import {Avatar, Stack, Typography} from '@mui/material';
import {IStep} from '../../../../constants/steps';

interface CardHeaderTitleProps {
  step: IStep;
}

export const CardHeaderTitle = ({step}: CardHeaderTitleProps) => {
  return (
    <Stack direction="row">
      <Avatar sx={{backgroundColor: theme => theme.palette.primary.main}}>{step.stepIcon}</Avatar>{' '}
      <Typography variant="h5" sx={{paddingLeft: '10px', paddingTop: '6px'}}>
        {step.stepLabel}
      </Typography>
    </Stack>
  );
};
