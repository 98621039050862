import {Grid} from '@mui/material';
import {SectionTitle} from '@ozark/common/components';
import {SectionAnswers} from '../types';
import {QuestionSummary} from './QuestionSummary';

interface Props {
  sectionAnswers: SectionAnswers;
}
export const SectionSummary = ({sectionAnswers}: Props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SectionTitle text={sectionAnswers.header} />
      </Grid>
      {sectionAnswers.questionAnswers
        .filter(x => x.isAvailable)
        .map(questionAnswer => (
          <QuestionSummary questionAnswer={questionAnswer} key={questionAnswer.question} />
        ))}
    </Grid>
  );
};
