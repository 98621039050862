/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import SecurityIcon from '@mui/icons-material/Security';
import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  Tooltip,
} from '@mui/material';
import {
  EncryptFieldResult,
  OwnerTitles,
  PersonaVerification,
  SignerPageSchema,
  StateArray,
  StateHash,
  toDateInUTCWithoutTime,
  usePersonaId,
} from '@ozark/common';
import {
  AutoCompleteAddressTextField,
  BooleanRadioGroup,
  SectionTitle,
  TextField,
} from '@ozark/common/components';
import {omit} from '@s-libs/micro-dash';
import Persona, {Client} from 'persona';
import {Field, InquiryAttributes} from 'persona/dist/lib/interfaces';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import {useCallable} from '../../firebase/hooks/useCallable';
import {useStore} from '../../store';
import Select from '../Select';
import Title from '../Title';
import {OtherOwners} from './OtherOwners';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  onDirty?: (isDirty: boolean) => void;
};

type Owner = {
  firstName?: string;
  lastName?: string;
  title: string;
  socialSecurityNumber?: string;
  encryptedSocialSecurityNumber?: string;
  encryptedSocialSecurityNumberSalt?: string;
  socialSecurityNumberLast4?: string;
  dateOfBirth?: Date;
  percentOwnership?: number;
  homeAddress1?: string;
  homeAddress2?: string;
  homeCity?: string;
  homeState?: string;
  homeZip?: string;
  driverLicenseState?: string;
  driverLicenseNumber?: string;
  cellPhone?: string;
  personaId?: PersonaVerification;
};

export type SignerFormData = Owner & {
  otherOwners: boolean;
  otherOwnersData: Owner[];
};

let personaClient: Client;

export const signerDataTransform =
  (onSuccess: any, handleEncrypt: (plainText: string) => Promise<EncryptFieldResult | null>) =>
  async (data: SignerFormData) => {
    let secureFormData = {...data};
    if (secureFormData.encryptedSocialSecurityNumber && !secureFormData.socialSecurityNumber) {
      secureFormData = omit(
        secureFormData,
        'encryptedSocialSecurityNumber',
        'encryptedSocialSecurityNumberSalt',
        'socialSecurityNumber'
      );
    } else if (secureFormData.socialSecurityNumber) {
      const ssn = String(secureFormData.socialSecurityNumber);
      const last4 = ssn.substring(ssn.length - 4, ssn.length);
      const encryptResult = await handleEncrypt(secureFormData.socialSecurityNumber);
      secureFormData = omit(secureFormData, 'socialSecurityNumber');
      if (encryptResult) {
        secureFormData.encryptedSocialSecurityNumber = encryptResult.encryptedText;
        secureFormData.encryptedSocialSecurityNumberSalt = encryptResult.salt;
        secureFormData.socialSecurityNumberLast4 = last4;
      } else {
        console.error(
          'Error encrypting sensitive data. For security, plain text values will not be saved.'
        );
      }
    }

    if (secureFormData.otherOwners && secureFormData.otherOwnersData?.length > 0) {
      const secureOtherOwners = await Promise.all(
        secureFormData.otherOwnersData.map(async otherOwner => {
          let secureOtherOwnerData = {...otherOwner};
          if (secureOtherOwnerData.socialSecurityNumber) {
            const ssn = String(secureOtherOwnerData.socialSecurityNumber);
            const last4 = ssn.substring(ssn.length - 4, ssn.length);
            const encryptedResult = await handleEncrypt(secureOtherOwnerData.socialSecurityNumber);
            secureOtherOwnerData = omit(secureOtherOwnerData, 'socialSecurityNumber');
            if (encryptedResult) {
              secureOtherOwnerData.encryptedSocialSecurityNumber = encryptedResult.encryptedText;
              secureOtherOwnerData.encryptedSocialSecurityNumberSalt = encryptedResult.salt;
              secureOtherOwnerData.socialSecurityNumberLast4 = last4;
            } else {
              console.error(
                'Error encrypting sensitive data. For security, plain text values will not be saved.'
              );
            }
          }

          if (otherOwner.dateOfBirth) {
            secureOtherOwnerData.dateOfBirth = toDateInUTCWithoutTime(otherOwner.dateOfBirth);
          }

          return secureOtherOwnerData;
        })
      );

      secureFormData.otherOwnersData = secureOtherOwners;
    } else {
      secureFormData.otherOwnersData = [];
    }

    onSuccess(secureFormData);
  };

const SignerPage = ({setValidationHandler, onDirty}: Props) => {
  const {group, application} = useStore();
  const {savePersonaIdAsync} = usePersonaId();
  const location = useLocation();
  const {encryptField, getPersonaIdConfig} = useCallable();
  const {
    formState: {errors, isDirty},
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    setFocus,
  } = useForm<SignerFormData>({
    defaultValues: {
      firstName: application.data?.firstName,
      lastName: application.data?.lastName,
      title: application.data?.title,
      encryptedSocialSecurityNumber: application.data?.encryptedSocialSecurityNumber,
      encryptedSocialSecurityNumberSalt: application.data?.encryptedSocialSecurityNumberSalt,
      socialSecurityNumberLast4: application.data?.socialSecurityNumberLast4,
      dateOfBirth: application.data?.dateOfBirth,
      percentOwnership: application.data?.percentOwnership ?? 100,
      homeAddress1: application.data?.homeAddress1,
      homeAddress2: application.data?.homeAddress2,
      homeCity: application.data?.homeCity,
      homeState: application.data?.homeState,
      homeZip: application.data?.homeZip,
      otherOwners: application.data?.otherOwners,
      otherOwnersData:
        application.data?.otherOwnersData?.length > 0
          ? application.data?.otherOwnersData
          : [{title: 'Owner'}],
      driverLicenseState: application.data?.driverLicenseState,
      driverLicenseNumber: application.data?.driverLicenseNumber,
      cellPhone: application.data?.cellPhone,
      personaId: application.data?.personaId,
    },
    resolver: yupResolver(SignerPageSchema),
  });

  const isPersonaEnabled = group.data?.applicationSettings?.enablePersonaIdVerification;

  const initPersona = async (refId: string) => {
    if (personaClient) return;

    getPersonaIdConfig().then(config => {
      personaClient = new Persona.Client({
        templateId: config.data.template,
        environmentId: config.data.environment,
        referenceId: refId,
        onReady: () => {},
        onComplete: ({
          inquiryId,
          status,
          fields,
        }: {
          inquiryId: string;
          status: string;
          fields: Record<string, Field> | InquiryAttributes;
        }) => {
          const selectedFields = fields as any;
          console.log(`Completed inquiry ${inquiryId} with status ${status}`);
          if (status === 'completed' && selectedFields['identification-class'].value === 'dl') {
            const newValue = {
              ...watchPersonaId,
              isVerified: true,
              driverLicenseNumber: selectedFields['identification-number'].value,
            } as any;
            setValue('personaId', newValue);
            savePersonaIdAsync(application.data.id, newValue);
            setValue('driverLicenseNumber', selectedFields['identification-number'].value);
          }

          personaClient.cancel(false);
        },
      });
    });
  };

  useEffect(() => {
    if (!application.data || !isPersonaEnabled || personaClient) {
      return;
    }

    if (!application.data.personaId?.referenceId) {
      const encryptEmail = async () => await encryptField(application.data.email);

      encryptEmail().then(encryptedResult => {
        if (encryptedResult) {
          const personaId = {
            isVerified: false,
            referenceId: encryptedResult.encryptedText,
            rerefenceIdSalt: encryptedResult.salt,
          };
          setValue('personaId', personaId);
          savePersonaIdAsync(application.data.id, personaId);
          initPersona(encryptedResult.encryptedText);
        }
      });
    } else {
      initPersona(application.data.personaId?.referenceId);
    }
  }, [initPersona, application, isPersonaEnabled]);

  const watchPercentOwnership = watch('percentOwnership');
  const watchOtherOwners = watch('otherOwners');
  const watchOtherOwnersData = watch('otherOwnersData');
  const watchPersonaId = watch('personaId');
  const watchDlNumber = watch('driverLicenseNumber');

  const handleHomeAddressAutoFill = (city: any, state: any, zip: any) => {
    setValue('homeAddress2', '', {shouldDirty: true});
    setValue('homeCity', city ? city : '', {shouldDirty: true});
    setValue('homeState', state ? state : '', {shouldDirty: true});
    setValue('homeZip', zip ? zip : '', {shouldDirty: true});
  };

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    if (!location.hash) return;

    const hash = location.hash.replace('#', '');
    if (hash === 'showValidation') {
      trigger('socialSecurityNumber', {shouldFocus: true});
      trigger('dateOfBirth', {shouldFocus: true});
      trigger('driverLicenseState', {shouldFocus: true});
      trigger('driverLicenseNumber', {shouldFocus: true});
      trigger('otherOwnersData', {shouldFocus: true});
    }
  }, [location.hash, trigger]);

  useEffect(() => {
    const firstError = (Object.keys(errors) as Array<keyof typeof errors>).reduce<
      keyof typeof errors | null
    >((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return !!errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit(data => {
          const totalPercentageOwnership =
            (data.percentOwnership ?? 0) +
            data.otherOwnersData.reduce((acc, cur) => acc + (cur.percentOwnership ?? 0), 0);

          if (totalPercentageOwnership > 100) {
            setError('percentOwnership', {
              type: 'custom',
              message:
                'Percentage ownership for all owners combined must be less than or equal to 100%.',
            });

            if (data.otherOwnersData?.length > 0) {
              for (let i = 0; i < data.otherOwnersData.length; i++) {
                setError(`otherOwnersData.${i}.percentOwnership`, {
                  type: 'custom',
                  message:
                    'Percentage ownership for all owners combined must be less than or equal to 100%.',
                });
              }
            }

            onError({message: 'Percentage ownership must be less than or equal to 100%.'});
            return;
          }
          signerDataTransform(onSuccess, encryptField)(data);
          reset(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  useEffect(() => {
    if (
      (!watchOtherOwners || (watchPercentOwnership !== undefined && watchPercentOwnership > 74)) &&
      watchOtherOwnersData.length
    ) {
      setValue('otherOwnersData', []);
    } else if (watchOtherOwners && !watchOtherOwnersData.length) {
      setValue('otherOwnersData', [{title: 'Owner'} as any]);
    }
  }, [watchOtherOwners, watchPercentOwnership]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Signer Info" h2="Let’s confirm who you are." />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="lastName" label="Last Name" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="__/__/____"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '99/99/9999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="socialSecurityNumber"
          label="Social Security Number"
          errors={errors}
          control={control}
          placeholder={application.data?.encryptedSocialSecurityNumber ? '**********' : null}
          helperText={
            application.data?.encryptedSocialSecurityNumber
              ? "We've already encrypted and captured your SSN. There's no need to enter it again."
              : null
          }
          transform={{
            pattern: '999-99-9999',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="We take your privacy and security seriously. All sensitive data that we collect is encrypted using an AES-256 encryption algorithm.">
                  <SecurityIcon color="primary" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name="driverLicenseState"
          label="Driver License State"
          required
          errors={errors}
          control={control}
        >
          <MenuItem key={'passport'} value={'Passport'}>
            Passport
          </MenuItem>
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6} lg={isPersonaEnabled ? 4 : 6}>
        <TextField
          name="driverLicenseNumber"
          disabled={watchPersonaId?.isVerified}
          label={`Driver License Number ${
            watchPersonaId?.isVerified && watchPersonaId?.driverLicenseNumber === watchDlNumber
              ? ' (Verified by Persona Id)'
              : ''
          }`}
          required
          errors={errors}
          control={control}
        />
      </Grid>
      {isPersonaEnabled && (
        <Grid item xs={12} sm={12} lg={2}>
          <Button
            style={{
              height: '56px',
              marginTop: '16px',
              width: '100%',
            }}
            onClick={() => {
              if (watchPersonaId) {
                personaClient.open();
              }
            }}
            variant="contained"
            color={watchPersonaId?.isVerified ? 'success' : 'primary'}
          >
            {watchPersonaId?.isVerified ? 'Verified by Persona Id' : 'Verify by Persona Id'}
          </Button>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          name="cellPhone"
          label="Cell Phone"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <SectionTitle text="Home Address" />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleHomeAddressAutoFill}
          name="homeAddress1"
          label="Address Line 1"
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField name="homeAddress2" label="Address Line 2" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField name="homeCity" label="City" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select name="homeState" label="State" required errors={errors} control={control}>
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}2`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="homeZip"
          label="Zip Code"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Business Ownership" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="percentOwnership"
          label="What percentage of the business do you own?"
          required
          errors={errors}
          control={control}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="title"
          label="Title"
          required
          defaultValue={OwnerTitles.Owner}
          errors={errors}
          control={control}
          options={Object.values(OwnerTitles)}
        />
      </Grid>
      {watchPercentOwnership !== undefined && watchPercentOwnership < 75 && (
        <Grid item xs={12}>
          <BooleanRadioGroup
            name="otherOwners"
            label="Are there any other individuals with ownership above 25%?"
            defaultValue={false}
            required
            errors={errors}
            control={control}
          >
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
          </BooleanRadioGroup>
        </Grid>
      )}
      {watchPercentOwnership !== undefined && watchPercentOwnership < 75 && watchOtherOwners && (
        <Grid item xs={12}>
          <OtherOwners control={control} errors={errors} isAgent={false} />
        </Grid>
      )}
    </Grid>
  );
};

export default SignerPage;
