import {Box, Typography} from '@mui/material';
import Title from '@ozark/app/src/components/Title';

type Props = {
  title?: string;
  subTitle?: string;
  applicationDistinguishableId: string;
};

const DEFAULT_TITLE = 'Submitted!';
const DEFAULT_SUB_TITLE =
  'You have completed your online application. You will receive an email shortly with the details of your approval to begin processing.';

export const SubmittedPageContent = ({title, subTitle, applicationDistinguishableId}: Props) => {
  return (
    <Box sx={{width: '100%'}}>
      <Title
        h1={title || DEFAULT_TITLE}
        h2={
          <Box sx={{width: '100%', textAlign: 'left'}}>
            {subTitle ? (
              <Box sx={{'& p': {margin: 0}}} dangerouslySetInnerHTML={{__html: subTitle}} />
            ) : (
              <Box sx={{textAlign: 'center'}}>{DEFAULT_SUB_TITLE}</Box>
            )}
          </Box>
        }
      />
      <Typography variant="h6" align="center">
        Application ID: <strong>{applicationDistinguishableId}</strong>
      </Typography>
    </Box>
  );
};
