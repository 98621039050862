import PhoneNumber from 'awesome-phonenumber';
import * as yup from 'yup';
import {LocationTypes} from '../../LocationTypes';
import {OwnershipTypes} from '../../OwnershipTypes';
import {ProcessingTypes} from '../../ProcessingTypes';
import {StateHash} from '../../USStates';
import {getCustomErrorMessage, getNumberTypeError, oneOfAllowEmpty} from '../Common';

const stringNoExtraSpaces = (value: string) => new RegExp(/^\S.*\S$/).test(value);

export const getBusinessPageForAgentsValidationObjectCommon = (useCustomErrorMessage: boolean) => {
  return {
    ownershipType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Business Structure is required'))
      .oneOf(Object.values(OwnershipTypes)),
    legalBusinessName: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Legal Business Name is required'))
      .test(
        'no-extra-spaces-allowed',
        'Please remove leading and trailing spaces' + (useCustomErrorMessage ? '' : ' in ${path}'),
        value => stringNoExtraSpaces(value ?? '')
      ),
    doingBusinessAs: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'DBA is required'))
      .test(
        'no-extra-spaces-allowed',
        'Please remove leading and trailing spaces' + (useCustomErrorMessage ? '' : ' in ${path}'),
        value => stringNoExtraSpaces(value ?? '')
      )
      .max(25, "DBA name can't be longer than 25 characters"),
    filingState: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Filing State is required'))
      .oneOf(Object.keys(StateHash)),
    federalTaxId: yup
      .string()
      .when(
        '$dobSsnTaxIdAndBankInfoNotRequired',
        (dobSsnTaxIdAndBankInfoNotRequired: boolean, schema: any) => {
          if (!dobSsnTaxIdAndBankInfoNotRequired) {
            return schema
              .required(getCustomErrorMessage(useCustomErrorMessage, 'Federal Tax ID is required'))
              .min(10, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid EIN'))
              .matches(
                /\d{2}-\d{7}$/,
                getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid EIN')
              );
          }
        }
      ),
    customerServiceEmail: yup
      .string()
      .email(getCustomErrorMessage(useCustomErrorMessage, 'Customer Service Email is not valid'))
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Customer Service Email is required')),
    businessPhone: yup
      .string()
      .min(12, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid phone number')) // validating against e164 format (+18002333333)
      .matches(
        /^\+1[2-9]{1}[0-9]{9}$/,
        getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid phone number')
      )
      .transform(value => {
        return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      })
      .required(
        getCustomErrorMessage(
          useCustomErrorMessage,
          'Business Phone is required',
          "businessPhone must match the following: \\'/^\\\\+1[2-9]{1}[0-9]{9}$/\\'"
        )
      ),
    website: yup.string().when('processingType', (processingType: ProcessingTypes, schema: any) => {
      if (processingType === ProcessingTypes.eCommerce) {
        return schema.required(getCustomErrorMessage(useCustomErrorMessage, 'Website is required'));
      }
    }),
    businessStartYear: yup
      .number()
      .required()
      .min(1900, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid year'))
      .max(
        new Date().getFullYear(),
        getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid year')
      )
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Must be a valid year')),
    locationType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Location Type is required'))
      .oneOf(Object.values(LocationTypes)),
    businessAddress1: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Address is required')),
    businessCity: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'City is required')),
    businessState: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'State is required'))
      .oneOf(Object.keys(StateHash)),
    businessZip: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Zip Code is required'))
      .min(5, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid zip code')),
    mailingAddressDifferent: yup.boolean(),
    mailingAddress1: yup
      .string()
      .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
        if (mailingAddressDifferent) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Address is required')
          );
        }
      }),
    mailingCity: yup
      .string()
      .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
        if (mailingAddressDifferent) {
          return schema.required(getCustomErrorMessage(useCustomErrorMessage, 'City is required'));
        }
      }),
    mailingState: yup
      .string()
      .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
        if (mailingAddressDifferent) {
          return schema.required(getCustomErrorMessage(useCustomErrorMessage, 'State is required'));
        }
      })
      .test(oneOfAllowEmpty(Object.keys(StateHash))),
    mailingZip: yup
      .string()
      .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
        if (mailingAddressDifferent) {
          return schema
            .required(getCustomErrorMessage(useCustomErrorMessage, 'Zip Code is required'))
            .min(5, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid zip code'));
        }
      }),
  };
};

export const getApiBusinessPageForAgentsValidationObjectCommon = () => {
  return getBusinessPageForAgentsValidationObjectCommon(false);
};
