import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import {Application} from '@ozark/common';
import {
  SubmittedPageContent,
  SubmittedPageFooter,
  SubmittedPageHead,
} from '@ozark/common/components';
import {Fragment, useEffect, useState} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {APPLICATION} from '../../constants/routes';
import {useStore} from '../../store';
import Title from '../Title';

const Confirmation = () => {
  const {group, signOut} = useStore();
  const history = useHistory();
  const location = useLocation<{
    applicationData?: Pick<
      Application,
      'firstName' | 'lastName' | 'legalBusinessName' | 'doingBusinessAs' | 'distinguishableId'
    >;
    isTransferredApplication: boolean;
  }>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (group.data?.appDomain === 'app.zaray.com') {
      (window as any).gtag('event', 'conversion', {send_to: 'AW-11079756849/Mx4WCOuwzIwYELHYnqMp'});
    }
    if (group.data?.appDomain === 'app.luqra.com') {
      (window as any).gtag('event', 'conversion', {send_to: 'AW-11079810358/7lLVCPCV-o0YELb6oaMp'});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (history.action !== 'PUSH') {
      setLoading(false);
      return;
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [history.action]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  if (!location.state?.applicationData) {
    return <Redirect to={APPLICATION} />;
  }

  const {applicationData, isTransferredApplication} = location.state;

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container direction="column" alignItems="center" sx={{py: 6}}>
          <Grid item>{group.data && <SubmittedPageHead group={group.data} />}</Grid>
          <Grid item>
            {loading ? (
              <Title h2="Processing your application..." />
            ) : (
              <Fragment>
                {group?.data?.applicationSettings?.enableRegistration &&
                !isTransferredApplication ? (
                  <>
                    <Title
                      h1={
                        <Box
                          component="span"
                          sx={{textTransform: 'initial', mt: 0, mb: 3, display: 'block'}}
                        >
                          Hello {applicationData.firstName ?? ''} {applicationData.lastName ?? ''},
                        </Box>
                      }
                      h2={`The application for ${applicationData.legalBusinessName ?? ''} ${
                        applicationData.doingBusinessAs ?? ''
                      } (ID: ${
                        applicationData.distinguishableId ?? ''
                      }) has been submitted to our team for processing.`}
                    />
                    <Typography align="center" sx={{fontSize: 24}}>
                      <Box component="span" sx={{mb: 3, display: 'block'}}>
                        Please track the progress of this application within your portal.
                      </Box>
                      <Button
                        variant="contained"
                        href={`http://${group.data?.portalDomain}`}
                        target="_self"
                      >
                        Sign In to Portal
                      </Button>
                    </Typography>
                  </>
                ) : (
                  <SubmittedPageContent
                    applicationDistinguishableId={applicationData.distinguishableId}
                    title={group.data?.applicationSettings?.submittedPage?.title}
                    subTitle={group.data?.applicationSettings?.submittedPage?.subTitle}
                  />
                )}

                {group.data && (
                  <SubmittedPageFooter
                    group={group.data}
                    customFooterText={group.data.applicationSettings?.submittedPage?.footerText}
                  />
                )}
              </Fragment>
            )}
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Confirmation;
