import {
  ApplicationTemplate,
  ApplicationTemplateView,
  Collections,
  selectApplicationTemplateView,
  UniversalSnapshot,
} from '@ozark/common';
import {useCallback, useEffect, useState} from 'react';
import {Firebase} from '../firebase';
import {AsyncState} from '../models';

export const useApplicationTemplates = (createdBy: string | undefined) => {
  const [applicationTemplates, setApplicationTemplates] = useState<
    AsyncState<ApplicationTemplateView[]>
  >({
    promised: true,
  });

  const fetchApplicationTemplates = useCallback(async (createdBy: string | undefined) => {
    if (!createdBy) {
      setApplicationTemplates({promised: false});
      return;
    }
    Firebase.firestore
      .collection(Collections.applicationsTemplates)
      .where('createdBy', '==', createdBy)
      .onSnapshot(
        async snapshot => {
          if (snapshot.empty) {
            setApplicationTemplates({promised: false});
            return;
          }

          const applicationTemplates = snapshot.docs.map(doc =>
            selectApplicationTemplateView(doc as UniversalSnapshot<ApplicationTemplate>)
          );
          setApplicationTemplates({promised: false, data: applicationTemplates});
        },
        err => {
          console.error(err);
          setApplicationTemplates({promised: false, error: err});
        }
      );
  }, []);

  useEffect(() => {
    fetchApplicationTemplates(createdBy);
  }, [fetchApplicationTemplates, createdBy]);

  return {applicationTemplates};
};
