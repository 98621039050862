import {MenuItem, TextField} from '@mui/material';
import {getSortedKeys, useSelectSearch, VAL_FALSE, VAL_TRUE} from '@ozark/common';
import {Controller} from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  errors: any;
  control: any;
  options?: string[] | {[_: string]: string | number} | null | undefined;
  yesOrNo?: boolean;
  skipSortOptions?: boolean;
  [others: string]: any;
}

const getSortedOptions = (options: any): any => {
  const _options = options as string[];

  return (_options ? getSortedKeys(_options, e => options[e]) : Object.keys(options))!.map(e => (
    <MenuItem key={e} value={options[e]}>
      {e}
    </MenuItem>
  ));
};

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;

const Select = ({
  name,
  label,
  defaultValue,
  required,
  errors,
  control,
  options,
  yesOrNo,
  skipSortOptions,
  children,
  ...other
}: Props) => {
  const selectSearch = useSelectSearch();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{required: required}}
      render={({field: {value, ref, onChange}}) => (
        <TextField
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          id={name}
          label={label}
          name={name}
          value={value}
          data-test={name}
          onChange={e => onChange(e.target.value)}
          onKeyDown={e => {
            selectSearch.onKeyDown(e, value, onChange, yesOrNo, options, skipSortOptions, children);
          }}
          error={Boolean(errors[name])}
          helperText={errors[name]?.message}
          inputRef={ref}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 13 + ITEM_PADDING_TOP,
                },
              },
            },
          }}
          select
          {...other}
        >
          {yesOrNo && [
            <MenuItem key={`${name}-true`} value={VAL_TRUE.value}>
              {VAL_TRUE.text}
            </MenuItem>,
            <MenuItem key={`${name}-false`} value={VAL_FALSE.value}>
              {VAL_FALSE.text}
            </MenuItem>,
          ]}
          {!yesOrNo &&
            options &&
            Array.isArray(options) &&
            (skipSortOptions
              ? options.map(e => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))
              : options.sortAndMap(e => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                )))}
          {!yesOrNo && options && !Array.isArray(options) && getSortedOptions(options)}
          {children}
        </TextField>
      )}
    />
  );
};

export default Select;
