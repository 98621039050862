import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {Button, Grid, Typography} from '@mui/material';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import {styled} from '@mui/material/styles';
import {useEffect, useState} from 'react';
import {Control, useFieldArray} from 'react-hook-form';
import {SignerFormData} from '.';
import {OtherOwnerForm} from './OtherOwnerForm';

type OtherOwnersProps = {
  control: Control<SignerFormData>;
  errors: any;
  isAgent: boolean;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const OtherOwners = ({control, errors, isAgent}: OtherOwnersProps) => {
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'otherOwnersData',
  });

  const [ownersCount, setOwnersCount] = useState(fields.length);
  const [expanded, setExpanded] = useState<string[]>([]);

  // Keeps track of added owners, if a new owner is added it expands the accordion.
  useEffect(() => {
    if (fields.length !== ownersCount) {
      setExpanded([...expanded, `panel${fields[fields.length - 1].id}`]);
      setOwnersCount(fields.length);
    }
  }, [fields.length, setOwnersCount, expanded, fields, ownersCount]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (expanded.includes(panel)) {
      setExpanded([...expanded.filter(e => e !== panel)]);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {fields.map((field, index) => (
          <Accordion
            expanded={fields.length === 1 ? true : expanded.includes(`panel${field.id}`)}
            onChange={handleChange(`panel${field.id}`)}
            key={field.id}
          >
            <AccordionSummary
              aria-controls={`panel${field.id}d-content`}
              id={`panel${field.id}d-header`}
            >
              <Typography>Owner #{index + 2}</Typography>
              {index !== 0 && (
                <Button
                  size="small"
                  onClick={() => remove(index)}
                  variant="contained"
                  color="secondary"
                >
                  Remove
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails style={{display: 'flex', flexWrap: 'wrap', columnGap: '10px'}}>
              <OtherOwnerForm
                key={field.id}
                index={index}
                control={control}
                errors={errors}
                isAgent={isAgent}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      {fields.length < 3 && (
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', padding: 20}}>
          <Button
            size="medium"
            onClick={() => append({title: 'Owner'})}
            variant="contained"
            color="primary"
          >
            Add Additional Owner
          </Button>
        </Grid>
      )}
    </>
  );
};
