import {Box, Typography} from '@mui/material';
import {indigo} from '@mui/material/colors';

const primary = indigo[500];

export const NotAvailable = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: primary,
        textAlign: 'center',
      }}
    >
      <Typography variant="h1" style={{color: 'white'}}>
        Not Available
      </Typography>
    </Box>
  );
};
