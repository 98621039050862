import {useNotification} from '@ozark/common/';
import {useCallback, useState} from 'react';
import {useStore} from '../../../store';

export const useSessionProgress = () => {
  const showNotification = useNotification();
  const {saveProgress} = useStore();
  const [endSessionHandler, setEndSessionHandler] = useState<() => () => Promise<any>>();

  const saveCurrentProgressWithoutValidation = useCallback(async () => {
    const fields = endSessionHandler?.();
    if (fields) {
      await saveProgress(fields);
    }
  }, [endSessionHandler, saveProgress]);

  const handleEndSession = useCallback(async () => {
    showNotification(
      'success',
      'Successfully saved application progress. You may end this session and close the window.'
    );
  }, [showNotification]);

  return {
    saveCurrentProgressWithoutValidation,
    handleEndSession,
    setEndSessionHandler,
    endSessionHandler,
  };
};
