import {ApplicationView, ProcessingTypes, RateType, UniversalTimestamp} from '@ozark/common';
import {useStore} from '../../../../store';
import {QuestionAnswer} from '../types';

export const useSummaryCommon = () => {
  const {application} = useStore();
  const app = application.data as Partial<ApplicationView>;
  const getYesNo = (isYes: boolean | string | undefined): string => {
    if (typeof isYes === 'boolean') {
      return isYes ? 'Yes' : 'No';
    }

    if (typeof isYes === 'string') {
      return isYes === 'true' ? 'Yes' : 'No';
    }

    return 'No';
  };
  const isCardPresent = () => {
    return app.processingType === ProcessingTypes.cardPresent;
  };

  const getQuestionAnswer = (
    question: string,
    answer: string | number | undefined | RateType,
    isAvailable: boolean
  ): QuestionAnswer => {
    return {
      question,
      answer,
      isAvailable,
    };
  };

  const getAvailableQuestionAnswer = (
    question: string,
    answer: string | number | UniversalTimestamp | undefined | RateType,
    isAvailable: boolean = true,
    helperText?: any
  ): QuestionAnswer => {
    return {
      question,
      answer,
      isAvailable,
      helperText,
    };
  };

  const emptyAnswers = {
    sectionAnswers: [],
  };

  return {
    application,
    emptyAnswers,
    getAvailableQuestionAnswer,
    getQuestionAnswer,
    isCardPresent,
    getYesNo,
    app,
  };
};
