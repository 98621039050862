import * as yup from 'yup';
import {getCustomErrorMessage} from '../Common';

export const getDepositsPageForAgentsObjectCommon = (useCustomErrorMessage: boolean) => {
  return {
    routingNumber: yup
      .string()
      .optional()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema
            .required(getCustomErrorMessage(useCustomErrorMessage, 'Routing Number is required'))
            .length(
              9,
              getCustomErrorMessage(useCustomErrorMessage, 'Routing Number must be 9 digits')
            );
        }
        return;
      }),
    bankAccountNumber: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Bank Account Number is required')
          );
        }
        return;
      }),
    confirmBankAccountNumber: yup
      .string()
      .oneOf(
        [yup.ref('bankAccountNumber')],
        getCustomErrorMessage(useCustomErrorMessage, 'Bank Account Numbers do not match')
      )
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Confirmation is required')
          );
        }
        return;
      }),
    bankName: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Bank Name is required')
          );
        }
        return;
      }),
    finicityAccount: yup.object(),
  };
};

export const getApiDepositsPageForAgentsObjectCommon = () => {
  return getDepositsPageForAgentsObjectCommon(false);
};
