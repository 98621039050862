import {FlowAnswers} from '../types';
import {useBasicStepAnswers} from './useBasicStepAnswers';
import {useBusinessStepAnswers} from './useBusinessStepAnswers';
import {useDepositInfoStepAnswers} from './useDepositInfoStepAnswers';
import {useSelectYourProgramStepAnswers} from './useSelectYourProgramStepAnswers';
import {useSignerInfoStepAnswers} from './useSignerInfoStepAnswers';

export const useSummary = () => {
  const {getBasicStepAnswers, getBasicStepAnswersForAgentOrErpUser} = useBasicStepAnswers();
  const {getYourBusinessStepAnswers, getYourBusinessStepAnswersForAgentOrErpUser} =
    useBusinessStepAnswers();
  const {getSignerInfoStepAnswers, getSignerInfoStepAnswersForAgentOrErpUser} =
    useSignerInfoStepAnswers();
  const {getDepositInfoStepAnswers, getDepositInfoStepAnswersForAgentOrErpUser} =
    useDepositInfoStepAnswers();
  const {getSelectYourProgramStepAnswers, getSelectYourProgramStepAnswersForAgentOrErpUser} =
    useSelectYourProgramStepAnswers();

  const getFlowAnswers = (): FlowAnswers => {
    return {
      basicStepAnswers: getBasicStepAnswers(),
      yourBusinessStepAnswers: getYourBusinessStepAnswers(),
      signerInfoStepAnswers: getSignerInfoStepAnswers(),
      depositInfoStepAnswers: getDepositInfoStepAnswers(),
      selectYourProgramStepAnswers: getSelectYourProgramStepAnswers(),
    };
  };

  const getFlowAnswersForAgentOrErpUser = (): FlowAnswers => {
    return {
      basicStepAnswers: getBasicStepAnswersForAgentOrErpUser(),
      yourBusinessStepAnswers: getYourBusinessStepAnswersForAgentOrErpUser(),
      signerInfoStepAnswers: getSignerInfoStepAnswersForAgentOrErpUser(),
      depositInfoStepAnswers: getDepositInfoStepAnswersForAgentOrErpUser(),
      selectYourProgramStepAnswers: getSelectYourProgramStepAnswersForAgentOrErpUser(),
    };
  };

  return {
    getFlowAnswers,
    getFlowAnswersForAgentOrErpUser,
  };
};
