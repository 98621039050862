import {Button, CircularProgress, Grid} from '@mui/material';
import {Fragment} from 'react';

interface Props {
  onSubmit(): any;
  loading?: boolean;
}

export const Actions = ({onSubmit, loading}: Props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button variant="contained" onClick={onSubmit} color="primary" fullWidth disabled={loading}>
          {loading ? <CircularProgress size={24} /> : <Fragment>Save</Fragment>}
        </Button>
      </Grid>
    </Grid>
  );
};
