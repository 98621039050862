import {Alert, Button} from '@mui/material';
import {Fragment} from 'react';

type Props = {
  className?: string;
  onPopulateTestDataClick: () => void;
};

export const TestModeAlert = ({className, onPopulateTestDataClick}: Props) => {
  return (
    <Alert severity="warning" className={className}>
      <Fragment>
        You are currently in <b>test mode</b>.{' '}
        <Button color="primary" size="small" onClick={onPopulateTestDataClick}>
          Click here
        </Button>
        to prepopulate the form with test data.
      </Fragment>
    </Alert>
  );
};
