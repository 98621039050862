import {
  ApplicationInput,
  LocationTypes,
  OwnershipTypes,
  OwnerTitles,
  ProcessingTypes,
  useSecretCode,
} from '@ozark/common';
import {useCallback, useEffect} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import {APPLICATION_DETAILS} from '../../../constants/routes';
import {IStep} from '../../../constants/steps';
import CodeAudio from '../../../static/media/scorpion-get_over_here.mp3';
import {useStore} from '../../../store';

const TEST_APPLICATION_DATA: Partial<ApplicationInput> = {
  // basics
  businessCategory: 'Food & Beverage',
  businessType: 'Fast Food, Ice Cream Shops, Coffee Shops, Etc',
  processingType: ProcessingTypes.cardPresent,
  percentSwiped: 70,
  percentKeyed: 30,
  percentInternet: 0,
  percentInternational: 0,
  percentB2b: 0,
  acceptPIN: true,
  ebtAcceptance: 'Yes',
  ebtFNS: '0123456',
  customerReceivesProductService: '0-7 days',
  refundPolicy: 'Other',
  refundPolicyExplained: 'This is an explaination of the refund policy.',
  previouslyProcessed: true,
  estimatedMonthlyCreditCardSales: 25000,
  estimatedMonthlyCreditCardSalesAmex: 2500,
  estimatedAverageSale: 10,
  estimatedHighestSale: 50,
  // business
  ownershipType: OwnershipTypes.limitedLiabilityCompany,
  legalBusinessName: 'Legal Business Name',
  doingBusinessAs: 'Doing Business AS',
  customerServiceEmail: 'test@test.com',
  filingState: 'CA',
  federalTaxId: '98-9898989',
  businessPhone: '(800) 252-1234',
  website: 'http://www.ozarkcrm.com/',
  businessStartYear: 2020,
  locationType: LocationTypes.retailStoreFront,
  businessAddress1: '23515 Lyons Ave',
  businessAddress2: '',
  businessCity: 'Valencia',
  businessState: 'CA',
  businessZip: '91355',
  mailingAddressDifferent: true,
  mailingAddress1: '23515 Lyons Ave',
  mailingAddress2: '',
  mailingCity: 'Valencia',
  mailingState: 'CA',
  mailingZip: '91355',
  // signer
  firstName: 'Richard',
  lastName: 'Cardwell',
  title: OwnerTitles.Owner,
  socialSecurityNumber: '217-90-8984',
  //dateOfBirth: new Date(1975, 1, 1), // disabled due to earlier application submit issue
  homeAddress1: '23515 Lyons Ave',
  homeAddress2: '',
  homeCity: 'Valencia',
  homeState: 'CA',
  homeZip: '91355',
  percentOwnership: 100,
  driverLicenseState: 'CA',
  driverLicenseNumber: 'D123456789',
  otherOwners: false,
  otherOwnersData: [],
  // deposit
  routingNumber: '122000247',
  bankName: 'Chase',
  bankAccountNumber: '0987654321',
  confirmBankAccountNumber: '0987654321',
  cellPhone: '(800) 123-1234',
};

const SECRET_CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
];

export const useTestApplicationData = (applicationId: string, activeStep: IStep) => {
  const {set} = useStore();
  const history = useHistory();
  const codeEntered = useSecretCode(SECRET_CODE);

  const populateTestDataToApplication = useCallback(async () => {
    await set({
      ...TEST_APPLICATION_DATA,
      nextPath: activeStep.key,
    });

    // redirect to force updating application data in forms
    history.push(generatePath(APPLICATION_DETAILS, {applicationId}));
  }, [set, history, applicationId, activeStep]);

  useEffect(() => {
    if (!codeEntered) return;

    const audio = new Audio(CodeAudio);
    audio.play();
    populateTestDataToApplication();
  }, [codeEntered, populateTestDataToApplication]);

  return {
    populateTestDataToApplication,
  };
};
