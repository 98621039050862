import SecurityIcon from '@mui/icons-material/Security';
import {Grid, InputAdornment, MenuItem, Tooltip} from '@mui/material';
import {OwnerTitles, StateArray, StateHash} from '@ozark/common';
import {Select, TextField} from '@ozark/common/components';
import {Control, useWatch} from 'react-hook-form';
import {SignerFormData} from '.';
import {useStore} from '../../store';

type OtherOwnerFormProps = {
  control: Control<SignerFormData>;
  errors: any;
  index: number;
  readonlyAccess?: boolean;
  isAgent: boolean;
};

export const OtherOwnerForm = ({
  control,
  errors,
  index,
  readonlyAccess = false,
  isAgent,
}: OtherOwnerFormProps) => {
  const {group} = useStore();
  const otherOwner = useWatch({
    control,
    name: `otherOwnersData.${index}`,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.percentOwnership`}
          label="Ownership Percentage"
          required
          errors={errors}
          control={control}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name={`otherOwnersData.${index}.title`}
          label="Title"
          required
          defaultValue={OwnerTitles.Owner}
          errors={errors}
          control={control}
          options={Object.values(OwnerTitles)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.firstName`}
          label="First Name"
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.lastName`}
          required
          label="Last Name"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.dateOfBirth`}
          label="Date of Birth"
          placeholder="__/__/____"
          required={!isAgent || !group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          errors={errors}
          control={control}
          transform={{
            pattern: '99/99/9999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.socialSecurityNumber`}
          label="Social Security Number"
          placeholder={otherOwner?.encryptedSocialSecurityNumber ? '**********' : null}
          helperText={
            otherOwner?.encryptedSocialSecurityNumber
              ? "We've already encrypted and captured your SSN. There's no need to enter it again."
              : null
          }
          transform={{
            pattern: '999-99-9999',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="We take your privacy and security seriously. All sensitive data that we collect is encrypted using an AES-256 encryption algorithm.">
                  <SecurityIcon color="primary" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name={`otherOwnersData.${index}.driverLicenseState`}
          label="Driver License State"
          required={!isAgent || !group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          errors={errors}
          control={control}
        >
          <MenuItem key={'passport'} value={'Passport'}>
            {'Passport'}
          </MenuItem>
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={`otherOwnersData.${index}.driverLicenseNumber`}
          label="Driver License Number"
          required={!isAgent || !group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`otherOwnersData.${index}.homeAddress1`}
          required
          label="Address Line 1"
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`otherOwnersData.${index}.homeAddress2`}
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name={`otherOwnersData.${index}.homeCity`}
          label="City"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          name={`otherOwnersData.${index}.homeState`}
          label="State"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name={`otherOwnersData.${index}.homeZip`}
          label="Zip Code"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
    </Grid>
  );
};
