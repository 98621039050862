import {Card, CardContent, CardHeader, Divider, Stack} from '@mui/material';
import {IStep} from '../../../../constants/steps';
import {PageAnswers} from '../types';
import {CardHeaderAction} from './CardHeaderAction';
import {CardHeaderTitle} from './CardHeaderTitle';
import {SectionSummary} from './SectionSummary';

interface Props {
  pageAnswers: PageAnswers;
  prevRoute: string;
  anchorId: string;
  step: IStep;
}

export const PageSummary = ({pageAnswers, prevRoute, anchorId, step}: Props) => {
  return (
    <Card id={anchorId} variant="outlined">
      <CardHeader
        title={<CardHeaderTitle step={step} />}
        action={<CardHeaderAction prevRoute={prevRoute} anchorId={anchorId} />}
      />
      <CardContent>
        <Stack spacing={1} divider={<Divider flexItem />}>
          {pageAnswers.sectionAnswers
            .filter(x => x.isAvailable)
            .map(sectionAnswer => (
              <SectionSummary sectionAnswers={sectionAnswer} key={sectionAnswer.header} />
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
