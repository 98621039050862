import {CircularProgress, InputAdornment, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {Controller} from 'react-hook-form';
import {AutoCompleteOptions} from '../../context/AutoComplete';
import {TextInput} from '../../types/InputFields';

interface AutoCompleteProps extends TextInput {
  onInputChange: any;
  onChange?: any;
  options: AutoCompleteOptions;
  freeSolo?: boolean;
  helperText?: string;
  inputProps?: any;
  loading: boolean;
  disabled?: boolean;
}

const AutoCompleteBankInput = ({
  name,
  control,
  required,
  errors,
  helperText,
  label,
  margin = 'normal',
  onChange = () => null,
  onInputChange,
  options,
  inputProps,
  freeSolo = true,
  loading = false,
  disabled = false,
}: AutoCompleteProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field}) => (
        <Autocomplete
          id={name}
          freeSolo={freeSolo}
          getOptionLabel={text => text.toString()}
          filterOptions={x => x}
          options={options ? options.map(option => option.text) : []}
          autoComplete
          disabled={disabled}
          inputValue={field.value}
          onChange={onChange}
          onInputChange={onInputChange}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                ...inputProps,
              }}
              name={field.name}
              label={label}
              margin={margin}
              inputRef={field.ref}
              error={Boolean(errors[name])}
              helperText={errors[name]?.message || helperText}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={24} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutoCompleteBankInput;
