import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useTheme,
} from '@mui/material';
import {ApplicationTemplateView, getErrorMessage} from '@ozark/common';
import {useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  applicationTemplates: ApplicationTemplateView[];
  onClose: () => void;
  onSubmit: (applicationTemplate: ApplicationTemplateView) => Promise<void>;
}

type FormValues = {
  template: ApplicationTemplateView | null;
};

export const LoadForm = yup.object({
  template: yup.object().required('Template is required').nullable(),
});

const getLabelText = (option: ApplicationTemplateView) => {
  if (option.rateSet?.applicationType) {
    return `${option.templateName} (${option.rateSet.applicationType})`;
  }
  return option.templateName;
};

export const LoadTemplateDialog = ({applicationTemplates, onSubmit, onClose}: Props) => {
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [sortedApplicationTemplates, setSortedApplicationTemplates] = useState<
    ApplicationTemplateView[]
  >([]);
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormValues>({
    resolver: yupResolver(LoadForm),
    defaultValues: {template: null},
  });

  useEffect(() => {
    setSortedApplicationTemplates(
      applicationTemplates.sort((x1, x2) => {
        const appType1 = x1.rateSet?.applicationType
          ? x1.rateSet?.applicationType.toLowerCase()
          : '';
        const appType2 = x2.rateSet?.applicationType
          ? x2.rateSet?.applicationType.toLowerCase()
          : '';
        if (appType1 < appType2) {
          return -1;
        }
        if (appType1 > appType2) {
          return 1;
        }
        return 0;
      })
    );
  }, [applicationTemplates]);

  const onSuccess: SubmitHandler<FormValues> = async data => {
    if (!data.template) {
      return;
    }
    setIsSaving(true);
    try {
      await onSubmit(data.template);
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmitClick = async () => {
    await handleSubmit(onSuccess)();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="load-dialog-title" maxWidth={'lg'}>
      <DialogTitle id="load-dialog-title">Load Pricing Template</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 600,
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="template"
                control={control}
                render={({field: {onChange, value, ...props}}) => (
                  <Autocomplete
                    options={sortedApplicationTemplates}
                    getOptionLabel={option => getLabelText(option)}
                    disableClearable={!!value}
                    onChange={(_e, value) => {
                      onChange(value);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Template"
                        variant="standard"
                        error={Boolean(getErrorMessage('template', errors))}
                        helperText={getErrorMessage('template', errors)?.message}
                      />
                    )}
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={onSubmitClick} color="primary" loading={isSaving}>
          Load
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
