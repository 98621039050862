import {Box} from '@mui/material';
import {Group} from '@ozark/common';

type Props = {
  group: Group;
};

export const SubmittedPageHead = ({group}: Props) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Box
        component="img"
        sx={{margin: theme => theme.spacing(2, 2, 6, 2), height: 48}}
        src={group?.logoUrl}
        alt={group?.name}
      />
    </Box>
  );
};
