import {yupResolver} from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select as MuiSelect,
  SelectChangeEvent,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import {
  ApplicationType,
  EbtAcceptanceStatus,
  emptyStringToNull,
  getErrorMessage,
  ProcessingTypePayments,
  ProcessingTypes,
  RefundPolicy,
  toBoolean,
  useSimpleBusinessTypes,
} from '@ozark/common';
import {Loading, RadioGroup, SectionTitle, TextField} from '@ozark/common/components';
import {MenuProps} from '@ozark/common/helpers';

import {
  avgSaleOptions as avgSaleOptionsDefaults,
  estimatedMonthlyCreditCardSalesOptions,
  highestSaleOptions as highestSaleOptionsDefaults,
  ShippingService,
} from '@ozark/functions/src/constants';
import {
  BusinessTypeCodeValue,
  BusinessTypePercentReserve,
} from '@ozark/functions/src/documents/BusinessType';
import React, {Fragment, SyntheticEvent, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import {useApplicationQuery} from '../Application/hooks/useApplicationQuery';
import Select from '../Select';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  onDirty?: (isDirty: boolean) => void;
};

const getSchema = (applicationType?: ApplicationType) =>
  yup.object().shape({
    businessType: yup.string().required('Specific Business Type is required'),
    processingType: yup.string().required('You must select an option'),
    acceptPIN: yup
      .boolean()
      .optional()
      .nullable(true)
      .when(['processingType'], {
        is: (processingType: ProcessingTypes) => {
          return (
            processingType === ProcessingTypes.cardPresent &&
            applicationType !== ApplicationType.cashDiscount
          );
        },
        then: yup.boolean().required('Accept PIN is required').typeError('Accept PIN is required'),
      }),
    ebtAcceptance: yup
      .string()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.cardPresent) {
          return schema
            .required('EBT Acceptance is required')
            .typeError('EBT Acceptance is required');
        }
      }),
    ebtFNS: yup.string().when('ebtAcceptance', {
      is: 'Yes',
      then: schema =>
        schema
          .required()
          .test(
            'empty-check',
            'FNS number for EBT Food Stamps must be 7 digits',
            value => value === undefined || value === '' || value.length === 7
          ),
    }),
    customerReceivesProductService: yup
      .string()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType !== ProcessingTypes.cardPresent) {
          return schema.required('Timeframe is required');
        }
      }),
    refundPolicy: yup.string().required('Refund Policy is required'),
    refundPolicyExplained: yup
      .string()
      .when('refundPolicy', (refundPolicy: string, schema: any) => {
        if (refundPolicy === 'Other') {
          return schema.required('Explanation is required');
        }
      }),
    previouslyProcessed: yup.boolean().required().typeError('Previously Processed is required'),
    estimatedMonthlyCreditCardSales: yup
      .number()
      .required()
      .typeError('Monthly Credit Card Sales is required'),
    estimatedAverageSale: yup.number().required().typeError('Average Sale is required'),
    estimatedHighestSale: yup.number().required().typeError('Highest Sale is required'),
    productOwner: yup
      .string()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required('Product Owner is required');
        }
      }),
    isCustomerDepositRequired: yup
      .boolean()
      .transform(toBoolean)
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required('Customers Provide Deposit is required');
        }
      }),
    customerDepositPercentage: yup
      .number()
      .min(0)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when(['processingType', 'isCustomerDepositRequired'], {
        is: (processingType: ProcessingTypes, isCustomerDepositRequired: boolean) => {
          return processingType === ProcessingTypes.eCommerce && Boolean(isCustomerDepositRequired);
        },
        then: yup
          .number()
          .min(0)
          .max(100)
          .transform(emptyStringToNull)
          .nullable()
          .required('Deposit Percentage is required'),
      }),
    productPurchasedNames: yup.string().transform(emptyStringToNull).nullable(),
    productPurchasedBy: yup
      .string()
      .transform(emptyStringToNull)
      .nullable()
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema.required('Product Purchased By is required');
        }
      }),
    fulfillmentCenterNames: yup
      .string()
      .when(['processingType', 'isFulfillmentCenterNamesDefined'], {
        is: (processingType: ProcessingTypes, isFulfillmentCenterNamesDefined: boolean) => {
          return (
            processingType === ProcessingTypes.eCommerce && Boolean(isFulfillmentCenterNamesDefined)
          );
        },
        then: yup.string().required('Fulfillment Center Names is required'),
      }),
    callCenterNames: yup.string().when(['processingType', 'isCallCenterNamesDefined'], {
      is: (processingType: ProcessingTypes, isCallCenterNamesDefined: boolean) => {
        return processingType === ProcessingTypes.eCommerce && Boolean(isCallCenterNamesDefined);
      },
      then: yup.string().required('Call Center Name is required'),
    }),
    cbManagementSystemsNames: yup
      .string()
      .when(['processingType', 'isCbManagementSystemsNamesDefined'], {
        is: (processingType: ProcessingTypes, isCbManagementSystemsNamesDefined: boolean) => {
          return (
            processingType === ProcessingTypes.eCommerce &&
            Boolean(isCbManagementSystemsNamesDefined)
          );
        },
        then: yup.string().required('CB Management System Names is required'),
      }),
    shippingServices: yup
      .array()
      .of(yup.string())
      .when('processingType', (processingType: ProcessingTypes, schema: any) => {
        if (processingType === ProcessingTypes.eCommerce) {
          return schema
            .required('Shipping Services is required')
            .min(1, 'Shipping Services is required');
        }
      }),
    otherShippingServices: yup.string().when(['processingType', 'shippingServices'], {
      is: (processingType: ProcessingTypes, shippingServices: ShippingService[]) => {
        return (
          processingType === ProcessingTypes.eCommerce &&
          shippingServices?.includes(ShippingService.other)
        );
      },
      then: yup.string().required('Other Shipping Method is required'),
    }),
  });

const transform = (onSuccess: any) => (data: any) => {
  data.percentB2b = 0;
  data.percentInternational = data.percentInternational || 0;
  switch (data.processingType) {
    case ProcessingTypes.cardPresent:
      data.percentSwiped = 90;
      data.percentKeyed = 10;
      data.percentInternet = 0;
      break;
    case ProcessingTypes.cardNotPresent:
      data.percentSwiped = 10;
      data.percentKeyed = 90;
      data.percentInternet = 0;
      break;
    case ProcessingTypes.eCommerce:
      data.percentSwiped = 0;
      data.percentKeyed = 0;
      data.percentInternet = 100;
      break;
    default:
      data.percentSwiped = 34;
      data.percentKeyed = 33;
      data.percentInternet = 33;
  }
  // estimate that amex is 10% of credit card sales
  data.estimatedMonthlyCreditCardSalesAmex = Math.round(data.estimatedMonthlyCreditCardSales * 0.1);

  if (data.processingType === ProcessingTypes.eCommerce) {
    if (!data.isCustomerDepositRequired) {
      data.customerDepositPercentage = 0;
    }
    if (!data.isFulfillmentCenterNamesDefined) {
      data.fulfillmentCenterNames = '';
    }
    if (!data.isCallCenterNamesDefined) {
      data.callCenterNames = '';
    }
    if (!data.isCbManagementSystemsNamesDefined) {
      data.cbManagementSystemsNames = '';
    }
    if (!data.shippingServices.includes(ShippingService.other)) {
      data.otherShippingServices = '';
    }
  } else {
    data.productOwner = '';
    data.customerDepositPercentage = 0;
    data.productPurchasedBy = '';
    data.productPurchasedNames = null;
    data.isFulfillmentCenterNamesDefined = true;
    data.fulfillmentCenterNames = '';
    data.isCallCenterNamesDefined = true;
    data.callCenterNames = '';
    data.isCbManagementSystemsNamesDefined = true;
    data.cbManagementSystemsNames = '';
    data.shippingServices = [];
    data.otherShippingServices = '';
  }
  onSuccess(data);
};

const enum SaleOptionCompareType {
  LargerThan,
  SmallerThan,
}

const BasicsPage = ({setValidationHandler, onDirty}: Props) => {
  const {application} = useStore();
  const [avgSaleOptions, setAvgSaleOptions] = React.useState(avgSaleOptionsDefaults);
  const [avgHighSaleOptions, setAvgHighSaleOptions] = React.useState(highestSaleOptionsDefaults);
  const [chosenShippingServices, setChosenShippingServices] = useState<string[]>([]);
  const {
    isSimpleBusinessTypesLoaded,
    getMccByBusinessType,
    getBusinessTypeByName,
    getBusinessTypeNames,
  } = useSimpleBusinessTypes();

  const applicationType = application?.data?.rateSet?.applicationType;

  const {formState, control, watch, reset, setValue, handleSubmit} = useForm({
    defaultValues: {
      businessType: application.data?.businessType,
      processingType: application.data?.processingType,
      acceptPIN: application.data?.acceptPIN,
      ebtAcceptance: application.data?.ebtAcceptance,
      ebtFNS: application.data?.ebtFNS,
      customerReceivesProductService: application.data?.customerReceivesProductService,
      refundPolicy: application.data?.refundPolicy,
      refundPolicyExplained: application.data?.refundPolicyExplained,
      previouslyProcessed: application.data?.previouslyProcessed,
      estimatedMonthlyCreditCardSales: application.data?.estimatedMonthlyCreditCardSales,
      estimatedAverageSale: application.data?.estimatedAverageSale,
      estimatedHighestSale: application.data?.estimatedHighestSale,
      percentReserve: application.data?.percentReserve,
      productOwner: application.data?.productOwner,
      isCustomerDepositRequired: application.data?.isCustomerDepositRequired,
      customerDepositPercentage: application.data?.customerDepositPercentage,
      productPurchasedNames: application.data?.productPurchasedNames,
      productPurchasedBy: application.data?.productPurchasedBy,
      isFulfillmentCenterNamesDefined: application.data?.isFulfillmentCenterNamesDefined ?? true, // make the Name field required by default
      fulfillmentCenterNames: application.data?.fulfillmentCenterNames,
      isCallCenterNamesDefined: application.data?.isCallCenterNamesDefined ?? true,
      callCenterNames: application.data?.callCenterNames,
      isCbManagementSystemsNamesDefined:
        application.data?.isCbManagementSystemsNamesDefined ?? true,
      cbManagementSystemsNames: application.data?.cbManagementSystemsNames,
      shippingServices: application.data?.shippingServices,
      otherShippingServices: application.data?.otherShippingServices,
    },
    resolver: yupResolver(getSchema(applicationType)),
  });
  const {isSummary} = useApplicationQuery();

  const {errors} = formState;
  const {isDirty} = formState;
  const watchProcessingType = watch('processingType');
  const watchBusinessType: string | undefined = watch('businessType');
  const watchRefundPolicy = watch('refundPolicy');
  const watchEbtAcceptance = watch('ebtAcceptance');
  const watchEstimatedAverageSale = watch('estimatedAverageSale');
  const watchEstimatedHighestSale = watch('estimatedHighestSale');
  const watchIsCustomerDepositRequired = watch('isCustomerDepositRequired');
  const watchIsFulfillmentCenterNamesDefined = watch('isFulfillmentCenterNamesDefined');
  const watchIsCallCenterNamesDefined = watch('isCallCenterNamesDefined');
  const watchIsCbManagementSystemsNamesDefined = watch('isCbManagementSystemsNamesDefined');

  const handleIsFulfillmentCenterNamesDefinedChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setValue('isFulfillmentCenterNamesDefined', !checked);
    if (checked) {
      setValue('fulfillmentCenterNames', '');
    }
  };
  const handleIsCallCenterNamesDefined = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setValue('isCallCenterNamesDefined', !checked);
    if (checked) {
      setValue('callCenterNames', '');
    }
  };
  const handleIsCbManagementSystemsNamesDefined = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setValue('isCbManagementSystemsNamesDefined', !checked);
    if (checked) {
      setValue('cbManagementSystemsNames', '');
    }
  };

  const handleShippingServicesChange = (event: SelectChangeEvent<string[]>) => {
    const shippingServices = event.target.value as string[];
    setChosenShippingServices(shippingServices);
    setValue('shippingServices', shippingServices, {shouldDirty: true});
  };

  useEffect(() => {
    // set shipping services:
    const curShippingServices: string[] | null =
      (control._defaultValues.shippingServices as string[]) || null;
    if (curShippingServices) {
      const selectedShippingServices = Object.entries(ShippingService)
        .filter(([, value]) => curShippingServices.includes(value))
        .map(([, value]) => value);
      setChosenShippingServices(selectedShippingServices);
    }
  }, [control]);

  useEffect(() => {
    if (watchEstimatedAverageSale === undefined) {
      setAvgSaleOptions(avgSaleOptionsDefaults);
    }
    if (watchEstimatedHighestSale === undefined) {
      setAvgHighSaleOptions(highestSaleOptionsDefaults);
    }
    if (watchEstimatedAverageSale > 0) {
      // once estimatedAverageSale selected, estimatedHighestSale options must be larger than estimatedAverageSale
      setAvgHighSaleOptions(
        getSaleOptionsFrom(
          highestSaleOptionsDefaults,
          SaleOptionCompareType.LargerThan,
          watchEstimatedAverageSale
        )
      );
    }
    if (watchEstimatedHighestSale > 0 && watchEstimatedAverageSale === '') {
      // once estimatedHighestSale selected, estimatedAverageSale options must be smaller than estimatedHighestSale
      setAvgSaleOptions(
        getSaleOptionsFrom(
          avgSaleOptionsDefaults,
          SaleOptionCompareType.SmallerThan,
          watchEstimatedHighestSale
        )
      );
    }
  }, [watchEstimatedAverageSale, watchEstimatedHighestSale]);

  const getSaleOptionsFrom = (options: any, compare: SaleOptionCompareType, value: number) => {
    return Object.keys(options)
      .filter((key: string) => {
        return compare === SaleOptionCompareType.LargerThan
          ? options[key] >= value
          : options[key] <= value;
      })
      .reduce((map: any, key: string) => {
        map[key] = options[key];
        return map;
      }, {});
  };

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          if (data.businessType) {
            data.mcc = getMccByBusinessType(data.businessType);
          }
          transform(onSuccess)(data);
          reset(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit, getMccByBusinessType]);

  const processingTypeLock = Boolean(application.data?.locks?.processingType) || isSummary;

  useEffect(() => {
    if (!watchBusinessType) {
      return;
    }

    const type = getBusinessTypeByName(watchBusinessType);

    if (!type || !type.code) return;

    const percentReserve = BusinessTypePercentReserve[type.code as BusinessTypeCodeValue] ?? null;

    setValue('percentReserve', percentReserve);
  }, [getBusinessTypeByName, setValue, watchBusinessType]);

  const displayProcessingType = (processingType: ProcessingTypes) => {
    const paymentInfo = ProcessingTypePayments[processingType];

    return (
      <FormControlLabel
        key={processingType}
        value={processingType}
        control={<Radio disabled={processingTypeLock} />}
        label={
          <Typography>
            {paymentInfo.title} &nbsp;
            <Typography variant="caption">&bull; {paymentInfo.caption}</Typography>
          </Typography>
        }
      />
    );
  };

  if (!isSimpleBusinessTypesLoaded) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          h1="Just the Basics"
          h2="Let's collect some basic information about your business."
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="businessType"
          control={control}
          rules={{required: true}}
          defaultValue=""
          render={({field}) => (
            <Autocomplete
              id="businessType"
              sx={{width: '100%'}}
              disableClearable={!watchBusinessType}
              options={getBusinessTypeNames()}
              {...field}
              onChange={(_event, newInputValue) => {
                setValue('businessType', newInputValue);
              }}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  error={Boolean(getErrorMessage(`businessType`, errors))}
                  helperText={getErrorMessage(`businessType`, errors)?.message}
                  name="businessType"
                  label="What is your specific business type?"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="How do you sell to your customers?" />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          name="processingType"
          label="How will you be processing payments?"
          defaultValue=""
          required
          errors={errors}
          control={control}
        >
          {Object.keys(ProcessingTypePayments).map(key =>
            displayProcessingType(key as ProcessingTypes)
          )}
        </RadioGroup>
      </Grid>

      {watchProcessingType === ProcessingTypes.cardPresent && (
        <Fragment>
          {applicationType !== ApplicationType.cashDiscount && (
            <Grid item xs={12}>
              <Select
                name="acceptPIN"
                label="Do you want to accept Pin based debit cards?"
                required
                errors={errors}
                control={control}
                yesOrNo
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Select
              name="ebtAcceptance"
              label="Do you accept EBT cards?"
              required
              errors={errors}
              control={control}
              options={Object.values(EbtAcceptanceStatus)}
              skipSortOptions
            />
          </Grid>
          {watchEbtAcceptance && watchEbtAcceptance === EbtAcceptanceStatus.Yes && (
            <Grid item xs={12}>
              <TextField
                name="ebtFNS"
                label="EBT FNS #"
                errors={errors}
                control={control}
                placeholder="0000000"
                transform={{
                  pattern: '9999999',
                }}
              />
            </Grid>
          )}
        </Fragment>
      )}
      <Grid item xs={12}>
        <Select
          name="previouslyProcessed"
          label="Have you accepted credit cards before?"
          required
          errors={errors}
          control={control}
          yesOrNo
        />
      </Grid>
      {watchProcessingType && watchProcessingType !== ProcessingTypes.cardPresent && (
        <Grid item xs={12}>
          <Select
            name="customerReceivesProductService"
            label="When do your customers receive your products or services?"
            required
            errors={errors}
            control={control}
            options={{
              '0-7 days': '0-7 days',
              '8-14 days': '8-14 days',
              '15-30 days': '15-30 days',
              '30+ days': '30+ days',
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          name="refundPolicy"
          label="What is your business refund policy?"
          required
          errors={errors}
          control={control}
          options={Object.values(RefundPolicy)}
        />
      </Grid>
      {watchRefundPolicy === RefundPolicy.other && (
        <Grid item xs={12}>
          <TextField
            name="refundPolicyExplained"
            label="Refund Policy Explanation"
            required
            errors={errors}
            control={control}
          />
        </Grid>
      )}
      {watchProcessingType === ProcessingTypes.eCommerce && (
        <>
          <Grid item xs={12}>
            <Select
              name="productOwner"
              label="Who owns the product?"
              required
              errors={errors}
              control={control}
              options={{
                Merchant: 'Merchant',
                'Vendor (Drop Ship Required)': 'Vendor',
              }}
            />
          </Grid>
          <Grid
            item
            xs={
              watchIsCustomerDepositRequired && String(watchIsCustomerDepositRequired) !== 'false'
                ? 6
                : 12
            }
          >
            <Select
              name="isCustomerDepositRequired"
              label="Are customers required to provide a deposit?"
              required
              errors={errors}
              control={control}
              yesOrNo
            />
          </Grid>
          {watchIsCustomerDepositRequired && String(watchIsCustomerDepositRequired) !== 'false' && (
            <Grid item xs={6}>
              <TextField
                name="customerDepositPercentage"
                label="Deposit Percentage"
                variant="outlined"
                margin="normal"
                control={control}
                errors={errors}
                fullWidth
                type="number"
                inputProps={{min: 0, max: 100}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                transform={{
                  pattern: '999',
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              name="productPurchasedNames"
              label="List the name(s)/addresses(es) where the product is purchased"
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="productPurchasedBy"
              label="Product purchased by"
              required
              errors={errors}
              control={control}
              options={{
                Merchant: 'Merchant',
                Vendor: 'Vendor',
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="fulfillmentCenterNames"
              label="List the name(s) of Fulfillment Center, Contact Name, Address, and Phone # or Email Address, if any"
              required={watchIsFulfillmentCenterNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsFulfillmentCenterNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isFulfillmentCenterNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsFulfillmentCenterNamesDefined}
                onChange={handleIsFulfillmentCenterNamesDefinedChange}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="callCenterNames"
              label="List the name(s) of call center(s) providers, if any"
              required={watchIsCallCenterNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsCallCenterNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isCallCenterNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsCallCenterNamesDefined}
                onChange={handleIsCallCenterNamesDefined}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="cbManagementSystemsNames"
              label="List the name(s) of chargeback management systems, if any"
              required={watchIsCbManagementSystemsNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsCbManagementSystemsNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isCbManagementSystemsNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsCbManagementSystemsNamesDefined}
                onChange={handleIsCbManagementSystemsNamesDefined}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{mt: 2}} fullWidth variant="outlined">
              <InputLabel id="shippingServices-label">
                What shipping service do you use to deliver products to customers
              </InputLabel>
              <MuiSelect
                name="shippingServices"
                variant="outlined"
                labelId="shippingService-label"
                label="What shipping service do you use to deliver products to customers"
                id="shippingServices"
                required
                error={Boolean(errors.shippingServices)}
                multiple
                renderValue={selected => (selected as string[]).join(', ')}
                value={chosenShippingServices}
                onChange={handleShippingServicesChange}
                MenuProps={MenuProps}
                fullWidth
              >
                {Object.entries(ShippingService).map(([, value]) => (
                  <MenuItem key={`${value}`} value={value}>
                    <MuiCheckbox
                      checked={chosenShippingServices.indexOf(value) > -1}
                      color="primary"
                    />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </MuiSelect>
              {errors.shippingServices?.message && (
                <FormHelperText error={true}>
                  {(errors.shippingServices as any)?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {chosenShippingServices.includes(ShippingService.other) && (
            <Grid item xs={12}>
              <TextField
                name="otherShippingServices"
                label="Other Shipping Method"
                required={chosenShippingServices.includes(ShippingService.other)}
                errors={errors}
                control={control}
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Sales Estimates" />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="estimatedMonthlyCreditCardSales"
          label="Monthly credit card sales"
          required
          errors={errors}
          control={control}
          options={estimatedMonthlyCreditCardSalesOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="estimatedAverageSale"
          label="Average amount per sale"
          required
          errors={errors}
          control={control}
          options={avgSaleOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="estimatedHighestSale"
          label="Estimated highest sale amount"
          required
          errors={errors}
          control={control}
          options={avgHighSaleOptions}
        />
      </Grid>
    </Grid>
  );
};

export default BasicsPage;
