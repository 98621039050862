import {ExternalServicesApi} from '@ozark/common/api';
import {GroupView} from '@ozark/functions/src/shared';
import throttle from 'lodash/throttle';
import React from 'react';

export type AutoCompleteOption = {
  text: string;
  value: any;
};

export type AutoCompleteOptions = AutoCompleteOption[];

export const useAutoComplete = (externalServices: ExternalServicesApi, group?: GroupView) => {
  const debounceRate = 500;
  const [institutionOptions, setInstitutionOptions] = React.useState<AutoCompleteOptions>([]);
  const [routingNumberOptions, setRoutingNumberOptions] = React.useState<AutoCompleteOptions>([]);
  const [institutionQuery, setInstitutionQuery] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const throttledInstitutionSearch = React.useMemo(
    () => throttle(input => externalServices.plaidInstitutionsSearch({query: input}), debounceRate),
    [externalServices, debounceRate]
  );

  React.useEffect(() => {
    let subscribed = true;
    if (
      group?.institutionServiceProvider === 'Plaid' &&
      subscribed &&
      institutionQuery.length > 1
    ) {
      (async () => {
        setLoading(true);
        const result = await throttledInstitutionSearch(institutionQuery);

        if (result?.institutions?.length) {
          setInstitutionOptions(
            result.institutions.map(({name, routingNumbers}) => ({
              text: name,
              value: routingNumbers,
            }))
          );
        }
        setLoading(false);
      })();
    }
    return () => {
      subscribed = false;
    };
  }, [institutionQuery, throttledInstitutionSearch, group]);

  const handleInstitutionSelect = (intitutionName: string) => {
    const option = institutionOptions.find(i => i.text === intitutionName);
    setRoutingNumberOptions(option?.value.map((value: string) => ({text: `${value}`, value})));
  };
  const handleInstitutionOptions = (value: any) => {
    setInstitutionOptions(value);
  };
  const handleInstitutionQuery = (value: any) => {
    setInstitutionQuery(value);
  };
  const handleRoutingNumberOptions = (value: any) => {
    setRoutingNumberOptions(value);
  };

  return {
    handleInstitutionSelect,
    handleInstitutionOptions,
    handleInstitutionQuery,
    handleRoutingNumberOptions,
    institutionQuery,
    routingNumberOptions,
    institutionOptions,
    loading,
  };
};
