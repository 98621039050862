import {
  TransferIntent,
  useApiClient,
  useCallable as useCommonCallable,
  useNotification,
  UserRoles,
} from '@ozark/common';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {ApplicationSteps, IStep} from '../../../constants/steps';
import {useStore} from '../../../store';

export const useApplicationFormManager = (
  isApplicationFormForAgentOrErpUser: boolean,
  enableRegistration: boolean,
  activeStep: IStep,
  steps: {[p: string]: IStep},
  goToNextStep: () => void
) => {
  const showNotification = useNotification();
  const {
    authUser,
    application,
    set,
    addApplicationUWRiskNote,
    addApplicationSupportNote,
    complete,
    signInAnonymousUser,
    createUserWithEmailAndPassword,
    group,
  } = useStore();
  const {welcomeEmail} = useCommonCallable();
  const {apiClient} = useApiClient(authUser);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [registration, setRegistration] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    businessPhone: string;
  } | null>(null);
  const history = useHistory();
  const [validationHandler, setValidationHandler] =
    useState<(onSuccess: any, onError?: any) => () => Promise<any>>();

  const clearSessionAndAdvanceToFinalPage = async () => {
    const {firstName, lastName, legalBusinessName, doingBusinessAs, distinguishableId} =
      application.data;

    history.push(isApplicationFormForAgentOrErpUser ? ROUTES.SUBMITTED : ROUTES.CONFIRMATION, {
      applicationData: {firstName, lastName, legalBusinessName, doingBusinessAs, distinguishableId},
      isTransferredApplication:
        authUser?.claims?.transferIntent === TransferIntent.transferApplication,
    });

    if (authUser?.claims?.portal && authUser?.claims?.role !== UserRoles.merchant) {
      window.close();
    }
  };

  const signInAndAdvance = async (data: any) => {
    setEmail(data.email);

    if (!enableRegistration) {
      const user = await signInAnonymousUser();
      if (!user) {
        console.error('failed to sign in anonymous user.');
      }
    } else {
      setRegistration({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        businessPhone: data.businessPhone,
      });
      try {
        const user = await createUserWithEmailAndPassword(data.email, data.password);
        if (!user) {
          console.error('failed to create account.');
          return;
        }
        if (group.data) {
          await welcomeEmail({
            emailTo: data.email,
            groupId: group.data.id,
          });
        }
      } catch (err: any) {
        showNotification(
          'error',
          err.code === 'auth/email-already-in-use'
            ? 'Account has already been created with this email address. Please sign in to start a new application.'
            : 'Unexpected error trying to start your application.'
        );
      }
    }

    setLoading(false);
  };

  const saveAppAndAdvance = async (data: any) => {
    try {
      await set(activeStep.next ? {nextPath: steps[activeStep.next].key, ...data} : data);
      goToNextStep();
    } catch (err) {
      console.error('failed to save application', err);
    }
  };

  const saveAppWithoutAdvance = async (data: any) => {
    try {
      await set(data);
      return true;
    } catch (err) {
      console.error('failed to save application', err);
    }
    return false;
  };

  const addApplicationNotes = async (data: any) => {
    if (data.agentNotes) {
      await addApplicationUWRiskNote(data.agentNotes, application.data?.createdBy);
      await addApplicationSupportNote(data.agentNotes, application.data?.createdBy);
    }
  };

  // TODO: TG-680 Remove "any" types from this hook and add strong types for all application steps forms
  const handleSuccess = async (data: any) => {
    if (activeStep.key === ApplicationSteps.start) {
      signInAndAdvance(data);
      return;
    }

    if (isApplicationFormForAgentOrErpUser && activeStep.next) {
      await saveAppAndAdvance(data);
      setLoading(false);
      return;
    }

    if (isApplicationFormForAgentOrErpUser) {
      try {
        if (data.agentNotes) {
          await addApplicationNotes(data);
          await set({agentNotes: data.agentNotes});
        }
        await complete(application.data.id, data.signature);
        await clearSessionAndAdvanceToFinalPage();
      } catch (err) {
        console.error('failed to complete application', err);
      }
      setLoading(false);
      return;
    }

    // When instant id enabled, submitting an application on the review page will initialize instant id session,
    // complete app, and advance to the instand id page. When instant id is disabled or fails to initialize,
    // continuing will complete app and advance to the final page.
    if (activeStep.key === ApplicationSteps.review && activeStep.next) {
      try {
        await apiClient.externalServices.instantIdQandAInit({applicationId: application.data.id});
      } catch (err: any) {
        await set({loaAgree: data.loaAgree ?? null});
        await addApplicationNotes(application.data);
        await complete(application.data.id, data.signature);
        await clearSessionAndAdvanceToFinalPage();
        setLoading(false);
        return;
      }
      await set({nextPath: steps[activeStep.next].key, loaAgree: data.loaAgree ?? null});
      await addApplicationNotes(application.data);
      await complete(application.data.id, data.signature);

      goToNextStep();
    } else if (activeStep.next) {
      await saveAppAndAdvance(data);
    } else {
      await clearSessionAndAdvanceToFinalPage();
    }
    setLoading(false);
  };

  const handleError = (data: any) => {
    console.error(data);
    setLoading(false);
  };

  return {
    handleError,
    handleSuccess,
    validationHandler,
    setValidationHandler,
    registration,
    email,
    setEmail,
    loading,
    setLoading,
    clearSessionAndAdvanceToFinalPage,
    saveAppWithoutAdvance,
  };
};
