import {PageAnswers, SectionAnswers} from '../types';
import {useSummaryCommon} from './useSummaryCommon';

export const useSignerInfoStepAnswers = () => {
  const {emptyAnswers, getAvailableQuestionAnswer, getQuestionAnswer, getYesNo, app} =
    useSummaryCommon();

  const getSignerInfoStepAnswersBase = (percentOwnershipQuestion: string): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    const isPercentOwnershipLess75 = !!app.percentOwnership && app.percentOwnership < 75;
    const pageAnswers = {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Signer Info',
          questionAnswers: [
            getAvailableQuestionAnswer('First Name', app.firstName),
            getAvailableQuestionAnswer('Last Name', app.lastName),
            getAvailableQuestionAnswer('Date of Birth', app.dateOfBirth),
            getAvailableQuestionAnswer('Social Security Number', `**********`),
            getAvailableQuestionAnswer('Driver License State', app.driverLicenseState),
            getAvailableQuestionAnswer('Driver License Number', app.driverLicenseNumber),
            getAvailableQuestionAnswer('Cell Phone', app.cellPhone),
          ],
        },
        {
          isAvailable: true,
          header: 'Home Address',
          questionAnswers: [
            getAvailableQuestionAnswer('Address Line 1', app.homeAddress1),
            getAvailableQuestionAnswer('Address Line 2', app.homeAddress2),
            getAvailableQuestionAnswer('City', app.homeCity),
            getAvailableQuestionAnswer('State', app.homeState),
            getAvailableQuestionAnswer('Zip Code', app.homeZip),
          ],
        },
        {
          isAvailable: true,
          header: 'Business Ownership',
          questionAnswers: [
            getAvailableQuestionAnswer(percentOwnershipQuestion, app.percentOwnership),
            getAvailableQuestionAnswer('Title', app.title),
            getQuestionAnswer(
              'Are there any other individuals with ownership above 25%?',
              getYesNo(app.otherOwners),
              isPercentOwnershipLess75
            ),
          ],
        },
      ],
    };
    if (isPercentOwnershipLess75 && app.otherOwners && app.otherOwnersData) {
      app.otherOwnersData.map((otherOwner, index) => {
        const sectionAnswer = {
          isAvailable: true,
          header: `Owner #${index + 2}`,
          questionAnswers: [
            getAvailableQuestionAnswer('Ownership Percentage', otherOwner.percentOwnership),
            getAvailableQuestionAnswer('Title', otherOwner.title),
            getAvailableQuestionAnswer('First Name', otherOwner.firstName),
            getAvailableQuestionAnswer('Last Name', otherOwner.lastName),
            getAvailableQuestionAnswer('Date of Birth', otherOwner.dateOfBirth),
            getAvailableQuestionAnswer('Social Security Number', `**********`),
            getAvailableQuestionAnswer('Driver License State', otherOwner.driverLicenseState),
            getAvailableQuestionAnswer('Driver License Number', otherOwner.driverLicenseNumber),
            getAvailableQuestionAnswer('Address Line 1', otherOwner.homeAddress1),
            getAvailableQuestionAnswer('Address Line 2', otherOwner.homeAddress2),
            getAvailableQuestionAnswer('City', otherOwner.homeCity),
            getAvailableQuestionAnswer('State', otherOwner.homeState),
            getAvailableQuestionAnswer('Zip Code', otherOwner.homeZip),
          ],
        } as SectionAnswers;
        pageAnswers.sectionAnswers.push(sectionAnswer);
        return true;
      });
    }
    return pageAnswers;
  };

  const getSignerInfoStepAnswers = (): PageAnswers => {
    return getSignerInfoStepAnswersBase('What percentage of the business do you own?');
  };

  const getSignerInfoStepAnswersForAgentOrErpUser = (): PageAnswers => {
    return getSignerInfoStepAnswersBase('What percentage of the business does the signer own?');
  };

  return {
    getSignerInfoStepAnswers,
    getSignerInfoStepAnswersForAgentOrErpUser,
  };
};
