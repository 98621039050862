import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BusinessIcon from '@mui/icons-material/Business';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WebIcon from '@mui/icons-material/Web';
import {Hidden} from '@mui/material';
import React from 'react';
import * as ROUTES from './routes';

export interface IStep {
  index: number;
  back?: string;
  next?: string;
  key: string;
  stepIcon: React.ReactElement;
  stepLabel: React.ReactElement | string;
}

export const FIRST_APPLICATION_STEP = ROUTES.BASICS;
export const FIRST_AGENT_APPLICATION_STEP = ROUTES.PROGRAM;

export enum ApplicationSteps {
  start = 'start',
  basics = 'basics',
  business = 'business',
  signer = 'signer',
  deposits = 'deposits',
  program = 'program',
  review = 'review',
  instantIdQanda = 'instant-id-qanda',
}

const steps: {[index: string]: IStep} = {
  [ROUTES.START]: {
    index: 0,
    next: ROUTES.BASICS,
    key: ApplicationSteps.start,
    stepIcon: <PlayArrowIcon />,
    stepLabel: <>Start</>,
  },
  [ROUTES.BASICS]: {
    index: 1,
    next: ROUTES.BUSINESS,
    key: ApplicationSteps.basics,
    stepIcon: <WebIcon />,
    stepLabel: (
      <>
        <Hidden smDown>Just the </Hidden>Basics
      </>
    ),
  },
  [ROUTES.BUSINESS]: {
    index: 2,
    back: ROUTES.BASICS,
    next: ROUTES.SIGNER,
    key: ApplicationSteps.business,
    stepIcon: <BusinessIcon />,
    stepLabel: (
      <>
        <Hidden smDown>Your </Hidden>Business
      </>
    ),
  },
  [ROUTES.SIGNER]: {
    index: 3,
    back: ROUTES.BUSINESS,
    next: ROUTES.DEPOSITS,
    key: ApplicationSteps.signer,
    stepIcon: <AccountCircleIcon />,
    stepLabel: (
      <>
        Signer<Hidden smDown> Info</Hidden>
      </>
    ),
  },
  [ROUTES.DEPOSITS]: {
    index: 4,
    back: ROUTES.SIGNER,
    next: ROUTES.PROGRAM,
    key: ApplicationSteps.deposits,
    stepIcon: <AccountBalanceIcon />,
    stepLabel: (
      <>
        Deposit<Hidden smDown> Info</Hidden>
      </>
    ),
  },
  [ROUTES.PROGRAM]: {
    index: 5,
    back: ROUTES.DEPOSITS,
    next: ROUTES.REVIEW,
    key: ApplicationSteps.program,
    stepIcon: <LoyaltyIcon />,
    stepLabel: (
      <>
        <Hidden smDown>Select your </Hidden>Program
      </>
    ),
  },
  [ROUTES.REVIEW]: {
    index: 6,
    back: ROUTES.PROGRAM,
    next: ROUTES.INSTANT_ID_Q_AND_A,
    key: ApplicationSteps.review,
    stepIcon: <AssignmentTurnedInIcon />,
    stepLabel: <>Review</>,
  },

  [ROUTES.INSTANT_ID_Q_AND_A]: {
    index: 7,
    key: ApplicationSteps.instantIdQanda,
    stepIcon: <FingerprintIcon />,
    stepLabel: <>Instant Id Q&A</>,
  },
};

const agentSteps: {[index: string]: IStep} = {
  [ROUTES.START]: {
    index: 0,
    next: ROUTES.PROGRAM,
    key: ApplicationSteps.start,
    stepIcon: <PlayArrowIcon />,
    stepLabel: <>Start</>,
  },
  [ROUTES.PROGRAM]: {
    index: 1,
    next: ROUTES.BASICS,
    key: ApplicationSteps.program,
    stepIcon: <LoyaltyIcon />,
    stepLabel: <>Program</>,
  },
  [ROUTES.BASICS]: {
    index: 2,
    back: ROUTES.PROGRAM,
    next: ROUTES.BUSINESS,
    key: ApplicationSteps.basics,
    stepIcon: <WebIcon />,
    stepLabel: (
      <>
        <Hidden smDown>Just the </Hidden>Basics
      </>
    ),
  },
  [ROUTES.BUSINESS]: {
    index: 3,
    back: ROUTES.BASICS,
    next: ROUTES.SIGNER,
    key: ApplicationSteps.business,
    stepIcon: <BusinessIcon />,
    stepLabel: (
      <>
        <Hidden smDown>Your </Hidden>Business
      </>
    ),
  },
  [ROUTES.SIGNER]: {
    index: 4,
    back: ROUTES.BUSINESS,
    next: ROUTES.DEPOSITS,
    key: ApplicationSteps.signer,
    stepIcon: <AccountCircleIcon />,
    stepLabel: (
      <>
        Signer<Hidden smDown> Info</Hidden>
      </>
    ),
  },
  [ROUTES.DEPOSITS]: {
    index: 5,
    back: ROUTES.SIGNER,
    next: ROUTES.REVIEW,
    key: ApplicationSteps.deposits,
    stepIcon: <AccountBalanceIcon />,
    stepLabel: (
      <>
        Deposit<Hidden smDown> Info</Hidden>
      </>
    ),
  },
  [ROUTES.REVIEW]: {
    index: 6,
    back: ROUTES.DEPOSITS,
    key: ApplicationSteps.review,
    stepIcon: <AssignmentTurnedInIcon />,
    stepLabel: <>Review</>,
  },
};

export const getSteps = (isAgentApplication: boolean = false) => {
  return isAgentApplication ? agentSteps : steps;
};
