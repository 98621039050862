/* eslint-disable react-hooks/exhaustive-deps */
import {Firebase} from '@ozark/common';
import {useEffect, useMemo, useState} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store';
import {AsyncState} from './AsyncState';

export const usePortal = () => {
  const history = useHistory();
  const {startApplication, signInWithCustomToken} = useStore();
  const [portalState, setPortalState] = useState<
    AsyncState<{applicationId: string; readonly: boolean; disableLogRocket: boolean}>
  >({
    promised: true,
  });

  const token = useMemo(() => {
    const query = new URLSearchParams(history.location.search);
    return query.get('token');
  }, [history.location.search]);

  const disableLogRocket = useMemo(() => {
    const query = new URLSearchParams(history.location.search);
    const disableLogRocketParam = query.get('disableLogRocket');
    if (disableLogRocketParam) {
      return Boolean(parseInt(disableLogRocketParam));
    }

    return false;
  }, [history.location.search]);

  useEffect(() => {
    if (!token) {
      return;
    }

    let mounted: boolean = true;

    const handleAuthByToken = async () => {
      const user = await signInWithCustomToken(token);
      if (!user) {
        history.push('/');
        return;
      }

      const idToken = await user.user?.getIdTokenResult();
      if (!mounted) {
        return;
      }

      if (!idToken) {
        // TODO return an error
        window.parent.postMessage('Error', '*');
        console.error('Failed to get idToken result for the following user', user);
        return;
      }

      const {portal, applicationId, readonly, resume, sourceApplicationId} = idToken.claims;

      // only { portal: true } claim should make it this far
      if (!portal) {
        // Redirect to error page
        return;
      }

      if (applicationId && !resume) {
        setPortalState({
          data: {disableLogRocket, applicationId, readonly: Boolean(readonly)},
          promised: false,
        });
        return;
      }

      const email = user.user?.email;

      if (!email) {
        await Firebase.auth.signOut();
        history.push(ROUTES.START);
        return;
      }

      if (resume) {
        if (!applicationId) throw Error('application id is empty');
        history.push(generatePath(ROUTES.APPLICATION_DETAILS, {applicationId}));
      } else {
        const newAppId = await startApplication({
          email,
          sourceApplicationId,
        });

        if (newAppId) {
          history.push(generatePath(ROUTES.APPLICATION_DETAILS, {applicationId: newAppId}));
        }
      }
    };

    handleAuthByToken();

    return () => {
      mounted = false;
    };
  }, [token, signInWithCustomToken]);

  return {
    portalState,
  };
};
