import {yupResolver} from '@hookform/resolvers/yup';
import {Alert, Grid, MenuItem, TextField as MuiTextField} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Agent,
  AgentView,
  ApplicationOwner,
  Collections,
  Firebase,
  hasOzarkRole,
  ProcessingTypes,
  TransferIntent,
  useNotification,
  UserRoles,
} from '@ozark/common';
import {HookFormCheckBox, SectionTitle, TextField} from '@ozark/common/components';
import {emptyStringToNull} from '@ozark/common/helpers/schemaValidationHelpers';
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {getSteps} from '../../constants/steps';
import {useStore} from '../../store';
import {PageSummary} from '../ReviewPage/Summary';
import {useSummary} from '../ReviewPage/Summary/hooks';
import Title from '../Title';
import {UploadDocuments} from '../UploadDocuments';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
}));

interface Props {
  setValidationHandler(handleSubmit: any): any;
}

const schema = yup.object().shape({
  mpaAttached: yup
    .boolean()
    .test(
      'mpaAttached',
      'You must attach the wet signed Merchant Processing Agreement.',
      (attached: boolean | undefined) => {
        return !!attached;
      }
    )
    .typeError('You must attach the wet signed Merchant Processing Agreement.'),
  agreeMpaUploaded: yup
    .boolean()
    .test('agreeMpaUploaded', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection4: yup
    .boolean()
    .test('agreeSection4', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection5: yup
    .boolean()
    .test('agreeSection5', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection6: yup
    .boolean()
    .test('agreeSection6', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection7: yup
    .boolean()
    .when('processingType', (processingType: ProcessingTypes, schema: any) => {
      if (processingType !== ProcessingTypes.cardPresent) {
        return schema
          .test(
            'agreeSection7',
            'You must complete this section.',
            (agree: boolean | undefined) => {
              return !!agree;
            }
          )
          .typeError('You must complete this section');
      }
      return schema.transform(emptyStringToNull).nullable();
    }),
  agreeSection9: yup
    .boolean()
    .test('agreeSection9', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection12: yup
    .boolean()
    .test('agreeSection12', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
  agreeSection13: yup
    .boolean()
    .test('agreeSection13', 'You must complete this section.', (agree: boolean | undefined) => {
      return !!agree;
    })
    .typeError('You must complete this section'),
});

const ReviewPageForAgents = ({setValidationHandler}: Props) => {
  const {authUser, application, set} = useStore();
  const showNotification = useNotification();
  const [agents, setAgents] = useState<AgentView[]>([]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      ...(application.data ?? {}),
    },
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const isCrmUser =
    authUser.claims &&
    authUser?.claims?.role !== UserRoles.agent &&
    authUser?.claims?.role !== UserRoles.merchant;

  const beforeSubmit = useCallback(() => {}, []);
  const {getFlowAnswersForAgentOrErpUser} = useSummary();

  useEffect(() => {
    if (authUser.claims && hasOzarkRole(authUser.claims.role as UserRoles)) {
      Firebase.firestore
        .collection(Collections.agents)
        .get()
        .then(snapshot => {
          if (snapshot.size <= 0) setAgents([]);
          setAgents(
            snapshot.docs.map(e => {
              return {id: e.id, ...e.data()} as unknown as AgentView;
            })
          );
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(() => (onSuccess: any, onError: any) => {
      if (!application.data?.dateOfBirth) {
        onError("Missing Date of Birth in application. Can't submit.");
        showNotification(
          'error',
          'Error: Date of Birth is missing in application. Please enter a valid date of birth to proceed with the application'
        );
      }
      if (!application.data?.federalTaxId) {
        onError("Missing Federal Tax Id in application. Can't submit.");
        showNotification(
          'error',
          'Error: Federal Tax Id is missing in application. Please enter a valid federal tax id to proceed with the application'
        );
      }
      if (!application.data?.encryptedSocialSecurityNumber) {
        onError("Missing SSN in application. Can't submit.");
        showNotification(
          'error',
          'Error: Social Security number is missing in application. Please enter a valid SSN to proceed with the application'
        );
      }
      if (!application.data?.driverLicenseNumber || !application.data?.driverLicenseState) {
        onError("Driver's license information. Can't submit.");
        showNotification(
          'error',
          'Error: Drivers license info is missing in application. Please enter a valid drivers license and state to proceed with the application'
        );
      }
      if (!application.data?.routingNumber || !application.data?.bankAccountNumber) {
        onError("Banking info is missing. Can't submit.");
        showNotification(
          'error',
          'Error: Banking info is missing in application. Please enter a valid bank account and routing number to proceed with the application'
        );
      }

      if (application.data?.otherOwners) {
        const otherOwnersData = (application.data?.otherOwnersData as ApplicationOwner[]) || [];
        for (const otherOwner of otherOwnersData) {
          if (!otherOwner.dateOfBirth) {
            onError("Missing Date of Birth of an additional owner. Can't submit.");
            showNotification(
              'error',
              'Error: Date of Birth of an additional owner is missing in application. Please enter a valid date of birth to proceed with the application'
            );
          }
          if (!otherOwner.encryptedSocialSecurityNumber) {
            onError("Missing SSN of an additional owner. Can't submit.");
            showNotification(
              'error',
              'Error: Social Security number of an additional owner is missing in application. Please enter a valid SSN to proceed with the application'
            );
          }
          if (!otherOwner.driverLicenseNumber || !otherOwner.driverLicenseState) {
            onError("Driver's license information of an additional owner. Can't submit.");
            showNotification(
              'error',
              'Error: Drivers license info of an additional owner is missing in application. Please enter a valid drivers license and state to proceed with the application'
            );
          }
        }
      }

      if (
        !application.data?.dateOfBirth ||
        !application.data?.federalTaxId ||
        !application.data?.encryptedSocialSecurityNumber ||
        !application.data?.driverLicenseNumber ||
        !application.data?.driverLicenseState ||
        !application.data?.routingNumber ||
        !application.data?.bankAccountNumber ||
        (application.data?.otherOwners &&
          application.data?.otherOwnersData?.some(
            (owner: ApplicationOwner) =>
              !owner.encryptedSocialSecurityNumber ||
              !owner.dateOfBirth ||
              !owner.driverLicenseNumber ||
              !owner.driverLicenseState
          ))
      ) {
        return;
      }

      beforeSubmit();
      return _handleSubmit(onSuccess, onError);
    });
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit, beforeSubmit, application.data]);

  useEffect(() => {
    // register processingType so Yup can use it to validate
    register('processingType');
  }, [register]);

  const handleAgentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const agent = agents.find(e => e.id === event.target.value);
    if (!agent) return;
    await set({
      agent: {id: agent.id, firstName: agent.firstName, lastName: agent.lastName},
      group: agent.group,
    });
  };

  const classes = useStyles();
  const flowAnswers = getFlowAnswersForAgentOrErpUser();
  const steps = useMemo(() => getSteps(false), []);
  const stepArray = Object.values(steps);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Review" h2="You're almost done." />
      </Grid>
      {authUser.claims?.transferIntent !== TransferIntent.transferApplication && (
        <Grid item xs={12}>
          <PageSummary
            pageAnswers={flowAnswers.selectYourProgramStepAnswers}
            prevRoute={stepArray[4].next as string}
            anchorId="selectYourProgramStepAnswers"
            step={stepArray[5]}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.basicStepAnswers}
          prevRoute={stepArray[0].next as string}
          anchorId="basicStepAnswers"
          step={stepArray[1]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.yourBusinessStepAnswers}
          prevRoute={stepArray[1].next as string}
          anchorId="yourBusinessStepAnswers"
          step={stepArray[2]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.signerInfoStepAnswers}
          prevRoute={stepArray[2].next as string}
          anchorId="signerInfoStepAnswers"
          step={stepArray[3]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.depositInfoStepAnswers}
          prevRoute={stepArray[3].next as string}
          anchorId="depositInfoStepAnswers"
          step={stepArray[4]}
        />
      </Grid>
      {agents && agents.length > 0 && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Application Ownership" />
          </Grid>
          <Grid item xs={12}>
            <MuiTextField
              name="agentId"
              label="Agent"
              variant="outlined"
              margin="normal"
              fullWidth
              value={application.data?.agent?.id}
              error={Boolean(errors.agentId)}
              helperText={
                typeof errors.agentId?.message === 'string' ? errors.agentId?.message : ''
              }
              onChange={handleAgentChange}
              select
            >
              {agents.sortAndMap(
                (e: Agent) => (
                  <MenuItem value={e.id}>
                    {e.group.name} - {e.firstName} {e.lastName}
                  </MenuItem>
                ),
                e => `${e.group.name} - ${e.firstName} ${e.lastName}`
              )}
            </MuiTextField>
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Upload Documents" />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info" className={classes.alert}>
          <>
            Please attach the wet signed Merchant Processing Agreement along with supporting
            documents.
          </>
        </Alert>
      </Grid>
      {application.data &&
        ((application.data?.estimatedMonthlyCreditCardSales || 0) >= 250000 ||
          (application.data?.estimatedAverageSale || 0) > 2500 ||
          (application.data?.estimatedHighestSale || 0) > 2500) && (
          <Grid item xs={12}>
            <Alert severity="info" className={classes.alert}>
              <>
                Please upload the Last 3 Months Processing Statements or Last 3 Months Bank
                Statements.
              </>
            </Alert>
          </Grid>
        )}
      <UploadDocuments errors={errors} setValue={setValue} isPortalUser={!isCrmUser} />
      <Grid item xs={12}>
        <SectionTitle text="Additional Notes" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="agentNotes"
          label="Notes for Underwriting"
          errors={errors}
          control={control}
          multiline
          minRows={2}
          maxRows={4}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Acknowledgments" />
      </Grid>
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeMpaUploaded"
          name="agreeMpaUploaded"
          label={'I have uploaded the wet signed Merchant Processing Agreement.'}
          required
          control={control}
        />
      </Grid>
      {errors && errors.agreeMpaUploaded && typeof errors.agreeMpaUploaded.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeMpaUploaded.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection4"
          name="agreeSection4"
          label={
            "I have completed 'Section 4: Important Disclosures' and it has been signed by the merchant."
          }
          required
          control={control}
        />
      </Grid>
      {errors && errors.agreeSection4 && typeof errors.agreeSection4.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection4.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection5"
          name="agreeSection5"
          label={"I have completed 'Section 5: Compliance Information'."}
          required
          control={control}
        />
      </Grid>{' '}
      {errors && errors.agreeSection5 && typeof errors.agreeSection5.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection5.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection6"
          name="agreeSection6"
          label={
            "I have completed 'Section 6: Site Inspection Information' and it has been signed by me as the agent."
          }
          required
          control={control}
        />
      </Grid>{' '}
      {errors && errors.agreeSection6 && typeof errors.agreeSection6.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection6.message}</Alert>
        </Grid>
      )}
      {application.data?.processingType !== ProcessingTypes.cardPresent && (
        <Grid item xs={12}>
          <HookFormCheckBox
            aria-label="AgreeSection7"
            name="agreeSection7"
            label={
              "I have completed 'Section 7: Advertising, Sales and Delivery Questions' for CNP/ECOM merchants."
            }
            required
            control={control}
          />
        </Grid>
      )}
      {errors && errors.agreeSection7 && typeof errors.agreeSection7.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection7.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection9"
          name="agreeSection9"
          label={
            "I have completed 'Section 9: Unlimited Personal Guaranty and Credit Information' and it has been signed by the merchant."
          }
          required
          control={control}
        />
      </Grid>{' '}
      {errors && errors.agreeSection9 && typeof errors.agreeSection9.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection9.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection13"
          name="agreeSection12"
          label={
            "I have completed 'Section 12: Patriot Act and Background Authorization' and it has been signed by the merchant."
          }
          required
          control={control}
        />
      </Grid>{' '}
      {errors && errors.agreeSection12 && typeof errors.agreeSection12.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection12.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <HookFormCheckBox
          aria-label="AgreeSection13"
          name="agreeSection13"
          label={
            "I have completed 'Section 13: Merchant Acknowledgements and Signatures' and it has been signed by the merchant."
          }
          required
          control={control}
        />
      </Grid>{' '}
      {errors && errors.agreeSection13 && typeof errors.agreeSection13.message === 'string' && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agreeSection13.message}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default ReviewPageForAgents;
