import {Button, CircularProgress, Hidden, styled} from '@mui/material';
import {IStep} from '../../../constants/steps';
import Header from '../../Header';

type Props = {
  loading: boolean;
  onSaveTemplate?: () => void;
  onTransferToMerchant?: () => void;
  onSaveProgress?: () => void;
  onSignOutClick: () => Promise<void>;
  onDownloadPdf?: () => Promise<void>;
  activeStep: IStep;
};

const CircularProgressStyled = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export const ApplicationFormHeader = ({
  loading,
  onSaveTemplate,
  onTransferToMerchant,
  onSaveProgress,
  onSignOutClick,
  onDownloadPdf,
  activeStep,
}: Props) => {
  return (
    <Header>
      <>
        {onDownloadPdf && activeStep?.index > 1 && (
          <Button variant="outlined" color="primary" onClick={onDownloadPdf} disabled={loading}>
            {loading && <CircularProgressStyled size={24} />}
            Download PDF
          </Button>
        )}
        {onSaveTemplate && (
          <Button variant="outlined" color="primary" onClick={onSaveTemplate}>
            Save Template
          </Button>
        )}
        {onTransferToMerchant && activeStep?.index > 0 && (
          <Button
            variant={activeStep.index === 1 ? 'outlined' : 'contained'}
            color={activeStep.index === 1 ? 'primary' : 'success'}
            onClick={onTransferToMerchant}
          >
            Transfer To Merchant
          </Button>
        )}
        {onSaveProgress && (
          <Button variant="outlined" color="primary" onClick={onSaveProgress}>
            Save Progress
          </Button>
        )}
        {['ozark-staging', 'getevolved-staging'].includes(
          process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ''
        ) && (
          <Button variant="outlined" color="primary" onClick={onSignOutClick}>
            End<Hidden mdDown> Session</Hidden>
          </Button>
        )}
      </>
    </Header>
  );
};
