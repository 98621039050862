import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, ButtonTypeMap, Divider, Typography} from '@mui/material';
import {ConfirmationDialog, ConfirmationDialogField} from '@ozark/common/components';
import {useCallback, useState} from 'react';

interface Props {
  description?: string;
  equipmentRemove?: () => void;
  editDescription?: (description: string) => void;
}

const buttonProps: ButtonTypeMap['props'] = {
  variant: 'text',
  color: 'secondary',
};

type DialogOpen = {
  isOpen: boolean;
  confirmationAction?: (() => Promise<void>) | null;
  message?: string;
};

type EditForm = {
  description?: string;
};

export const AdditionalEquipmentBar = ({description, equipmentRemove, editDescription}: Props) => {
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<DialogOpen>({
    isOpen: false,
  });
  const [editConfirmationDialog, setEditConfirmationDialog] = useState<DialogOpen>({
    isOpen: false,
  });
  const handleEquipmentRemove = useCallback(() => {
    setDeleteConfirmationDialog({
      isOpen: true,
      confirmationAction: async () => {
        equipmentRemove?.();
      },
      message: 'Are you sure you want to delete additional equipment?',
    });
  }, [equipmentRemove]);

  const handleEquipmentEdit = useCallback(() => {
    setEditConfirmationDialog({
      isOpen: true,
      confirmationAction: async (form?: EditForm) => {
        if (form?.description) {
          editDescription?.(form.description);
        }
      },
      message: '',
    });
  }, [editDescription]);

  return (
    <Box
      sx={{
        marginTop: theme => theme.spacing(2),
        marginBottom: theme => theme.spacing(2),
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          sx={theme => ({
            fontSize: '1.2em',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1em',
            },
          })}
          variant="caption"
          gutterBottom
        >
          {`Additional Equipment: ${description}`}
        </Typography>
        <Box display="flex">
          <Button {...buttonProps} onClick={() => handleEquipmentEdit()} startIcon={<EditIcon />}>
            Edit Description
          </Button>
          <Button
            {...buttonProps}
            onClick={() => handleEquipmentRemove()}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Divider />
      {deleteConfirmationDialog.isOpen && (
        <ConfirmationDialog
          title="Confirmation"
          message={deleteConfirmationDialog.message ?? ''}
          onClose={() =>
            setDeleteConfirmationDialog({
              isOpen: false,
            })
          }
          onConfirm={deleteConfirmationDialog.confirmationAction ?? null}
        />
      )}
      {editConfirmationDialog.isOpen && (
        <ConfirmationDialog
          title="Edit Description"
          message={editConfirmationDialog.message ?? ''}
          fields={[
            new ConfirmationDialogField('description', 'Description', description ?? '', null),
          ]}
          onClose={() =>
            setEditConfirmationDialog({
              isOpen: false,
            })
          }
          onConfirm={editConfirmationDialog.confirmationAction ?? null}
        />
      )}
    </Box>
  );
};
