import * as yup from 'yup';
import {EquipmentDurationType, EquipmentFileType, EquipmentTipMode} from '../../../documents';
import {emptyStringToNull} from '../../../helpers';
import {PaymentType} from '../../PaymentType';
import {PlacementType} from '../../PlacementType';
import {timeList} from '../../TimeList';
import {
  getCustomErrorMessage,
  getNumberTypeError,
  getStringTypeError,
  oneOfAllowEmpty,
} from '../Common';
import {GREATER_THAN_ZERO} from './ApplicationProgramEquipmentValidation';

const cashDiscountPricingIsCappedAt = (cap: number, symbol: string) =>
  `Cash Discount Pricing is Capped at ${cap}${symbol}`;

export const getEquipmentSchema = ({
  useCustomErrorMessage,
  cashDiscountCap,
}: {
  useCustomErrorMessage: boolean;
  cashDiscountCap?: number;
}) =>
  yup.object({
    id: yup.string().required(),
    softwareName: yup.string().when(['isVarSheet'], {
      is: (isVarSheet: boolean | undefined) => {
        return !!isVarSheet;
      },
      then: yup
        .string()
        .required(getCustomErrorMessage(useCustomErrorMessage, 'Software Name is required')),
      otherwise: yup.string().nullable(),
    }),
    name: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Equipment/Gateway is required')),
    price: yup
      .number()
      .min(0)
      .when(['isGateway', 'isOther'], {
        is: (isGateway: boolean, isOther: boolean) => {
          return !Boolean(isGateway) && !Boolean(isOther);
        },
        then: yup
          .number()
          .min(0)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Price is required'))
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Price is required')),
      }),
    duration: yup.string().when(['isGateway', 'isOther'], {
      is: (isGateway: boolean, isOther: boolean) => {
        return (
          isGateway !== undefined &&
          !Boolean(isGateway) &&
          isOther !== undefined &&
          !Boolean(isOther)
        );
      },
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required(getCustomErrorMessage(useCustomErrorMessage, 'Billing method is required'))
        .typeError(getStringTypeError(useCustomErrorMessage, 'Billing method is required'))
        .oneOf(Object.values(EquipmentDurationType)),
    }),
    connectionType: yup.string().optional(),
    fileType: yup
      .string()
      .nullable()
      .when('isGateway', (isGateway: boolean, schema: any) => {
        if (isGateway) {
          return schema.test(oneOfAllowEmpty(Object.values(EquipmentFileType)));
        }
        return schema
          .required(getCustomErrorMessage(useCustomErrorMessage, 'File Type is required'))
          .oneOf(Object.values(EquipmentFileType));
      }),
    tipMode: yup
      .string()
      .nullable()
      .when('fileType', (fileType: EquipmentFileType, schema: any) => {
        if (fileType === EquipmentFileType.restaurant) {
          return schema
            .required(getCustomErrorMessage(useCustomErrorMessage, 'Tip Mode is required'))
            .oneOf(Object.values(EquipmentTipMode));
        } else {
          return schema.test(oneOfAllowEmpty(Object.values(EquipmentTipMode)));
        }
      }),
    cashDiscount: yup.boolean().transform(emptyStringToNull).nullable(),
    cashDiscountPercent: yup.number().when(['cashDiscount', 'isOther'], {
      is: (cashDiscount: boolean, isOther: boolean) => {
        return Boolean(cashDiscount) && !Boolean(isOther);
      },
      then: schema => {
        schema = yup
          .number()
          .min(0, GREATER_THAN_ZERO)
          .required(
            getCustomErrorMessage(
              useCustomErrorMessage,
              'Cash Discount Percent Billed to Card Holder is required'
            )
          )
          .typeError(
            getNumberTypeError(
              useCustomErrorMessage,
              'Cash Discount Percent Billed to Card Holder is required'
            )
          );

        if (typeof cashDiscountCap !== 'undefined') {
          schema = schema.max(cashDiscountCap, cashDiscountPricingIsCappedAt(cashDiscountCap, '%'));
        }

        return schema;
      },
      otherwise: yup.number().transform(emptyStringToNull).nullable(),
    }),
    autoBatch: yup.boolean().transform(emptyStringToNull).nullable(),
    autoBatchTime: yup.string().when('autoBatch', (autoBatch: boolean, schema: any) => {
      if (autoBatch) {
        return schema
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Time is required'))
          .typeError(getStringTypeError(useCustomErrorMessage, 'Time is required'))
          .oneOf(timeList());
      }
      return schema.transform(emptyStringToNull).nullable().test(oneOfAllowEmpty(timeList()));
    }),
    contactless: yup.boolean().transform(emptyStringToNull).nullable(),
    shippingSpeed: yup.string(),
    placementType: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Placement type is required'))
      .oneOf(Object.values(PlacementType)),
    paymentType: yup
      .string()
      .nullable()
      .when('isGateway', (isGateway: boolean, schema: any) => {
        if (isGateway) {
          return schema.test(oneOfAllowEmpty(Object.values(PaymentType)));
        }
        return schema
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Payment Type is required'))
          .oneOf(Object.values(PaymentType));
      }),
    virtualSetupFee: yup.number().when(['placementType', 'isGateway'], {
      is: (placementType: PlacementType, isGateway: boolean) => {
        return isGateway && placementType === PlacementType.gatewaySetupByProcessor;
      },
      then: schema =>
        schema
          .min(0, GREATER_THAN_ZERO)
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Setup Fee is required'))
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Setup Fee is required')),
      otherwise: schema => schema.transform(emptyStringToNull).nullable(true),
    }),
    virtualMonthlyFee: yup.number().when(['placementType', 'isGateway'], {
      is: (placementType: PlacementType, isGateway: boolean) => {
        return isGateway && placementType === PlacementType.gatewaySetupByProcessor;
      },
      then: schema =>
        schema
          .min(0, GREATER_THAN_ZERO)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Monthly Fee is required'))
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Monthly Fee is required')),
      otherwise: schema => schema.transform(emptyStringToNull).nullable(true),
    }),
    virtualTransactionFee: yup.number().when(['placementType', 'isGateway'], {
      is: (placementType: PlacementType, isGateway: boolean) => {
        return isGateway && placementType === PlacementType.gatewaySetupByProcessor;
      },
      then: schema =>
        schema
          .min(0, GREATER_THAN_ZERO)
          .required(getCustomErrorMessage(useCustomErrorMessage, 'Trans Fee is required'))
          .typeError(getNumberTypeError(useCustomErrorMessage, 'Trans Fee is required')),
      otherwise: schema => schema.transform(emptyStringToNull).nullable(true),
    }),
  });
