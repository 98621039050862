import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Tooltip} from '@mui/material';
import {generatePath, useHistory} from 'react-router-dom';
import {useApplicationFromRouteId} from '../../../Application/hooks';
import {useApplicationQuery} from '../../../Application/hooks/useApplicationQuery';

interface CardHeaderActionProps {
  anchorId: string;
  prevRoute: string;
}

export const CardHeaderAction = ({prevRoute, anchorId}: CardHeaderActionProps) => {
  const {applicationId} = useApplicationFromRouteId();
  const {isSummaryUrlParamName, anchorUrlParamName} = useApplicationQuery();
  const history = useHistory();
  const goBack = () => {
    const params = new URLSearchParams();
    params.append(isSummaryUrlParamName, 'true');
    params.append(anchorUrlParamName, anchorId);
    const route = `${prevRoute}?${params.toString()}`;
    history.push(generatePath(route, {applicationId}));
  };
  return (
    <IconButton onClick={goBack}>
      <Tooltip title="Edit">
        <EditIcon />
      </Tooltip>
    </IconButton>
  );
};
