import {FormControlLabel, Grid, MenuItem, Radio, Typography} from '@mui/material';
import {
  AgentOffice,
  DeprecatedShippingSpeeds,
  OnlineAppShippingSpeeds,
  ShippingSpeed,
  ShippingType,
  StateArray,
  StateHash,
} from '@ozark/common';
import {
  AutoCompleteAddressTextField,
  RadioGroup,
  SectionTitle,
  Select,
  TextField,
} from '@ozark/common/components';

interface Props {
  control: any;
  errors: any;
  setValue: any;
  watch: any;
  isVisible: any;
  isMerchantOnlineApp?: boolean;
  homeAddress?: string;
  businessAddress?: string;
  agentAddress?: AgentOffice;
}

export const ProgramShipping = ({
  isVisible,
  control,
  errors,
  setValue,
  watch,
  isMerchantOnlineApp = false,
  homeAddress,
  businessAddress,
  agentAddress,
}: Props) => {
  const watchShippingSelect = watch('shipping.select');
  const watchShippingSpeed = watch('equipment.shippingSpeed');
  const watchEquipmentIsGateway = watch('equipment.isGateway');
  const watchEquipmentIsVarSheet = watch('equipment.isVarSheet');

  const isLocalPickupOnly =
    watchShippingSpeed === ShippingSpeed.na &&
    !watchEquipmentIsGateway &&
    !watchEquipmentIsVarSheet;

  const handleShippingAddressAutoFill = (city?: any, state?: any, zip?: any) => {
    const empty = '';
    setValue('shipping.address2', empty, {shouldDirty: true});
    setValue('shipping.city', city ?? empty, {shouldDirty: true});
    setValue('shipping.state', state ?? empty, {shouldDirty: true});
    setValue('shipping.zip', zip ?? empty, {shouldDirty: true});
  };

  const availableShippingSpeeds = Object.values(ShippingSpeed).filter(s =>
    isMerchantOnlineApp
      ? OnlineAppShippingSpeeds.includes(s)
      : !DeprecatedShippingSpeeds.includes(s) && !OnlineAppShippingSpeeds.includes(s)
  );

  return (
    <Grid container spacing={1}>
      {isVisible && (
        <>
          <Grid item xs={12}>
            <SectionTitle text="Shipping" />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="equipment.shippingSpeed"
              label="Shipping Speed"
              errors={errors}
              control={control}
              options={availableShippingSpeeds}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              name="shipping.select"
              label="Where would you like to have this equipment shipped?"
              required
              errors={errors}
              control={control}
            >
              {!isLocalPickupOnly ? (
                <>
                  <FormControlLabel
                    value={ShippingType.homeAddress}
                    control={<Radio />}
                    label={
                      isMerchantOnlineApp ? (
                        <Typography>
                          <b>Home Address</b> - {homeAddress}
                        </Typography>
                      ) : (
                        <Typography>
                          I will be using the <b>Home Address</b> filled out later in the
                          application.
                        </Typography>
                      )
                    }
                  />

                  <FormControlLabel
                    value={ShippingType.businessAddress}
                    control={<Radio />}
                    label={
                      isMerchantOnlineApp ? (
                        <Typography>
                          <b>Business Address</b> - {businessAddress}
                        </Typography>
                      ) : (
                        <Typography>
                          I will be using the <b>Business Address</b> filled out later in the
                          application.
                        </Typography>
                      )
                    }
                  />

                  {agentAddress && (
                    <FormControlLabel
                      value={ShippingType.agentAddress}
                      control={<Radio />}
                      label={
                        <Typography>
                          I will be using the <b>Agent Address</b>
                        </Typography>
                      }
                    />
                  )}

                  <FormControlLabel
                    value={ShippingType.other}
                    control={<Radio />}
                    label={
                      <Typography>
                        I will be using a different address and will enter it now.
                      </Typography>
                    }
                  />
                </>
              ) : (
                <FormControlLabel
                  value={ShippingType.localPickup}
                  control={<Radio />}
                  label={<Typography>I will coordinate a local pickup.</Typography>}
                />
              )}
            </RadioGroup>
          </Grid>
          {watchShippingSelect === ShippingType.other && (
            <>
              <Grid item xs={12}>
                <TextField
                  name="shipping.attention"
                  label="Attention"
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoCompleteAddressTextField
                  setAutofillHandler={handleShippingAddressAutoFill}
                  name="shipping.address1"
                  label="Address Line 1"
                  required
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="shipping.address2"
                  label="Address Line 2"
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="shipping.city"
                  label="City"
                  required
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  name="shipping.state"
                  label="State"
                  required
                  errors={errors}
                  control={control}
                >
                  {StateArray.sortAndMap(
                    state => (
                      <MenuItem key={`${state}2`} value={state}>
                        {StateHash[state]}
                      </MenuItem>
                    ),
                    state => StateHash[state]
                  )}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="shipping.zip"
                  label="Zip Code"
                  required
                  errors={errors}
                  control={control}
                  transform={{
                    pattern: '99999',
                  }}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
