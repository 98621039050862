import {Loading} from '@ozark/common/components';
import {useEffect} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store';

export const Resume = () => {
  const history = useHistory();
  const {application} = useStore();

  useEffect(() => {
    if (application.data) {
      history.push(generatePath(ROUTES.APPLICATION_DETAILS, {applicationId: application.data.id}));
    }
  }, [application.data, history]);

  return <Loading />;
};
