import {useStore} from '../../../store';

export const useApplicationAddresses = () => {
  const {application} = useStore();
  const getHomeAddress = () => {
    return `${`${application?.data?.homeAddress1} ${
      application?.data?.homeAddress2 || ''
    }`.trim()}, ${application?.data?.homeCity}, ${application?.data?.homeState} ${
      application?.data?.homeZip
    }`;
  };

  const getBusinessAddress = () => {
    return `${`${application?.data?.businessAddress1} ${
      application?.data?.businessAddress2 || ''
    }`.trim()}, ${application?.data?.businessCity}, ${application?.data?.businessState} ${
      application?.data?.businessZip
    }`;
  };

  return {
    getHomeAddress,
    getBusinessAddress,
  };
};
