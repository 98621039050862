import {Box, Typography} from '@mui/material';
import {formatPhone, Group} from '@ozark/common';

type Props = {
  group: Group;
  customFooterText?: string;
};

export const SubmittedPageFooter = ({group, customFooterText}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {customFooterText && !group.applicationSettings?.enableRegistration ? (
        <Box
          sx={{my: 1, '& p': {margin: 0}}}
          dangerouslySetInnerHTML={{__html: customFooterText}}
        />
      ) : (
        <Box sx={{my: 3}}>
          <Typography variant="body2" align="center">
            Thank you for your partnership,
          </Typography>
          <Typography variant="body2" align="center">
            {group?.name}
          </Typography>
          <Typography variant="body2" align="center">
            {`${group?.address1}${group?.address2 ? `, ${group?.address2}` : ''}`}
          </Typography>
          <Typography variant="body2" align="center">
            {`${group?.city}, ${group?.state} ${group?.zipCode}`}
          </Typography>
          <Typography variant="body2" align="center">
            <a href={`tel:${formatPhone(group?.phone || '', {format: 'e164'})}`}>{group?.phone}</a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
