import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MobileStepper from '@mui/material/MobileStepper';
import makeStyles from '@mui/styles/makeStyles';
import {IStep} from '../../constants/steps';

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  root: {
    background: '#fff',
    width: `calc(100% - ${spacing(3)})`,
    marginLeft: spacing(-2),
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
    },
    [breakpoints.down(490)]: {
      marginBottom: spacing(2),
    },
    [breakpoints.down(485)]: {
      marginBottom: spacing(4),
    },
  },
  side: {
    [breakpoints.down('md')]: {
      flexBasis: '10%',
    },
  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  center: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    [breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

interface Props {
  isApplicationFormForAgentOrErpUser: boolean;
  isLastStep: boolean;
  isVerifyStep: boolean;
  steps: {[index: string]: IStep};
  activeStep: IStep;
  onBack(): any;
  onNext(): any;
  loading?: boolean;
}

const MobileActions = ({
  isApplicationFormForAgentOrErpUser,
  isLastStep,
  isVerifyStep,
  steps,
  activeStep,
  onBack,
  onNext,
  loading,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isLastStep && !isApplicationFormForAgentOrErpUser && (
        <Alert severity="info" sx={{marginBottom: 1}}>
          Please be prepared to answer 3 identity questions on the following page
        </Alert>
      )}

      <MobileStepper
        className={classes.stepper}
        variant="text"
        steps={Object.keys(steps).length}
        position="static"
        activeStep={activeStep.index}
        nextButton={
          <>
            <Button
              size="small"
              variant="contained"
              onClick={onNext}
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {isLastStep
                    ? 'Submit and Verify Your Identity'
                    : isVerifyStep
                    ? 'Verify Identity'
                    : 'Next'}
                  <KeyboardArrowRight />
                </>
              )}
            </Button>
          </>
        }
        backButton={
          <Button
            size="small"
            onClick={onBack}
            disabled={Boolean(activeStep.index === 0 || activeStep.index === 7)}
          >
            <KeyboardArrowLeft />
            {activeStep.index === 1 ? 'Restart' : 'Back'}
          </Button>
        }
      />
    </Box>
  );
};

export default MobileActions;
