import {
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Application,
  ApplicationEquipment,
  ApplicationType,
  AsyncState,
  DeprecatedPaymentTypes,
  EquipmentAdditionalField,
  EquipmentDurationType,
  EquipmentFileType,
  EquipmentView,
  EquipmentYesOrNo,
  nonGatewayPaymentTypes,
  PaymentType,
  PlacementsValuesGateway,
  PlacementsValuesNonGateway,
  PlacementType,
  RateProgram,
  timeList,
} from '@ozark/common';
import {Loading, ProgramShipping, SectionTitle, Select, TextField} from '@ozark/common/components';
import {useApplicationAddressAgent} from '@ozark/common/components/Application/SectionShipping/useApplicationAddressAgent';
import {MenuProps} from '@ozark/common/helpers';
import {omit, uniq} from '@s-libs/micro-dash';
import React, {ChangeEvent, Fragment, useCallback, useEffect, useState} from 'react';
import {useStore} from '../../store';
import {AdditionalEquipmentBar} from './AdditionalEquipmentBar';

interface Props {
  application: Application;
  control: any;
  errors: any;
  equipmentList: EquipmentView[];
  setValue: any;
  watch: any;
  watchApplicationType: ApplicationType;
  ratePrograms: AsyncState<RateProgram[]>;
  isAdditionalEquipment?: boolean;
  itemIndex?: number;
  equipmentData?: ApplicationEquipment;
  equipmentRemove?: () => void;
  children?: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const isYesOrNo = (value: any) => {
  return typeof value === typeof EquipmentYesOrNo;
};

const paymentTypes = Object.values(PaymentType);
const availablePaymentTypes = paymentTypes.filter(p => !DeprecatedPaymentTypes.includes(p));

const getEquipmentByName = (equipmentList: EquipmentView[], name: string) => {
  if (!name) {
    return undefined;
  }
  const _equipment = equipmentList.find(e => e.name === name);
  if (!_equipment) {
    console.error(`Equipment not found. Equipment name: ${name}`);
    return undefined;
  }
  return _equipment;
};

export const ProgramEquipment = ({
  application,
  equipmentData,
  control,
  errors,
  equipmentList,
  setValue,
  watch,
  watchApplicationType,
  ratePrograms,
  isAdditionalEquipment,
  itemIndex,
  equipmentRemove,
  children,
}: Props) => {
  const {group} = useStore();
  const classes = useStyles();
  const targetFieldName = isAdditionalEquipment
    ? (`equipmentAdditional.${itemIndex}` as const)
    : 'equipment';
  const watchEquipmentName = watch(`${targetFieldName}.name`);
  const watchIsOther = watch(`${targetFieldName}.isOther`);
  const watchEquipmentIsGateway = watch(`${targetFieldName}.isGateway`);
  const watchEquipmentIsVarSheet = watch(`${targetFieldName}.isVarSheet`);
  const watchAdditionalFields = watch(`${targetFieldName}.additionalFields`, []);
  const watchFileType = watch(`${targetFieldName}.fileType`);
  const watchCashDiscount = watch(`${targetFieldName}.cashDiscount`);
  const watchPlacementType = watch(`${targetFieldName}.placementType`);
  const watchPaymentType = watch(`${targetFieldName}.paymentType`);
  const watchAutoBatch = watch(`${targetFieldName}.autoBatch`);
  const watchDescription = watch(`${targetFieldName}.description`);
  const [chosenAccessories, setChosenAccessories] = useState<string[]>([]);
  const [chosenEquipment, setChosenEquipment] = useState<EquipmentView>();
  const [placementOptions, setPlacementOptions] = useState<
    typeof PlacementsValuesGateway | typeof PlacementsValuesNonGateway
  >();
  const [paymentOptions, setPaymentOptions] = useState<string[]>();

  const {agentAddress} = useApplicationAddressAgent(application);

  const allAccessories = uniq([
    ...(chosenEquipment?.options?.accessories ?? []),
    ...(chosenAccessories ?? []),
  ]);
  const availableAccessories = chosenEquipment?.options?.accessories ?? [];

  // TODO make watchEquipmentIsGateway and isGateway conditions more clear
  const isGateway = watchEquipmentIsGateway === true; // may be undefined so no !-operator here
  const isVarSheetOnly = watchPlacementType === PlacementType.varSheetOnly;
  const isProgramShippingVisible = !isGateway && !isVarSheetOnly;

  const getProgramNameByApplicationType = (type: ApplicationType) => {
    const programName = ratePrograms.data?.find(p => {
      const applicationType = Object.entries(p.processingTypes)[0][1].applicationType;
      return applicationType === type;
    })?.name;

    return programName ?? null;
  };

  const handleAccessoryChange = (event: SelectChangeEvent<string[]>) => {
    const accessories = event.target.value as string[];
    setChosenAccessories(accessories);
    setValue(`${targetFieldName}.accessories`, accessories, {shouldDirty: true});
  };

  const setEquipment = useCallback(
    (name: string) => {
      const _equipment = getEquipmentByName(equipmentList, name);
      if (!_equipment) {
        return;
      }
      setChosenEquipment(_equipment);
      setValue(`${targetFieldName}.id`, _equipment.id as any, {shouldDirty: true});
      setValue(`${targetFieldName}.isOther`, _equipment.isOther as any, {shouldDirty: true});
      setValue(`${targetFieldName}.name`, _equipment.name as any, {shouldDirty: true});
      setValue(`${targetFieldName}.isGateway`, (_equipment.isGateway || false) as any, {
        shouldDirty: true,
      });
      const isVarSheet = _equipment.isVarSheet || false;
      setValue(`${targetFieldName}.isVarSheet`, isVarSheet, {
        shouldDirty: true,
      });
      setValue(
        `${targetFieldName}.additionalFields`,
        (_equipment.additionalFields || []).map(f => ({
          ...f,
          name: f.name.replace('equipment.', `${targetFieldName}.`),
        })) as any,
        {shouldDirty: true}
      );
      setValue(`${targetFieldName}.price`, _equipment.price as any, {shouldDirty: true});
      setValue(`${targetFieldName}.duration`, _equipment.duration as any, {shouldDirty: true});
      setValue(`${targetFieldName}.imageUrl`, _equipment.imageUrl as any, {shouldDirty: true});
      if (_equipment.isGateway && !isAdditionalEquipment) {
        setValue('shipping.select', '' as any, {shouldDirty: true});
        setValue('shipping.other', '' as any, {shouldDirty: true});
      }

      if (isVarSheet) {
        // default placement type for var sheet is var sheet only
        setValue(`${targetFieldName}.placementType`, PlacementType.varSheetOnly, {
          shouldDirty: true,
        });
      }

      return _equipment;
    },
    [targetFieldName, isAdditionalEquipment, equipmentList, setValue]
  );

  useEffect(() => {
    if (equipmentData) {
      const _equipment = getEquipmentByName(equipmentList, equipmentData.name);
      if (!_equipment) {
        return;
      }
      setChosenEquipment(_equipment);

      //IMPORTANT: for some reason without the line bellow 'watch' of the 'useForm' doesn't return values on loading step from time to time.
      setValue(`${targetFieldName}.name`, _equipment.name as any, {shouldDirty: true});

      if (equipmentData.accessories !== undefined) {
        setChosenAccessories(equipmentData.accessories);
      }
    }
  }, [targetFieldName, equipmentData, equipmentList, setValue]);

  useEffect(() => {
    let paymentOptionsToSet;
    let placementOptionsToSet: any;
    if (watchEquipmentIsGateway || watchIsOther) {
      placementOptionsToSet = PlacementsValuesGateway;

      paymentOptionsToSet = availablePaymentTypes.filter(x => !nonGatewayPaymentTypes.includes(x));
    } else {
      placementOptionsToSet = PlacementsValuesNonGateway;

      paymentOptionsToSet = availablePaymentTypes;
    }
    if (
      group.data?.applicationSettings?.freePlacementPaymentTypeEnabled !== undefined &&
      !group.data?.applicationSettings?.freePlacementPaymentTypeEnabled
    ) {
      placementOptionsToSet = omit(
        placementOptionsToSet,
        PlacementType.freePlacementPriorApprovalRequired
      );
      paymentOptionsToSet = paymentOptionsToSet.filter(
        x => x !== PaymentType.freePlacementPriorApprovalRequired
      );
    }

    if (
      group.data?.applicationSettings?.rentalPaymentTypeEnabled !== undefined &&
      !group.data?.applicationSettings?.rentalPaymentTypeEnabled
    ) {
      placementOptionsToSet = omit(placementOptionsToSet, PlacementType.rental);
      paymentOptionsToSet = paymentOptionsToSet.filter(x => x !== PaymentType.rental);
    }

    if (
      group.data?.applicationSettings?.billToResidualsPaymentTypeEnabled !== undefined &&
      !group.data?.applicationSettings?.billToResidualsPaymentTypeEnabled
    ) {
      paymentOptionsToSet = paymentOptionsToSet.filter(x => x !== PaymentType.billResiduals);
    }
    setPlacementOptions(placementOptionsToSet);
    setPaymentOptions(paymentOptionsToSet);
  }, [watchEquipmentIsGateway, watchIsOther, group.data?.applicationSettings]);

  useEffect(() => {
    if (watchPlacementType === PlacementType.freePlacementPriorApprovalRequired) {
      setValue(`${targetFieldName}.paymentType`, PaymentType.freePlacementPriorApprovalRequired);
    }

    if (watchPlacementType === PlacementType.rental) {
      setValue(`${targetFieldName}.paymentType`, PaymentType.rental);
    }

    if (watchPlacementType === PlacementType.reprogram) {
      setValue(`${targetFieldName}.paymentType`, PaymentType.reprogramExistingDevice);
    }
  }, [targetFieldName, watchPlacementType, setValue]);

  useEffect(() => {
    if (watchPaymentType === PaymentType.freePlacementPriorApprovalRequired) {
      setValue(
        `${targetFieldName}.placementType`,
        PlacementType.freePlacementPriorApprovalRequired
      );
    }
  }, [targetFieldName, watchPaymentType, setValue]);

  useEffect(() => {
    if (!watchEquipmentIsGateway) {
      setValue('equipment.serialNumber', '');
    }
  }, [watchEquipmentIsGateway, setValue]);

  useEffect(() => {
    if (
      watchApplicationType &&
      watchApplicationType !== ApplicationType.cashDiscount &&
      watchApplicationType !== ApplicationType.surcharge &&
      watchApplicationType !== ApplicationType.dualPricingPassThroughFees &&
      watchApplicationType !== ApplicationType.dualPricingFlatRate
    ) {
      setValue(`${targetFieldName}.cashDiscount`, false, {shouldDirty: true});
      setValue(`${targetFieldName}.cashDiscountPercent`, null, {shouldDirty: true});
    }
  }, [watchApplicationType, targetFieldName, setValue]);

  const defaultPlacementTypeValue = watchEquipmentIsVarSheet ? PlacementType.varSheetOnly : '';

  const handleDescriptionEdit = useCallback(
    (description: string) => {
      setValue(`${targetFieldName}.description`, description, {shouldDirty: true});
    },
    [targetFieldName, setValue]
  );

  const handleCashDiscountChange = useCallback(
    (value: string) => {
      if (value === 'false') {
        setValue(`${targetFieldName}.cashDiscountPercent`, null, {shouldDirty: true});
      }
    },
    [setValue, targetFieldName]
  );

  if (ratePrograms.promised) {
    return <Loading />;
  }

  return (
    <>
      <Grid item xs={12}>
        {isAdditionalEquipment && (
          <AdditionalEquipmentBar
            description={watchDescription}
            equipmentRemove={equipmentRemove}
            editDescription={handleDescriptionEdit}
          />
        )}
        {!isAdditionalEquipment && <SectionTitle text="Equipment" />}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Select
          name={`${targetFieldName}.name`}
          label="Equipment/Gateway"
          required
          errors={errors}
          control={control}
          defaultValue={watchEquipmentName}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setEquipment(event.target.value)}
          options={equipmentList
            .filter(e => {
              if (
                watchApplicationType === ApplicationType.cashDiscount ||
                watchApplicationType === ApplicationType.surcharge ||
                watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
                watchApplicationType === ApplicationType.dualPricingFlatRate
              ) {
                const programName = getProgramNameByApplicationType(watchApplicationType);
                if (programName !== null) {
                  return e.programs && e.programs.includes(programName);
                }
              }
              return true;
            })
            .map(e => e.name)}
        />
      </Grid>

      {watchEquipmentIsVarSheet === true && watchAdditionalFields?.length && (
        <>
          {watchAdditionalFields.map((field: EquipmentAdditionalField) => {
            return (
              <Grid key={field.name} item xs={12}>
                {field.type === 'text' && (
                  <TextField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    errors={errors}
                    control={control}
                  />
                )}
              </Grid>
            );
          })}
        </>
      )}

      {watchEquipmentIsGateway !== undefined && (
        <>
          <Grid item xs={12}>
            <Select
              name={`${targetFieldName}.placementType`}
              label="Placement Type"
              errors={errors}
              control={control}
              displayValue={true}
              options={placementOptions}
              defaultValue={defaultPlacementTypeValue}
              required
            />
          </Grid>

          {!watchEquipmentIsGateway && (
            <Grid item xs={12}>
              <TextField
                name={`${targetFieldName}.serialNumber`}
                label="Equipment Serial Number"
                errors={errors}
                control={control}
              />
            </Grid>
          )}
          {watchPlacementType === PlacementType.gatewaySetupByProcessor &&
            watchEquipmentIsGateway && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name={`${targetFieldName}.virtualSetupFee`}
                    label="Setup Fee"
                    errors={errors}
                    control={control}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name={`${targetFieldName}.virtualMonthlyFee`}
                    label="Monthly Fee"
                    errors={errors}
                    control={control}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name={`${targetFieldName}.virtualTransactionFee`}
                    label="Trans Fee"
                    errors={errors}
                    control={control}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </>
            )}
        </>
      )}

      {watchEquipmentIsGateway === false && (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <TextField
              name={`${targetFieldName}.price`}
              defaultValue={chosenEquipment?.price}
              label="Price"
              required={!Boolean(watchIsOther)}
              type="number"
              errors={errors}
              control={control}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled={!chosenEquipment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name={`${targetFieldName}.duration`}
              defaultValue={chosenEquipment?.duration}
              label="Billing Method"
              required={!Boolean(watchIsOther)}
              errors={errors}
              control={control}
              disabled={!chosenEquipment}
            >
              {Object.entries(EquipmentDurationType).map(([key, value]) => (
                <MenuItem key={`${key}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select
              name={`${targetFieldName}.paymentType`}
              label="Payment Type"
              required
              errors={errors}
              control={control}
              displayValue={true}
              options={paymentOptions}
              disabled={!chosenEquipment}
              compareFn={(a, b) => paymentTypes.indexOf(a) - paymentTypes.indexOf(b)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name={`${targetFieldName}.connectionType`}
              label="Connection Type"
              errors={errors}
              control={control}
              disabled={!chosenEquipment}
              options={chosenEquipment ? chosenEquipment.options?.connectionType : []}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name={`${targetFieldName}.contactless`}
              label="Contactless"
              errors={errors}
              control={control}
              disabled={!chosenEquipment}
              yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.contactless)}
              options={
                chosenEquipment && !isYesOrNo(chosenEquipment.options?.contactless)
                  ? chosenEquipment.options?.contactless
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              required
              name={`${targetFieldName}.fileType`}
              label="File Type"
              errors={errors}
              control={control}
              disabled={!chosenEquipment}
              options={chosenEquipment ? chosenEquipment.options?.fileType : []}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {watchFileType !== EquipmentFileType.retail && (
              <Select
                name={`${targetFieldName}.tipMode`}
                label="Tip Mode"
                errors={errors}
                control={control}
                disabled={!chosenEquipment}
                options={chosenEquipment ? chosenEquipment.options?.tipMode : []}
              />
            )}
          </Grid>
          {(watchApplicationType === ApplicationType.cashDiscount ||
            watchApplicationType === ApplicationType.surcharge ||
            watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
            watchApplicationType === ApplicationType.dualPricingFlatRate) && (
            <>
              <Grid item xs={12} sm={6}>
                <Select
                  name={`${targetFieldName}.cashDiscount`}
                  label={
                    watchApplicationType === ApplicationType.cashDiscount
                      ? 'Cash Discount'
                      : 'Card Holder as Surcharge'
                  }
                  errors={errors}
                  control={control}
                  disabled={
                    !chosenEquipment &&
                    watchApplicationType !== ApplicationType.cashDiscount &&
                    watchApplicationType !== ApplicationType.surcharge &&
                    watchApplicationType !== ApplicationType.dualPricingPassThroughFees &&
                    watchApplicationType !== ApplicationType.dualPricingFlatRate
                  }
                  yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.cashDiscount)}
                  options={
                    chosenEquipment && !isYesOrNo(chosenEquipment.options?.cashDiscount)
                      ? chosenEquipment.options?.cashDiscount
                      : []
                  }
                  onChangeSuccess={handleCashDiscountChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {String(watchCashDiscount) === 'true' && (
                  <TextField
                    name={`${targetFieldName}.cashDiscountPercent`}
                    label={
                      watchApplicationType === ApplicationType.cashDiscount
                        ? 'Cash Discount Percent Billed to Card Holder'
                        : 'Surcharge Percent Billed to Card Holder'
                    }
                    type="number"
                    errors={errors}
                    control={control}
                    disabled={!chosenEquipment}
                    required={
                      (watchApplicationType === ApplicationType.cashDiscount ||
                        watchApplicationType === ApplicationType.surcharge ||
                        watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
                        watchApplicationType === ApplicationType.dualPricingFlatRate ||
                        watchCashDiscount === 'true') &&
                      !Boolean(watchIsOther)
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <Select
              name={`${targetFieldName}.autoBatch`}
              label="Auto Batch"
              errors={errors}
              control={control}
              disabled={!chosenEquipment}
              yesOrNo={chosenEquipment && isYesOrNo(chosenEquipment.options?.autoBatch)}
              options={
                chosenEquipment && !isYesOrNo(chosenEquipment.options?.autoBatch)
                  ? chosenEquipment.options?.autoBatch
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {watchAutoBatch === 'true' && (
              <Select
                name={`${targetFieldName}.autoBatchTime`}
                label="Batch Time"
                errors={errors}
                control={control}
                disabled={!chosenEquipment}
                options={chosenEquipment ? timeList() : []}
                compareFn={() => 0}
              />
            )}
          </Grid>
          {chosenEquipment && allAccessories.length > 0 && (
            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                fullWidth
                disabled={!chosenEquipment}
                variant="outlined"
              >
                <InputLabel id="accessories-label" shrink>
                  Accessories
                </InputLabel>
                <MuiSelect
                  variant="outlined"
                  labelId="accessories-label"
                  id="accessories"
                  multiple
                  renderValue={selected => (selected as string[]).join(', ')}
                  value={chosenAccessories}
                  onChange={handleAccessoryChange}
                  input={<OutlinedInput notched label="Accessories" />}
                  MenuProps={MenuProps}
                  fullWidth
                  disabled={!chosenEquipment || allAccessories.length === 0}
                >
                  {allAccessories.sortAndMap(accessoryName => (
                    <MenuItem key={accessoryName} value={accessoryName}>
                      <Checkbox
                        checked={chosenAccessories.includes(accessoryName)}
                        color="primary"
                      />
                      <ListItemText
                        primary={
                          availableAccessories.includes(accessoryName)
                            ? accessoryName
                            : `NOT AVAILABLE! ${accessoryName}`
                        }
                      />
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Grid>
          )}
        </Fragment>
      )}
      {children}
      {!isAdditionalEquipment && (
        <>
          <Grid item xs={12}>
            <SectionTitle text="Additional Notes" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="agentNotes"
              label="Notes for Underwriting"
              errors={errors}
              control={control}
              multiline
              minRows={2}
              maxRows={4}
              fullWidth
            />
          </Grid>

          <ProgramShipping
            isVisible={isProgramShippingVisible}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            agentAddress={agentAddress}
          />
        </>
      )}
    </>
  );
};
