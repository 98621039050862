import {useEffect, useState} from 'react';

export const useSecretCode = (secretCode: string[]) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let count = 0;

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code !== secretCode[count]) {
        count = 0;
        return;
      }

      count++;
      if (count === secretCode.length) {
        setSuccess(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => document.removeEventListener('keydown', keyDownHandler);
  }, []);

  return success;
};
