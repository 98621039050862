import {AppBar, Toolbar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Fragment, ReactNode} from 'react';
import {useStore} from '../../store';

const useStyles = makeStyles(theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, '10%'),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0.5, '5%'),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 2),
    },
  },
  logo: {
    height: 48,
  },
  actions: {
    '& > *': {
      marginLeft: theme.spacing(4),
    },
  },
}));

const Header = ({children}: {children: ReactNode}) => {
  const classes = useStyles();
  const {group} = useStore();
  return (
    <Fragment>
      <AppBar elevation={0}>
        <Toolbar className={classes.toolBar}>
          <img className={classes.logo} src={group.data?.logoUrl} alt="Luqra" />
          <div className={classes.actions}>{children}</div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
};

export default Header;
