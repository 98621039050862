import {PlaidAccount} from '@ozark/common';
import {PageAnswers, QuestionAnswer} from '../types';
import {useSummaryCommon} from './useSummaryCommon';

export const useDepositInfoStepAnswers = () => {
  const {emptyAnswers, getQuestionAnswer, app} = useSummaryCommon();

  const getDepositInfoStepAnswersCommon = (isAvailable: boolean): QuestionAnswer[] => {
    return [
      getQuestionAnswer('Bank Name', app.bankName, isAvailable),
      getQuestionAnswer('Routing Number', app.routingNumber, isAvailable),
      getQuestionAnswer('Account Number', app.bankAccountNumber, isAvailable),
    ];
  };

  const getDepositInfoStepAnswers = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    const plaidAccount = app?.plaidAccount as PlaidAccount;
    const paidBankAccount = plaidAccount
      ? `${plaidAccount.name} (****${plaidAccount.accountNumber.substr(
          plaidAccount.accountNumber.length - 4,
          plaidAccount.accountNumber.length
        )} ${plaidAccount.bankName})`
      : '';
    return {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Bank Deposit Info',
          questionAnswers: [
            getQuestionAnswer('Selected Bank Account', paidBankAccount, !!plaidAccount),
            ...getDepositInfoStepAnswersCommon(!plaidAccount),
          ],
        },
      ],
    };
  };

  const getDepositInfoStepAnswersForAgentOrErpUser = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    return {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Bank Deposit Info',
          questionAnswers: [...getDepositInfoStepAnswersCommon(true)],
        },
      ],
    };
  };

  return {
    getDepositInfoStepAnswers,
    getDepositInfoStepAnswersForAgentOrErpUser,
  };
};
