import {Application, Collections, Firebase, PersonaVerification} from '@ozark/common';

export const usePersonaId = () => {
  const savePersonaIdAsync = async (applicationId: string, personaId: PersonaVerification) => {
    try {
      const appsSnap = await Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .get();

      if (appsSnap.exists) {
        const data = appsSnap.data() as Application;

        appsSnap.ref.update({
          personaId: {
            ...data.personaId,
            ...personaId,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {savePersonaIdAsync};
};
