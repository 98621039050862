import {useEffect, useState} from 'react';
import {useStore} from '../../../store';

export const useEnableRegistration = () => {
  const {group} = useStore();
  const [enableRegistration, setEnableRegistration] = useState(false);

  useEffect(() => {
    if (group.promised || !group.data) {
      return;
    }
    setEnableRegistration(group.data.applicationSettings?.enableRegistration || false);
  }, [group.promised, group.data]);

  return {
    enableRegistration,
  };
};
