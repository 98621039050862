import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import {INSTANT_ID_Q_AND_A} from '../../constants/routes';
import {ApplicationSteps, IStep} from '../../constants/steps';

interface Props {
  applicationId: string;
  steps: {[index: string]: IStep};
  activeStep: IStep;
}

const STEP_LOCKED = ApplicationSteps.instantIdQanda;
const STEP_LOCKED_ROUTE = INSTANT_ID_Q_AND_A;

const Stepper = ({steps, activeStep, applicationId}: Props) => {
  const stepIconClasses = useStepIconStyles();
  const history = useHistory();
  const stepArray = Object.values(steps);

  const onStepClick = (stepIndexClicked: number) => {
    if (activeStep.key === STEP_LOCKED) return;
    if (stepIndexClicked >= activeStep.index) return;
    const stepTarget = stepArray[stepIndexClicked - 1].next;
    if (!stepTarget) return;
    history.push(generatePath(stepTarget, {applicationId}));
  };

  const getStep = (stepIndex: number) => (
    <Step onClick={() => onStepClick(stepIndex)}>
      <StepLabel StepIconComponent={CustomStepIcon(steps, stepIconClasses)}>
        {stepArray[stepIndex].stepLabel}
      </StepLabel>
    </Step>
  );

  useEffect(() => {
    if (activeStep.key !== STEP_LOCKED) return;
    const handler = () => {
      window.removeEventListener('popstate', handler);
      history.push(generatePath(STEP_LOCKED_ROUTE, {applicationId}));
      setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 0);
    };
    window.addEventListener('popstate', handler);
  }, [activeStep.key, applicationId, history]);

  return (
    <Box width="100%">
      <MuiStepper alternativeLabel activeStep={activeStep.index} connector={<CustomConnector />}>
        <Step>
          <StepLabel StepIconComponent={CustomStepIcon(steps, stepIconClasses)}>
            {stepArray[0].stepLabel}
          </StepLabel>
        </Step>
        {getStep(1)}
        {getStep(2)}
        {getStep(3)}
        {getStep(4)}
        {getStep(5)}
        <Step>
          <StepLabel StepIconComponent={CustomStepIcon(steps, stepIconClasses)}>
            {' '}
            {stepArray[6].stepLabel}
          </StepLabel>
        </Step>
      </MuiStepper>
    </Box>
  );
};

export default Stepper;

const CustomConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
    backgroundColor: '#f5fafc',
  },
  active: {
    '& $line': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#f5fafc',
    borderRadius: 1,
  },
}))(StepConnector);

const useStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5fafc',
    zIndex: 1,
    color: '#b2c2cd',
    height: 50,
    width: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: '#f5fafc',
  },
  completed: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: '#f5fafc',
  },
}));

const CustomStepIcon =
  (steps: {[index: string]: IStep}, classes: any) => (props: StepIconProps) => {
    const {active, completed} = props;

    const icons: {[index: string]: React.ReactElement} = {};

    Object.keys(steps).forEach((e, index) => {
      icons[String(index + 1)] = steps[e].stepIcon;
    });

    return (
      <div
        className={clsx(classes.root, {[classes.active]: active, [classes.completed]: completed})}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };
