import {yupResolver} from '@hookform/resolvers/yup';
import {Checkbox as MuiCheckbox, FormControlLabel, FormGroup, Grid, MenuItem} from '@mui/material';
import {
  getBusinessPageForAgentsValidationObjectCommon,
  LocationTypes,
  OwnershipTypes,
  ProcessingTypes,
  StateArray,
  StateHash,
} from '@ozark/common';
import {AutoCompleteAddressTextField, SectionTitle, TextField} from '@ozark/common/components';
import {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import Select from '../Select';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  setEndSessionHandler?(handleEndSession: any): any;
  onDirty?: (isDirty: boolean) => void;
  readonlyAccess?: boolean;
};

const schema = yup.object().shape(getBusinessPageForAgentsValidationObjectCommon(true));

const transform = (onSuccess: any) => (data: any) => {
  onSuccess(data);
};

const BusinessPageForAgents = ({
  setValidationHandler,
  setEndSessionHandler,
  onDirty,
  readonlyAccess,
}: Props) => {
  const {application, group} = useStore();
  const {formState, register, control, watch, setValue, getValues, handleSubmit} = useForm({
    defaultValues: {
      ...application.data,
      mailingAddressDifferent: application.data?.mailingAddressDifferent ? true : false,
    },
    shouldUnregister: true,
    resolver: yupResolver(schema),
    context: {
      dobSsnTaxIdAndBankInfoNotRequired:
        group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired,
    },
  });

  const {errors, isDirty} = formState;

  useEffect(() => {
    register('mailingAddressDifferent');
    register('processingType');
  }, [register]);

  //@ts-ignore TODO
  const watchMailingAddressDifferent = watch('mailingAddressDifferent');

  const handleMailingAddressDifferentChange = (event: any) => {
    setValue('mailingAddressDifferent', event.target.checked, {shouldDirty: true});
  };

  const handleAddressAutoFill =
    (addressType: 'mailing' | 'business') => (city: any, state: any, zip: any) => {
      const empty: any = '';
      setValue(`${addressType}Address2`, empty, {shouldDirty: true});
      setValue(`${addressType}City`, city ? city : empty, {shouldDirty: true});
      setValue(`${addressType}State`, state ? state : empty, {shouldDirty: true});
      setValue(`${addressType}Zip`, zip ? zip : empty, {shouldDirty: true});
    };

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          transform(onSuccess)(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  useEffect(() => {
    if (!setEndSessionHandler) return;
    setEndSessionHandler(() => () => {
      const form = getValues();
      return form;
    });
    // eslint-disable-next-line
  }, [setEndSessionHandler]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          h1="Business Profile"
          h2="Start by giving us some basic information about the business."
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="ownershipType"
          label="What's the business structure?"
          required
          errors={errors}
          control={control}
          options={Object.values(OwnershipTypes)}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="legalBusinessName"
          label="Legal Business Name"
          required
          disabled={readonlyAccess}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="federalTaxId"
          label="Employer Identification Number (EIN)"
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          disabled={readonlyAccess}
          errors={errors}
          control={control}
          transform={{
            pattern: '99-9999999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="doingBusinessAs"
          label="Doing Business As (DBA)"
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          inputProps={{maxLength: 25}}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name="filingState"
          label="What state did the business start in?"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={state} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="businessStartYear"
          label="What year did the business start?"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '9999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name="locationType"
          label="What best described the location type?"
          required
          errors={errors}
          disabled={readonlyAccess}
          control={control}
          options={Object.values(LocationTypes)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="customerServiceEmail"
          label="Customer Service Email"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessPhone"
          label="Business Phone Number"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      {application.data?.processingType !== ProcessingTypes.cardPresent && (
        <Grid item xs={12}>
          <TextField
            name="website"
            label="Website"
            required={application.data?.processingType === ProcessingTypes.eCommerce}
            errors={errors}
            control={control}
            disabled={readonlyAccess}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Physical Business Address" />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill('business')}
          name="businessAddress1"
          label="Address Line 1"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessAddress2"
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="businessCity"
          label="City"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          name="businessState"
          label="State"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={state} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="businessZip"
          label="Zip Code"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={<MuiCheckbox />}
            name="mailingAddressDifferent"
            label="Mailing Address is different than Physical Address"
            checked={watchMailingAddressDifferent}
            onChange={handleMailingAddressDifferentChange}
            disabled={readonlyAccess}
          />
        </FormGroup>
      </Grid>
      {watchMailingAddressDifferent && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Mailing Address" />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteAddressTextField
              setAutofillHandler={handleAddressAutoFill('mailing')}
              name="mailingAddress1"
              label="Address Line 1"
              required
              errors={errors}
              control={control}
              disabled={readonlyAccess}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="mailingAddress2"
              label="Address Line 2"
              errors={errors}
              control={control}
              disabled={readonlyAccess}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="mailingCity"
              label="City"
              required
              errors={errors}
              control={control}
              disabled={readonlyAccess}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              name="mailingState"
              label="State"
              required
              errors={errors}
              control={control}
              disabled={readonlyAccess}
            >
              {StateArray.sortAndMap(
                state => (
                  <MenuItem key={state} value={state}>
                    {StateHash[state]}
                  </MenuItem>
                ),
                state => StateHash[state]
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="mailingZip"
              label="Zip Code"
              required
              errors={errors}
              control={control}
              disabled={readonlyAccess}
              transform={{
                pattern: '99999',
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default BusinessPageForAgents;
