import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PlaidAccount, useNotification} from '@ozark/common';
import {PlaidLinkButton, TextField} from '@ozark/common/components';
import AutoCompleteBankInput from '@ozark/common/components/AutoCompleteBankInput';
import {useAutoComplete} from '@ozark/common/context/AutoComplete';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {PlaidLinkOnSuccess} from 'react-plaid-link';
import * as yup from 'yup';
import {Props} from '.';
import {PLAID_CALLBACK} from '../../constants/routes';
import {useStore} from '../../store';
import {PLAID_STORED_APPLICATION_ID_STORAGE_KEY} from '../PlaidCallback';
import Title from '../Title';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    height: 140,
  },
  manual: {
    height: 130,
  },
});

type FormInput = {
  routingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  bankName: string;
};

const schema = yup.object().shape({
  routingNumber: yup
    .string()
    .length(9, 'Routing Number must be 9 digits')
    .required('Routing Number is required'),
  bankAccountNumber: yup.string().required('Bank Account Number is required'),
  confirmBankAccountNumber: yup
    .string()
    .oneOf([yup.ref('bankAccountNumber')], 'Bank Account Numbers do not match')
    .required('Confirmation is required'),
  bankName: yup.string().required('Bank Name is required'),
});

const transform = (onSuccess: any) => (data: any) => {
  onSuccess(data);
};

const DepositsPageWithPlaid = ({setValidationHandler, onDirty}: Props) => {
  const classes = useStyles();
  const showNotification = useNotification();
  const {application, group, apiClient} = useStore();
  const autoComplete = useAutoComplete(apiClient.externalServices, group.data);

  const [isManualInput, setManualInput] = useState<boolean>();

  useEffect(() => {
    if (!application.data) return;

    // If application contains bank account and routing number it was manually entered.
    // If a user navigates back with save info, we want to show manual form right away.
    if (
      !application.data?.plaidAccount &&
      application.data?.bankAccountNumber &&
      application.data?.routingNumber
    ) {
      setManualInput(true);
    } else {
      setManualInput(false);
    }
  }, [application]);

  const {formState, reset, control, setValue, watch, handleSubmit} = useForm<FormInput>({
    defaultValues: {
      bankAccountNumber: application.data?.bankAccountNumber,
      bankName: application.data?.bankName,
      routingNumber: application.data?.routingNumber,
    },
    resolver: yupResolver(schema),
  });

  const {errors} = formState;

  const {isDirty} = formState;

  const [blurAccountNumber, setBlurAccountNumber] = useState(true);
  const [blurConfirmAccountNumber, setBlurConfirmAccountNumber] = useState(true);

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    if (isManualInput) {
      const _handleSubmit = handleSubmit;
      setValidationHandler(
        () => (onSuccess: any, onError: any) =>
          _handleSubmit((data: any) => {
            transform(onSuccess)(data);
            reset(data);
          }, onError)
      );
    } else {
      setValidationHandler(() => (onSuccess: any, onError: any) => async () => {
        if (!application.data?.plaidAccount) {
          onError('At least one account must be linked before continuing.');
          showNotification(
            'error',
            'Please either link your bank account using Plaid or enter it manually.'
          );
        } else {
          await onSuccess();
        }
      });
    }

    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit, isManualInput, application.data?.plaidAccount]);

  useEffect(() => {
    if (application.data?.id) {
      sessionStorage.setItem(PLAID_STORED_APPLICATION_ID_STORAGE_KEY, application.data.id);
    }
  }, [application.data?.id]);

  const watchFields = watch(['bankAccountNumber', 'confirmBankAccountNumber', 'routingNumber']);

  const mask = !(watchFields[0]?.length > 1 && watchFields[0] === watchFields[2]);

  const [linkToken, setLinkToken] = useState<string | null>(() =>
    window.location.search.indexOf('oauth') > -1 ? sessionStorage.getItem('link_token') : null
  );

  const [addingBankInfo, setAddingBankInfo] = useState(false);

  useEffect(() => {
    if (group.promised || !group.data || linkToken) return;

    const init = async () => {
      try {
        const response = await apiClient.externalServices.createPlaidLinkToken({
          groupName: group.data!.name,
          redirectUrl: `${window.location.origin}${PLAID_CALLBACK}`,
        });
        setLinkToken(response.link_token);
      } catch (err) {
        console.error(err);
      }
    };
    init();
  }, [group, linkToken, apiClient.externalServices]);

  const handleManually = () => {
    setManualInput(true);
  };

  const handleLinkBank = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      setAddingBankInfo(true);
      // Reset manual input in case if we are override bank information
      setManualInput(false);
      apiClient.externalServices
        .selectPlaidAccount({
          accountId: metadata.accounts[0].id,
          bankName: metadata.institution?.name || 'Unknown Bank',
          publicToken: public_token,
          applicationId: application.data.id,
        })
        .then(() => {
          setAddingBankInfo(false);
        })
        .catch(err => {
          console.error(err);
          setAddingBankInfo(false);
          setManualInput(true);
        })
        .finally(() => {
          // Reset link token
          sessionStorage.removeItem('link_token');
          setLinkToken(null);
        });
    },
    [apiClient.externalServices, application]
  );

  const handleRemovePlaidAccount = async () => {
    setAddingBankInfo(true);
    try {
      await apiClient.externalServices.removePlaidAccount({applicationId: application.data.id});
    } catch (err: any) {
      console.error(err);
    }
    setAddingBankInfo(false);
  };

  const refreshToken = useCallback(async () => {
    if (!group.data) return;

    const response = await apiClient.externalServices.createPlaidLinkToken({
      groupName: group.data.name,
      redirectUrl: `${window.location.origin}${PLAID_CALLBACK}`,
    });
    setLinkToken(response.link_token);
  }, [apiClient.externalServices, group.data]);

  const plaidAccount = application.data?.plaidAccount as PlaidAccount;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title
          h1="Bank Deposit Info"
          h2={
            plaidAccount || addingBankInfo
              ? ''
              : isManualInput
              ? 'Tell us where to deposit your funds.'
              : 'Instantly authenticate your bank account with Plaid'
          }
        />
      </Grid>
      <Grid item xs={12} container alignItems="center" direction="column">
        {plaidAccount ? (
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Selected Bank Account:
                </Typography>
                <Typography variant="h5" component="h2">
                  {plaidAccount.name} (****
                  {plaidAccount.accountNumber.substr(
                    plaidAccount.accountNumber.length - 4,
                    plaidAccount.accountNumber.length
                  )}
                  )
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {plaidAccount.bankName}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={handleRemovePlaidAccount}>
                  Remove
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ) : (
          <Grid
            item
            container
            alignContent="center"
            justifyContent="center"
            className={classes.link}
          >
            <Grid item>
              {addingBankInfo || !linkToken || isManualInput === undefined ? (
                <CircularProgress />
              ) : (
                <PlaidLinkButton
                  isOauth={
                    window.location.search.indexOf('oauth') > -1 &&
                    Boolean(sessionStorage.getItem('link_token'))
                  }
                  token={linkToken}
                  onSuccess={handleLinkBank}
                  size={'large'}
                  secondary={isManualInput}
                  refreshToken={refreshToken}
                >
                  {isManualInput ? 'Instantly add with Plaid' : 'Add a Bank Account'}
                </PlaidLinkButton>
              )}
            </Grid>
          </Grid>
        )}

        {!plaidAccount && !isManualInput && !addingBankInfo && (
          <Grid item container alignItems="center" direction="column">
            <Grid
              item
              container
              alignContent="center"
              justifyContent="center"
              className={classes.manual}
            >
              <Button variant="contained" size="medium" onClick={handleManually}>
                Enter manually
              </Button>
            </Grid>
          </Grid>
        )}

        {isManualInput && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteBankInput
                name="bankName"
                label="Bank Name"
                required
                freeSolo
                errors={errors}
                control={control}
                onInputChange={(_event: any, newInputValue: string) => {
                  setValue('bankName', newInputValue as any, {shouldDirty: true});
                  autoComplete.handleInstitutionQuery(newInputValue);
                }}
                autoFocus
                onChange={(_event: any, inputValue: any) => {
                  if (inputValue) autoComplete.handleInstitutionSelect(inputValue);
                }}
                options={autoComplete.institutionOptions}
                loading={autoComplete.loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteBankInput
                name="routingNumber"
                label="Routing Number"
                freeSolo
                onInputChange={(_event: any, newInputValue: string) => {
                  setValue('routingNumber', newInputValue as any, {shouldDirty: true});
                }}
                options={autoComplete.routingNumberOptions?.filter(e =>
                  watchFields && watchFields[2] && watchFields[2].length > 0
                    ? e.value.indexOf(watchFields[2]) > -1
                    : true
                )}
                required
                helperText="Suggested routing numbers may not match your records."
                errors={errors}
                control={control}
                transform={{
                  pattern: '999999999',
                }}
                loading={autoComplete.loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bankAccountNumber"
                label="Account Number"
                onFocus={() => setBlurAccountNumber(false)}
                onBlur={() => setBlurAccountNumber(true)}
                type={mask && blurAccountNumber ? 'password' : 'default'}
                errors={errors}
                control={control}
                transform={{
                  pattern: '999999999999999999999999999',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="confirmBankAccountNumber"
                label="Confirm Account Number"
                onFocus={() => setBlurConfirmAccountNumber(false)}
                onBlur={() => setBlurConfirmAccountNumber(true)}
                type={mask && blurConfirmAccountNumber ? 'password' : 'default'}
                errors={errors}
                control={control}
                transform={{
                  pattern: '999999999999999999999999999',
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DepositsPageWithPlaid;
