import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import {ApplicationTemplateView} from '@ozark/common';
import {RadioGroup, TextField} from '@ozark/common/components';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';

enum YesNo {
  Yes = 'Yes',
  No = 'No',
}
type FormValues = {
  useExitingTemplate: YesNo;
  templateName?: string;
};

export const SaveForm = yup.object({
  templateName: yup.string().when('useExitingTemplate', {
    is: YesNo.No,
    then: yup.string().required('Template Name is required'),
  }),
});

interface Props {
  existingTemplate?: ApplicationTemplateView;
  onClose: () => void;
  onSubmit: (existingTemplate?: ApplicationTemplateView, newTemplateName?: string) => Promise<void>;
}

export const SaveTemplateDialog = ({existingTemplate, onSubmit, onClose}: Props) => {
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<FormValues>({
    resolver: yupResolver(SaveForm),
    defaultValues: {useExitingTemplate: !!existingTemplate ? YesNo.Yes : YesNo.No},
  });

  const watchUseExitingTemplate = watch('useExitingTemplate');

  const onSuccess: SubmitHandler<FormValues> = async data => {
    setIsSaving(true);
    try {
      if (data.useExitingTemplate === YesNo.Yes) {
        await onSubmit(existingTemplate);
        return;
      }
      await onSubmit(undefined, data.templateName);
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmitClick = async () => {
    await handleSubmit(onSuccess)();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="save-dialog-title" maxWidth={'lg'}>
      <DialogTitle id="save-dialog-title">Save Pricing Template</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 600,
            },
          }}
        >
          <Grid container spacing={1}>
            {existingTemplate && (
              <Grid item xs={12}>
                <RadioGroup name="useExitingTemplate" label="" errors={errors} control={control}>
                  {[YesNo.Yes, YesNo.No].map(useExiting => (
                    <FormControlLabel
                      key={useExiting}
                      value={useExiting}
                      control={<Radio />}
                      label={
                        <Typography>
                          {useExiting === YesNo.Yes
                            ? 'Overwrite selected pricing template'
                            : 'Create pricing template'}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="templateName"
                label="Template Name"
                variant="outlined"
                margin="normal"
                control={control}
                errors={errors}
                fullWidth
                error={Boolean(errors.templateName)}
                helperText={errors.templateName?.message}
                type="text"
                disabled={watchUseExitingTemplate === YesNo.Yes}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={onSubmitClick} color="primary" loading={isSaving}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
