import {useCallable, useNotification} from '@ozark/common';
import {nanoid} from 'nanoid';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useStore} from '../../../store';
import {useApplicationQuery} from './useApplicationQuery';

export const useAutoCreatingApplication = (
  applicationId: string,
  registration: {
    email: string;
    firstName: string;
    lastName: string;
    businessPhone: string;
  } | null,
  email: string,
  setEmail: (email: string) => void
) => {
  const {
    authUser,
    startApplication,
    group,
    onSignIn,
    signOut,
    signInAnonymousUser,
    createUserWithEmailAndPassword,
  } = useStore();
  const history = useHistory<{distinguishableId: string; queryParams: {[key: string]: string}}>();
  const showNotification = useNotification();
  const {welcomeEmailWithResetPassword} = useCallable();
  const [isApplicationStarting, setIsApplicationStarting] = useState(false);
  const [referringAgentUid, setReferringAgentUid] = useState<string | undefined>();
  const userEmail = authUser?.data?.email;
  const isMerchantUserRegistration = Boolean(registration || email);
  const {agentUid} = useApplicationQuery();

  useEffect(() => {
    if (referringAgentUid || !agentUid) return;
    setReferringAgentUid(agentUid);
    // eslint-disable-next-line
  }, [agentUid]);

  const startApplicationWithEmailAndRedirect: typeof startApplication = useCallback(
    async appParams => {
      setIsApplicationStarting(true);
      const newApplicationId = await startApplication({
        ...appParams,
        startApplicationLink: window.location.href,
      });
      if (newApplicationId) {
        history.push(generatePath(ROUTES.APPLICATION_DETAILS, {applicationId: newApplicationId}));
      }
      setIsApplicationStarting(false);

      return newApplicationId;
    },
    [history, startApplication]
  );

  // Auto creating new application when authorized user is trying to open /start page
  useEffect(() => {
    if (applicationId || isMerchantUserRegistration) {
      return;
    }

    if (!group.data || !userEmail || isApplicationStarting) {
      return;
    }

    startApplicationWithEmailAndRedirect({email: userEmail});
  }, [
    isApplicationStarting,
    group.data,
    isMerchantUserRegistration,
    applicationId,
    userEmail,
    startApplicationWithEmailAndRedirect,
  ]);

  // creating new application when merchant is authorized by creating new account or submitting form with email field
  useEffect(() => {
    if (!registration && !email) return;
    const unsubscribe = onSignIn(async () => {
      console.log('sign in event executing');
      if (registration) {
        await startApplicationWithEmailAndRedirect({
          email: registration.email,
          registration,
          agentUid: referringAgentUid,
        });
      } else {
        await startApplicationWithEmailAndRedirect({email, agentUid: referringAgentUid});
      }
    });
    return () => {
      unsubscribe();
    };
  }, [registration, referringAgentUid, email, onSignIn, startApplicationWithEmailAndRedirect]);

  // checks /start?email=
  // if email exists, start app and move to basics page
  useEffect(() => {
    if (applicationId) return;
    if (group.promised) return;
    const stateQueryParams = history.location?.state?.queryParams;
    let startEmail: string | null = stateQueryParams?.[''];
    let externalLeadId: string;
    let agentUid: string | undefined;
    let registration: {
      businessPhone: string;
      email: string;
      firstName: string;
      lastName: string;
    } | null = null;
    if (!startEmail) {
      var query = new URLSearchParams(history.location.search);
      startEmail = query.get('inf_field_Email') || query.get('email');
      externalLeadId = query.get('contactId') || '';
      agentUid = query.get('a') || undefined;
    }

    const signUserAndStartApplication = async () => {
      if (!startEmail) return;
      setIsApplicationStarting(true);
      if (group.data?.applicationSettings?.enableRegistration) {
        try {
          registration = {
            email: startEmail,
            firstName: '', // we'll get these from the query string eventually
            lastName: '',
            businessPhone: '',
          };
          const user = await createUserWithEmailAndPassword(startEmail, nanoid());
          if (!user) {
            console.error('failed to create account.');
            setIsApplicationStarting(false);
            return;
          }
          if (group.data) {
            await welcomeEmailWithResetPassword({
              emailTo: startEmail,
              groupId: group.data.id,
            });
          }
        } catch (err: any) {
          showNotification(
            'error',
            err.code === 'auth/email-already-in-use'
              ? 'Account has already been created with this email address. Please sign in to start a new application.'
              : 'Unexpected error trying to start your application.'
          );
          setIsApplicationStarting(false);
          return;
        }
      } else {
        const user = await signInAnonymousUser();
        if (!user) {
          console.error('failed to sign in anonymously.');
          setIsApplicationStarting(false);
          return;
        }
      }
      setEmail(startEmail);
      startApplicationWithEmailAndRedirect({
        email: startEmail,
        registration,
        externalLeadId,
        agentUid,
      });
    };

    signUserAndStartApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  // log out user when they try to open start page and don't have account yet
  useEffect(() => {
    if (!authUser.promised && !userEmail && !applicationId && !isMerchantUserRegistration) {
      console.log('signOut');
      signOut();
    }
  }, [authUser.promised, userEmail, applicationId, isMerchantUserRegistration, signOut]);

  return {
    isApplicationStarting,
  };
};
