import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import {IStep} from '../../constants/steps';
import {useApplicationQuery} from '../Application/hooks/useApplicationQuery';

interface Props {
  isLastStep: boolean;
  isVerifyStep: boolean;
  activeStep: IStep;
  onBack(): any;
  onNext(): any;
  onSaveTemplate?: () => void;
  loading?: boolean;
  isBackButtonHidden?: boolean;
  isApplicationFormForAgentOrErpUser: boolean;
}

const Actions = ({
  isLastStep,
  isVerifyStep,
  activeStep,
  onBack,
  onNext,
  onSaveTemplate,
  loading,
  isApplicationFormForAgentOrErpUser,
  isBackButtonHidden = false,
}: Props) => {
  const {isSummary} = useApplicationQuery();
  const submitApplicationText = isApplicationFormForAgentOrErpUser
    ? 'Submit Paper Application'
    : 'Submit and Verify Your Identity';

  return (
    <Grid container spacing={1} alignItems="end">
      {!isBackButtonHidden && (
        <Grid item xs={12} sm={onSaveTemplate ? 4 : 6}>
          <Button
            variant="contained"
            onClick={onBack}
            fullWidth
            disabled={Boolean(activeStep.index === 0 || isLastStep)}
          >
            {isSummary && 'Return to summary'}
            {!isSummary && (activeStep.index === 1 ? 'Restart' : 'Back')}
          </Button>
        </Grid>
      )}
      {onSaveTemplate && (
        <Grid item xs={12} sm={4}>
          <Button variant="contained" onClick={onSaveTemplate} fullWidth>
            Save Pricing Template
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={onSaveTemplate ? 4 : 6}>
        {isLastStep && !isApplicationFormForAgentOrErpUser && (
          <Alert severity="info" sx={{marginBottom: 1}}>
            Please be prepared to answer 3 identity questions on the following page
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          onClick={onNext}
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {isSummary && 'Save & return to summary'}
              {!isSummary &&
                (isLastStep ? submitApplicationText : isVerifyStep ? 'Verify Identity' : 'Next')}
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Actions;
