import {Firebase, TransferIntent, TransferStatus} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useEffect, useMemo, useRef} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useCallable} from '../../firebase/hooks/useCallable';
import {useStore} from '../../store';

export const Transfer = () => {
  const history = useHistory();
  const {getTransferAuthToken} = useCallable();
  const {setTransferStatus, signInWithCustomToken} = useStore();
  const isSigningInByToken = useRef(false);

  const tokenId = useMemo(() => {
    const query = new URLSearchParams(history.location.search);
    return query.get('tokenId');
  }, [history.location.search]);

  useEffect(() => {
    if (!tokenId) {
      history.push(ROUTES.START);
      return;
    }

    let mounted = true;

    const handleAuthByToken = async () => {
      const result = await getTransferAuthToken({tokenId});
      if (result.status !== 'ok') {
        await Firebase.auth.signOut();
        history.push('/');
        return;
      }

      isSigningInByToken.current = true;
      const user = await signInWithCustomToken(result.token);
      if (!user) {
        history.push('/');
        return;
      }

      const idToken = await user.user?.getIdTokenResult();
      if (!mounted) {
        return;
      }

      if (!idToken) {
        history.push(ROUTES.START);
        return;
      }

      const {transferIntent, applicationId} = idToken.claims;

      if (transferIntent === TransferIntent.transferApplication) {
        await setTransferStatus(applicationId, TransferStatus.applicationViewed);
      }

      if (applicationId) {
        history.push(generatePath(ROUTES.APPLICATION_DETAILS, {applicationId}));
      } else {
        history.push('/');
      }
    };

    handleAuthByToken();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [history, setTransferStatus, signInWithCustomToken, tokenId]);

  return <Loading />;
};
