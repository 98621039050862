import {
  Alert,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationSubCollection, Collections, Firebase, InstantIdQaProduct} from '@ozark/common';
import {ExternalServicesApi} from '@ozark/common/api';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import Title from '../Title';

type Props = {
  applicationId: string;
  setValidationHandler(handleSubmit: any): any;
  externalServicesApi: ExternalServicesApi;
  clearSessionAndAdvanceToFinalPage: () => Promise<void>;
};

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  legend: {
    marginBottom: theme.spacing(1),
    lineHeight: '1.5em',
  },
  expirationCallout: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5em',
    },
    color: '#fd632f',
  },
}));

const InstandIdQaPage = ({
  applicationId,
  setValidationHandler,
  externalServicesApi,
  clearSessionAndAdvanceToFinalPage,
}: Props) => {
  const classes = useStyles();
  /**
   * { questionId: choiceId }
   */
  const [choices, setChoices] = useState<Record<number, string>>({});
  const [instantIdQaProduct, setInstantIdQaProduct] = useState<InstantIdQaProduct>();
  const [helperText, setHelperText] = useState<Record<number, string>>({});
  const [errors, setErrors] = useState<Record<number, boolean>>({});
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState<number>();

  useEffect(() => {
    (async () => {
      const unsubscribe = Firebase.firestore
        .collection(Collections.applications)
        .doc(applicationId)
        .collection(ApplicationSubCollection.instantIdQa)
        .limit(1)
        .onSnapshot(snapshot => {
          if (snapshot.empty) return;

          const product = snapshot.docs[0].data() as InstantIdQaProduct;
          setInstantIdQaProduct(product);
          const timeLeft = Math.round((product.expiresAt.toMillis() - new Date().getTime()) / 1000);
          setSecondsLeft(timeLeft);
          setChoices({});
        });
      return () => {
        unsubscribe();
      };
    })();
  }, [applicationId]);

  useEffect(() => {
    const interval = setInterval(async () => {
      // Cannot use falsy check as zero will be falsy also.
      if (secondsLeft === undefined) return;

      if (secondsLeft <= 0) {
        await clearSessionAndAdvanceToFinalPage();
      } else {
        setSecondsLeft(secondsLeft => secondsLeft! - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [secondsLeft, setSecondsLeft]);

  useEffect(() => {
    setValidationHandler(() => (onSuccess: any, onError: any) => async () => {
      if (!instantIdQaProduct || !instantIdQaProduct.QuestionSet) return;

      setHelperText({});
      setErrors({});

      instantIdQaProduct.QuestionSet.Questions.forEach(question => {
        if (!choices[question.QuestionId]) {
          setHelperText(helperText => ({
            [question.QuestionId]: 'Please select an answer from above',
            ...helperText,
          }));
          setErrors(errors => ({[question.QuestionId]: true, ...errors}));
        }
      });

      if (instantIdQaProduct.QuestionSet.Questions.length !== Object.keys(choices).length) {
        onError('Missing an answer');
      } else {
        // Submit answers to Lexis Nexis.
        try {
          setLoading(true);

          const response = await externalServicesApi.instantIdQandAContinue({
            applicationId: applicationId,
            answers: Object.keys(choices).map(key => ({
              questionId: Number(key),
              choices: [{choice: choices[Number(key)]}],
            })),
          });

          // If there are more pending questions, scroll to the top and make it look like page turned.
          if (response && response.additionalQuestions) {
            onError('Additional questions required');
            setLoading(false);

            if (scrollRef && scrollRef.current) {
              // @ts-ignore
              scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
            }

            return;
          }

          onSuccess();
        } catch (err: any) {
          onSuccess();
        }
      }
    });
    // eslint-disable-next-line
  }, [setValidationHandler, choices, instantIdQaProduct, setErrors, setHelperText, applicationId]);

  const handleSelectChoice = (questionId: number) => (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setChoices(choices => ({...choices, [questionId]: event.target.value}));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} ref={scrollRef}>
        <Title
          h1="Verify your identity"
          h2="Just a few more questions that will help us to confirm your identity"
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          position: 'sticky',
          top: '80px',
          bottom: {
            xs: '80px',
            sm: '24px',
          },
          zIndex: 1,
        }}
      >
        {!loading && (
          <Alert severity="info" style={{width: 'auto'}}>
            Seconds left to complete verification: <b style={{color: 'red'}}>{secondsLeft}</b>
          </Alert>
        )}
      </Grid>
      {loading ? (
        <Grid
          container
          xs={12}
          justifyContent="center"
          style={{minHeight: 300}}
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        instantIdQaProduct?.QuestionSet &&
        instantIdQaProduct.QuestionSet.Questions.map(question => (
          <Grid item xs={12} key={question.QuestionId}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              error={errors[question.QuestionId]}
            >
              <FormLabel component="legend" className={classes.legend}>
                {question.Text.Statement}
              </FormLabel>
              <RadioGroup
                aria-label="questions"
                name={'question-' + question.QuestionId.toString()}
                value={choices[question.QuestionId] || ''}
                onChange={handleSelectChoice(question.QuestionId)}
              >
                {question.Choices.map(choice => (
                  <FormControlLabel
                    key={choice.ChoiceId}
                    value={choice.ChoiceId.toString()}
                    control={<Radio />}
                    label={choice.Text.Statement}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>{helperText[question.QuestionId]}</FormHelperText>
            </FormControl>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default InstandIdQaPage;
