import {ProcessingTypes} from '../constants';
import {ApplicationEquipment} from './Application';
import {DocumentBase} from './DocumentBase';
import {RateSet} from './RateProgram';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type ApplicationTemplate = DocumentBase & {
  templateName: string;
  createdBy: string;
  processingType: ProcessingTypes;
  rateSet: RateSet;
  equipment?: ApplicationEquipment;
};

export type ApplicationTemplateView = ViewBase<ApplicationTemplate>;

export const selectApplicationTemplateView: ViewSelector<
  ApplicationTemplateView,
  ApplicationTemplate
> = snapshot => {
  const result: ApplicationTemplateView = {
    ...defaultViewSelector(snapshot),
  };
  return result;
};
