import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const GeneratePdfDialog = ({
  onClose,
  onNo,
  onYes,
}: {
  onClose: () => void;
  onNo: () => void;
  onYes: () => void;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={true} onClose={onClose} fullScreen={fullScreen} maxWidth={'md'}>
      <DialogTitle>Do you want to regenerate MPAs?</DialogTitle>
      <DialogContent>
        It's best to regenerate MPAs only when necessary. <br />
        <br />
        If you plan to make additional updates to this application, click No.
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>No</Button>
        <Button onClick={onYes} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
