import {Box, Container, CssBaseline, Grid} from '@mui/material';
import {SubmittedPageHead} from '@ozark/common/components';
import {useEffect} from 'react';
import {useStore} from '../../store';
import Title from '../Title';

export const AppTransferredMessage = () => {
  const {group} = useStore();
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    timer = setTimeout(() => {
      window.close();
    }, 5 * 1000); //5 sec
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container direction="column" alignItems="center" sx={{py: 6}}>
          <Grid item>{group.data && <SubmittedPageHead group={group.data} />}</Grid>
          <Grid item>
            <Box sx={{width: '100%'}}>
              <Title
                h1="SUCCESS!"
                h2={
                  <Box sx={{width: '100%', textAlign: 'left'}}>
                    <Box sx={{textAlign: 'center'}}>Application was successfully transferred</Box>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
