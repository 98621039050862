import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, InputAdornment, MenuItem, TextField as MuiTextField, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationType,
  ApplicationView,
  DeprecatedShippingSpeeds,
  EbtAcceptanceStatus,
  OnlineAppShippingSpeeds,
  PCIFee,
  PCIFeeTypes,
  ProcessingTypes,
  ShippingSpeed,
  ShippingType,
  StateArray,
  StateHash,
} from '@ozark/common';
import {
  AutoCompleteAddressTextField,
  SectionTitle,
  Select,
  TextField,
} from '@ozark/common/components';
import {PRICING_VISA_MASTER} from '@ozark/common/constants/strings';
import {emptyStringToNull} from '@ozark/common/helpers/schemaValidationHelpers';
import {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import Title from '../Title';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  equipment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 25,
    marginBottom: theme.spacing(2),
  },
  equipmentImage: {
    flexGrow: 1,
    borderRadius: 25,
    width: `100%`,
    height: 100,
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      height: 150,
    },
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
  },
}));

enum RateType {
  dollar = '$',
  percent = '%',
}
interface Props {
  setValidationHandler(handleSubmit: any): any;
}

const schema = yup.object().shape({
  shipping: yup.object({
    other: yup.boolean().default(false).transform(emptyStringToNull).nullable(),
    select: yup.string().transform(emptyStringToNull).nullable(),
    address1: yup.string().transform(emptyStringToNull).nullable(),
    city: yup.string().transform(emptyStringToNull).nullable(),
    state: yup.string().transform(emptyStringToNull).nullable(),
    zip: yup.string().transform(emptyStringToNull).nullable(),
  }),
});

const transform = (onSuccess: any, data: any) => {
  onSuccess(data);
};

const addresses: {
  [ShippingType.businessAddress]: string;
  [ShippingType.mailingAddress]: string;
  [ShippingType.homeAddress]: string;
  [ShippingType.other]: string;
} = {
  businessAddress: '',
  mailingAddress: '',
  homeAddress: '',
  other: 'Other',
};

const generateAddresses = () =>
  Object.entries(addresses)
    .filter(([_, v]) => v)
    .sortAndMap(
      ([k, v]) => (
        <MenuItem key={k} value={k}>
          {v}
        </MenuItem>
      ),
      ([_k, v]) => v
    );

const ProgramReviewPage = ({setValidationHandler}: Props) => {
  const classes = useStyles();
  const {application} = useStore();
  const app = application.data as Partial<ApplicationView> | null;

  const [chosenAddress, setChosenAddress] = useState<string>();

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,

    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...application.data,
    },
    shouldUnregister: true,
  });

  function setAddress(address1: any, address2: any, city: any, state: any, zip: any) {
    const empty = '';
    setValue('shipping.address1', address1 ?? empty, {shouldDirty: true});
    setValue('shipping.address2', address2 ?? empty, {shouldDirty: true});
    setValue('shipping.city', city ?? empty, {shouldDirty: true});
    setValue('shipping.state', state ?? empty, {shouldDirty: true});
    setValue('shipping.zip', zip ?? empty, {shouldDirty: true});
  }

  addresses.businessAddress = `Business Address: ${application.data?.businessAddress1}${
    application.data?.businessAddress2 ? ' ' + application.data?.businessAddress2 : ''
  }, ${application.data?.businessCity}, ${application.data?.businessState}, ${
    application.data?.businessZip
  }`;
  addresses.homeAddress = `Home Address: ${application.data?.homeAddress1}${
    application.data?.homeAddress2 ? ' ' + application.data?.homeAddress2 : ''
  }, ${application.data?.homeCity}, ${application.data?.homeState}, ${application.data?.homeZip}`;

  if (application.data?.mailingAddressDifferent) {
    addresses.mailingAddress = `Mailing Addresss: ${application.data?.mailingAddress1}${
      application.data?.mailingAddress2 ? ' ' + application.data?.mailingAddress2 : ''
    }, ${application.data?.mailingCity}, ${application.data?.mailingState}, ${
      application.data?.mailingZip
    }`;
  }

  addresses.businessAddress = `Business Address: ${application.data?.businessAddress1} ${application.data?.businessAddress2}, ${application.data?.businessCity}, ${application.data?.businessState}, ${application.data?.businessZip}`;
  addresses.homeAddress = `Home Address: ${application.data?.homeAddress1} ${application.data?.homeAddress2}, ${application.data?.homeCity}, ${application.data?.homeState}, ${application.data?.homeZip}`;

  if (application.data?.mailingAddressDifferent) {
    addresses.mailingAddress = `Mailing Address: ${application.data?.mailingAddress1} ${application.data?.mailingAddress2}, ${application.data?.mailingCity}, ${application.data?.mailingState}, ${application.data?.mailingZip}`;
  }

  const displayPCIFeeValue =
    application.data?.rateSet?.pciFee !== undefined &&
    application.data?.rateSet?.pciFee !== PCIFee.waived;

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          transform(onSuccess, data);
        }, onError)
    );
  }, [setValidationHandler, handleSubmit]);

  useEffect(() => {
    register('shipping.select');
    register('shipping.other');
    if (application.data?.shipping?.select) {
      setChosenAddress(application.data?.shipping?.select);
    }
    // eslint-disable-next-line
  }, [register]);

  const handleShippingAddressAutoFill = (city: any, state: any, zip: any) => {
    const empty: any = '';
    setValue('shipping.address2', empty, {shouldDirty: true});
    setValue('shipping.city', city ?? empty, {shouldDirty: true});
    setValue('shipping.state', state ?? empty, {shouldDirty: true});
    setValue('shipping.zip', zip ?? empty, {shouldDirty: true});
  };

  const handleShippingChange = (event: ChangeEvent<{value: unknown}>) => {
    const shippingChoice = event.target.value as ShippingType;
    setChosenAddress(shippingChoice);
    setValue('shipping.select', shippingChoice as any, {shouldDirty: true});
    switch (shippingChoice) {
      case ShippingType.businessAddress:
        const {businessAddress1, businessAddress2, businessCity, businessState, businessZip} =
          application.data;
        setAddress(businessAddress1, businessAddress2, businessCity, businessState, businessZip);
        break;
      case ShippingType.mailingAddress:
        const {mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingZip} =
          application.data;
        setAddress(mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingZip);
        break;
      case ShippingType.homeAddress:
        const {homeAddress1, homeAddress2, homeCity, homeState, homeZip} = application.data;
        setAddress(homeAddress1, homeAddress2, homeCity, homeState, homeZip);
        break;
      case ShippingType.localPickup:
      case ShippingType.other:
        setAddress(null, null, null, null, null);
    }
    if (shippingChoice === ShippingType.other) {
      setValue('shipping.other', true, {shouldDirty: true});
    } else {
      setValue('shipping.other', false, {shouldDirty: true});
    }
  };

  const watchEquipmentIsGateway = watch('equipment.isGateway');
  const watchShippingOther = watch('shipping.other');
  const applicationType = application.data?.rateSet?.applicationType;
  const isCnpProg = applicationType === ApplicationType.cnpVCp;
  const isErrProg = applicationType === ApplicationType.err;
  const isInterchangeProg = applicationType === ApplicationType.interchange;
  const isDebit = ![
    ApplicationType.interchange,
    ApplicationType.err,
    ApplicationType.flatRate,
    ApplicationType.tiered,
  ].includes(applicationType);

  const availableShippingSpeeds = Object.values(ShippingSpeed).filter(
    s => !DeprecatedShippingSpeeds.includes(s) && !OnlineAppShippingSpeeds.includes(s)
  );

  const ReadOnlyTextField = ({
    label,
    rate,
    rateType,
  }: {
    label: string;
    rate: any;
    rateType: RateType;
  }) => {
    let number = rate || 0;
    if (typeof number === 'string') {
      number = Number(number);
    }

    return (
      <MuiTextField
        variant="outlined"
        margin="normal"
        label={label}
        value={rateType === RateType.dollar ? `$${number.toFixed(2)}` : `${number.toFixed(2)}%`}
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment: <InputAdornment position="end" />,
        }}
        fullWidth
        disabled={true}
      />
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Pricing & Equipment Summary" h2="Please review the pricing and equipment." />
      </Grid>

      <Grid item xs={12}>
        <SectionTitle text={`${PRICING_VISA_MASTER}${isDebit ? ' and Debit' : ''}`} />
      </Grid>
      <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
        <ReadOnlyTextField
          label={'Qualified Rate'}
          rate={application?.data?.rateSet?.qualifiedDiscountRateVisaMastercardDiscover}
          rateType={RateType.percent}
        />
      </Grid>
      {!isCnpProg && !isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={4}>
          <ReadOnlyTextField
            label={'Mid-Qualified Rate'}
            rate={application?.data?.rateSet?.midQualifiedDiscountRateVisaMastercardDiscover}
            rateType={RateType.percent}
          />
        </Grid>
      )}
      {!isInterchangeProg && (
        <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
          <ReadOnlyTextField
            label={isErrProg ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate'}
            rate={application?.data?.rateSet?.nonQualifiedDiscountRateVisaMastercardDiscover}
            rateType={RateType.percent}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
        <ReadOnlyTextField
          label={'Qualified Transaction Fee'}
          rate={application?.data?.rateSet?.qualifiedTransactionFeeVisaMastercardDiscover}
          rateType={RateType.dollar}
        />
      </Grid>
      {!isCnpProg && !isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={4}>
          <ReadOnlyTextField
            label={'Mid-Qualified Transaction Fee'}
            rate={application?.data?.rateSet?.midQualifiedTransactionFeeVisaMastercardDiscover}
            rateType={RateType.dollar}
          />
        </Grid>
      )}
      {!isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
          <ReadOnlyTextField
            label={'Non-Qualified Transaction Fee'}
            rate={application?.data?.rateSet?.nonQualifiedTransactionFeeVisaMastercardDiscover}
            rateType={RateType.dollar}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SectionTitle text={`American Express`} />
      </Grid>
      <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
        <ReadOnlyTextField
          label={'Qualified Rate'}
          rate={application?.data?.rateSet?.qualifiedDiscountRateAmex}
          rateType={RateType.percent}
        />
      </Grid>
      {!isCnpProg && !isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={4}>
          <ReadOnlyTextField
            label={'Mid-Qualified Rate'}
            rate={application?.data?.rateSet?.midQualifiedDiscountRateAmex}
            rateType={RateType.percent}
          />
        </Grid>
      )}
      {!isInterchangeProg && (
        <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
          <ReadOnlyTextField
            label={isErrProg ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate'}
            rate={application?.data?.rateSet?.nonQualifiedDiscountRateAmex}
            rateType={RateType.percent}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
        <ReadOnlyTextField
          label={'Qualified Transaction Fee'}
          rate={application?.data?.rateSet?.qualifiedTransactionFeeAmex}
          rateType={RateType.dollar}
        />
      </Grid>
      {!isCnpProg && !isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={4}>
          <ReadOnlyTextField
            label={'Mid-Qualified Transaction Fee'}
            rate={application?.data?.rateSet?.midQualifiedTransactionFeeAmex}
            rateType={RateType.dollar}
          />
        </Grid>
      )}
      {!isErrProg && !isInterchangeProg && (
        <Grid item xs={12} sm={isCnpProg || isErrProg || isInterchangeProg ? 6 : 4}>
          <ReadOnlyTextField
            label={'Non-Qualified Transaction Fee'}
            rate={application?.data?.rateSet?.nonQualifiedTransactionFeeAmex}
            rateType={RateType.dollar}
          />
        </Grid>
      )}

      {Boolean(application.data?.acceptPIN) && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Debit" />
          </Grid>
          {!isCnpProg && (
            <Grid item xs={12} sm={6}>
              <ReadOnlyTextField
                label={'Signature Debit Non-Pin Based Rate'}
                rate={application?.data?.rateSet?.qualifiedDebitNonPINRate}
                rateType={RateType.percent}
              />
            </Grid>
          )}
          {!isCnpProg && (
            <Grid item xs={12} sm={6}>
              <ReadOnlyTextField
                label={'Signature Debit Non-Pin Based Transaction Fee'}
                rate={application?.data?.rateSet?.qualifiedDebitNonPINFee}
                rateType={RateType.dollar}
              />
            </Grid>
          )}
          {application?.data?.processingType === ProcessingTypes.cardPresent && (
            <>
              <Grid item xs={12} sm={6}>
                <ReadOnlyTextField
                  label={'Debit Pin Based Rate'}
                  rate={application?.data?.rateSet?.qualifiedDebitPINRate}
                  rateType={RateType.percent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReadOnlyTextField
                  label={'Debit Pin Based Transaction Fee'}
                  rate={application?.data?.rateSet?.qualifiedDebitPINFee}
                  rateType={RateType.dollar}
                />
              </Grid>
            </>
          )}
        </Fragment>
      )}

      {app?.ebtAcceptance === EbtAcceptanceStatus.Yes && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="EBT" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ReadOnlyTextField
              label={'EBT Discount Rate'}
              rate={app?.rateSet?.ebtDiscountRate}
              rateType={RateType.percent}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReadOnlyTextField
              label={'EBT Transaction Fee'}
              rate={app?.rateSet?.ebtTransactionFee}
              rateType={RateType.dollar}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Other Volume & Other Item Fee (All Card Types)" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextField
          label={'Discount Rate'}
          rate={application?.data?.rateSet?.otherVolumeRate}
          rateType={RateType.percent}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextField
          label={'Transaction Fee'}
          rate={application?.data?.rateSet?.otherItemFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Miscellaneous Fees" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Account on File Fee'}
          rate={application?.data?.rateSet?.accountOnFileFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Chargeback Fee'}
          rate={application?.data?.rateSet?.chargebackFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Retrieval Fee'}
          rate={application?.data?.rateSet?.retrievalFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Monthly Minimum Fee'}
          rate={application?.data?.rateSet?.monthlyMinimumFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'AVS Fee'}
          rate={application?.data?.rateSet?.avsFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Batch Fee'}
          rate={application?.data?.rateSet?.batchFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Debit Access Fee'}
          rate={application?.data?.rateSet?.debitAccessFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ReadOnlyTextField
          label={'Additional Service Fees'}
          rate={application?.data?.rateSet?.additionalServicesFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MuiTextField
          variant="outlined"
          margin="normal"
          label={'PCI Fee'}
          value={PCIFeeTypes[application?.data?.rateSet?.pciFee as PCIFee]}
          fullWidth
          disabled={true}
        />
      </Grid>
      {displayPCIFeeValue && (
        <Grid item xs={12} sm={3}>
          <ReadOnlyTextField
            label={'PCI Fee Value'}
            rate={application?.data?.rateSet?.pciFeeValue}
            rateType={RateType.dollar}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <ReadOnlyTextField
          label={'Early Deconversion Fee'}
          rate={application?.data?.rateSet?.earlyDeconversionFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <ReadOnlyTextField
          label={'Annual Fee'}
          rate={application?.data?.rateSet?.annualFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <ReadOnlyTextField
          label={'Regulatory'}
          rate={application?.data?.rateSet?.regulatoryFee}
          rateType={RateType.dollar}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Equipment" />
      </Grid>
      <Grid xs={12}>
        <div className={classes.equipment}>
          <Typography variant="h4" align="center" gutterBottom>
            {application?.data?.equipment?.name}
          </Typography>
          {application?.data?.processingType !== ProcessingTypes.eCommerce &&
          (application?.data?.equipment?.price || application?.data?.equipment?.price === 0) ? (
            <Typography variant="body1" align="center" gutterBottom>
              ${application?.data?.equipment?.price?.toFixed(2) || ''}
              <Typography variant="caption">/{application?.data?.equipment?.duration}</Typography>
            </Typography>
          ) : (
            <Typography gutterBottom />
          )}
          <img
            className={classes.equipmentImage}
            src={application?.data?.equipment?.imageUrl}
            alt="e"
          />
        </div>
      </Grid>
      {watchEquipmentIsGateway !== undefined && !watchEquipmentIsGateway && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Shipping" />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="equipment.shippingSpeed"
              label="Shipping Speed"
              errors={errors}
              control={control}
              options={availableShippingSpeeds}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiTextField
              name="whereto2"
              label="Where to Ship?"
              value={chosenAddress}
              onChange={handleShippingChange}
              select
              variant="outlined"
              margin="normal"
              fullWidth
            >
              {generateAddresses()}
            </MuiTextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="shipping.attention"
              label="Attention"
              required
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteAddressTextField
              setAutofillHandler={handleShippingAddressAutoFill}
              name="shipping.address1"
              label="Address Line 1"
              disabled={!watchShippingOther}
              required
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="shipping.address2"
              label="Address Line 2"
              disabled={!watchShippingOther}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="shipping.city"
              label="City"
              required
              disabled={!watchShippingOther}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              name="shipping.state"
              label="State"
              required
              disabled={!watchShippingOther}
              errors={errors}
              control={control}
            >
              {StateArray.sortAndMap(
                state => (
                  <MenuItem key={`${state}2`} value={state}>
                    {StateHash[state]}
                  </MenuItem>
                ),
                state => StateHash[state]
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="shipping.zip"
              label="Zip Code"
              required
              disabled={!watchShippingOther}
              errors={errors}
              control={control}
              transform={{
                pattern: '99999',
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ProgramReviewPage;
