import {ProcessingTypes} from '@ozark/common';
import {PageAnswers, QuestionAnswer} from '../types';
import {useSummaryCommon} from './useSummaryCommon';

export const useBusinessStepAnswers = () => {
  const {emptyAnswers, getAvailableQuestionAnswer, getQuestionAnswer, getYesNo, app} =
    useSummaryCommon();

  const getOwnershipType = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(question, app.ownershipType);
  };

  const getBusinessStartYear = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(question, app.businessStartYear);
  };

  const getLocationType = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(question, app.locationType);
  };

  const getBusinessStepAnswers = (
    ownershipTypeQuestion: string,
    businessStartYearQuestion: string,
    locationTypeQuestion: string
  ): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    return {
      sectionAnswers: [
        {
          isAvailable: true,
          header: 'Business Profile',
          questionAnswers: [
            getOwnershipType(ownershipTypeQuestion),
            getAvailableQuestionAnswer('Legal Business Name', app.legalBusinessName),
            getAvailableQuestionAnswer('Employer Identification Number (EIN)', app.federalTaxId),
            getAvailableQuestionAnswer('Doing Business As (DBA)', app.doingBusinessAs),
            getAvailableQuestionAnswer(
              'What state did you start your business in?',
              app.filingState
            ),
            getBusinessStartYear(businessStartYearQuestion),
            getLocationType(locationTypeQuestion),
            getAvailableQuestionAnswer('Customer Service Email', app.customerServiceEmail),
            getAvailableQuestionAnswer('Business Phone Number', app.businessPhone),
            getQuestionAnswer(
              'Website',
              app.website,
              app.processingType !== ProcessingTypes.cardPresent
            ),
          ],
        },
        {
          isAvailable: true,
          header: 'Physical Business Address',
          questionAnswers: [
            getAvailableQuestionAnswer('Address Line 1', app.businessAddress1),
            getAvailableQuestionAnswer('Address Line 2', app.businessAddress2),
            getAvailableQuestionAnswer('City', app.businessCity),
            getAvailableQuestionAnswer('State', app.businessState),
            getAvailableQuestionAnswer('Zip Code', app.businessZip),
            getAvailableQuestionAnswer(
              'Mailing Address is different than Physical Address',
              getYesNo(app.mailingAddressDifferent)
            ),
          ],
        },
        {
          isAvailable: !!app.mailingAddressDifferent,
          header: 'Mailing Address',
          questionAnswers: [
            getAvailableQuestionAnswer('Address Line 1', app.mailingAddress1),
            getAvailableQuestionAnswer('Address Line 2', app.mailingAddress2),
            getAvailableQuestionAnswer('City', app.mailingCity),
            getAvailableQuestionAnswer('State', app.mailingState),
            getAvailableQuestionAnswer('Zip Code', app.mailingZip),
          ],
        },
      ],
    };
  };

  const getYourBusinessStepAnswers = (): PageAnswers => {
    return getBusinessStepAnswers(
      "What's your business structure?",
      'What year did you start your business?',
      'What best described your location type?'
    );
  };

  const getYourBusinessStepAnswersForAgentOrErpUser = (): PageAnswers => {
    return getBusinessStepAnswers(
      "What's the business structure?",
      'What year did the business start?',
      'What best described the location type?'
    );
  };

  return {
    getYourBusinessStepAnswers,
    getYourBusinessStepAnswersForAgentOrErpUser,
  };
};
