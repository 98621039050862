export const avgSaleOptions = {
  '< $10': 10,
  '$10 to $50': 50,
  '$50 to $100': 100,
  '$100 to $500': 500,
  '$500 to $1,000': 1000,
  '$1,000 to $2,500': 2500,
  '$2,500 to $5,000': 5000,
  '$5,000 to $10,000': 10000,
  '$10,000 to $20,000': 20000,
  '> $20,000': 20001,
};

export const estimatedMonthlyCreditCardSalesOptions = {
  '< $25,000': 25000,
  '$25,000 to $50,000': 50000,
  '$50,000 to $100,000': 100000,
  '$100,000 to $250,000': 250000,
  '$250,000 to $500,000': 500000,
  '$500,000 to $1,000,000': 1000000,
  '> $1,000,000': 1000001,
};

export const highestSaleOptions = {
  '< $50': 50,
  '$50 to $100': 100,
  '$100 to $250': 250,
  '$250 to $500': 500,
  '$500 to $1,000': 1000,
  '$1,000 to $2,500': 2500,
  '$2,500 to $5,000': 5000,
  '$5,000 to 10,000': 10000,
  '$10,000 to $25,000': 25000,
  '$25,000 to $50,000': 50000,
  '> $50,000': 50001,
};
