import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';
import {useStore} from '../../store';

const Pixel = () => {
  const {group} = useStore();
  const location = useLocation();
  const [canonical, setCanonical] = useState(window.location.href);

  useEffect(() => {
    setCanonical(window.location.href);
  }, [location]);

  return (
    <>
      {group.data?.appDomain === 'app.zaray.com' && (
        <Helmet>
          <link rel="canonical" href={canonical} />
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11079756849"></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11079756849');`}
          </script>
          {/* End Google Tag Manager */}
          {/* Facebook Pixel Code */}
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '838734240763791');
            fbq('track', 'PageView');`}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=838734240763791&ev=PageView&noscript=1"
          />`}
          </noscript>
          {/* End Facebook Pixel Code */}
        </Helmet>
      )}
      {group.data?.appDomain === 'app.luqra.com' && (
        <Helmet>
          <link rel="canonical" href={canonical} />
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11079810358"></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11079810358');`}
          </script>
          {/* End Google Tag Manager */}
          {/* Facebook Pixel Code */}
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '723539409462885');
            fbq('track', 'PageView');`}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=723539409462885&ev=PageView&noscript=1" />`}
          </noscript>
          {/* End Facebook Pixel Code */}
        </Helmet>
      )}
    </>
  );
};

export default Pixel;
