/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import SecurityIcon from '@mui/icons-material/Security';
import {FormControlLabel, Grid, InputAdornment, MenuItem, Radio, Tooltip} from '@mui/material';
import {OwnerTitles, SignerPageSchema, StateArray, StateHash} from '@ozark/common';
import {
  AutoCompleteAddressTextField,
  BooleanRadioGroup,
  SectionTitle,
  TextField,
} from '@ozark/common/components';
import {omit} from '@s-libs/micro-dash';
import {parse} from 'date-fns';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useCallable} from '../../firebase/hooks/useCallable';
import {useStore} from '../../store';
import Select from '../Select';
import {signerDataTransform, SignerFormData} from '../SignerPage';
import {OtherOwners} from '../SignerPage/OtherOwners';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  setEndSessionHandler?(handleEndSession: any): any;
  onDirty?: (isDirty: boolean) => void;
  readonlyAccess?: boolean;
};

const SignerPageForAgents = ({
  setValidationHandler,
  setEndSessionHandler,
  onDirty,
  readonlyAccess,
}: Props) => {
  const {application, group} = useStore();
  const {encryptField} = useCallable();
  const {
    firstName,
    lastName,
    title,
    dateOfBirth,
    homeAddress1,
    homeAddress2,
    homeCity,
    homeState,
    homeZip,
    otherOwners,
    otherOwnersData,
    percentOwnership,
    encryptedSocialSecurityNumber,
  } = application.data ?? {};
  const {
    formState: {errors, isDirty},
    reset,
    control,
    watch,
    getValues,
    handleSubmit,
    setValue,
    setError,
  } = useForm<SignerFormData>({
    defaultValues: {
      firstName,
      lastName,
      title,
      dateOfBirth,
      homeAddress1,
      homeAddress2,
      homeCity,
      homeState,
      homeZip,
      otherOwners,
      otherOwnersData: otherOwnersData?.length > 0 ? otherOwnersData : [{title: 'Owner'}],
      percentOwnership: percentOwnership ?? 100,
      encryptedSocialSecurityNumber,
      driverLicenseState: application.data?.driverLicenseState,
      driverLicenseNumber: application.data?.driverLicenseNumber,
      cellPhone: application.data?.cellPhone,
      personaId: application.data?.personaId,
    },
    resolver: yupResolver(SignerPageSchema),
    context: {
      dobSsnTaxIdAndBankInfoNotRequired:
        group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired,
    },
  });

  const handleHomeAddressAutoFill = (city: any, state: any, zip: any) => {
    setValue('homeAddress2', '', {shouldDirty: true});
    setValue('homeCity', city ? city : '', {shouldDirty: true});
    setValue('homeState', state ? state : '', {shouldDirty: true});
    setValue('homeZip', zip ? zip : '', {shouldDirty: true});
  };

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit(data => {
          const totalPercentageOwnership =
            (data.percentOwnership ?? 0) +
            data.otherOwnersData.reduce((acc, cur) => acc + (cur.percentOwnership ?? 0), 0);

          if (totalPercentageOwnership > 100) {
            setError('percentOwnership', {
              type: 'custom',
              message:
                'Percentage ownership for all owners combined must be less than or equal to 100%.',
            });

            if (data.otherOwnersData?.length > 0) {
              for (let i = 0; i < data.otherOwnersData.length; i++) {
                setError(`otherOwnersData.${i}.percentOwnership`, {
                  type: 'custom',
                  message:
                    'Percentage ownership for all owners combined must be less than or equal to 100%.',
                });
              }
            }

            onError({message: 'Percentage ownership must be less than or equal to 100%.'});
            return;
          }

          signerDataTransform(onSuccess, encryptField)(data);
          reset(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  useEffect(() => {
    if (!setEndSessionHandler) return;
    setEndSessionHandler(() => () => {
      const form = omit(getValues(), 'socialSecurityNumber', 'encryptedSocialSecurityNumber');

      if (typeof form.dateOfBirth === 'string') {
        form.dateOfBirth = parse(form.dateOfBirth, 'MM/dd/yyyy', new Date());
      }

      if (form.otherOwnersData && form.otherOwnersData.length > 0) {
        form.otherOwnersData = form.otherOwnersData.map(owner => {
          if (typeof owner.dateOfBirth === 'string' && owner.dateOfBirth) {
            owner.dateOfBirth = parse(owner.dateOfBirth, 'MM/dd/yyyy', new Date());
          }
          if (owner.socialSecurityNumber) {
            delete owner.socialSecurityNumber;
          }

          return owner;
        });
      }

      return form;
    });
  }, [setEndSessionHandler]);

  const watchPercentOwnership = watch('percentOwnership');
  const watchOtherOwners = watch('otherOwners');
  const watchOtherOwnersData = watch('otherOwnersData');
  const watchPersonaId = watch('personaId');
  const watchDlNumber = watch('driverLicenseNumber');

  useEffect(() => {
    // Need to be explicit about checking for false, because if the value is undefined when it loads the form will get reset
    if (
      (watchOtherOwners === false ||
        (watchPercentOwnership !== undefined && watchPercentOwnership > 74)) &&
      watchOtherOwnersData.length
    ) {
      setValue('otherOwnersData', []);
    } else if (watchOtherOwners && !watchOtherOwnersData.length) {
      setValue('otherOwnersData', [{title: 'Owner'} as any]);
    }
  }, [watchOtherOwners, watchPercentOwnership]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Signer Info" h2="Tell us about who will be signing." />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="lastName"
          label="Last Name"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="__/__/____"
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '99/99/9999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="socialSecurityNumber"
          label="Social Security Number"
          errors={errors}
          control={control}
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          disabled={readonlyAccess}
          placeholder={application.data?.encryptedSocialSecurityNumber ? '**********' : null}
          helperText={
            application.data?.encryptedSocialSecurityNumber
              ? "We've already encrypted and captured the signer's SSN. There's no need to enter it again."
              : null
          }
          transform={{
            pattern: '999-99-9999',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="We take our merchant's privacy and security seriously. All sensitive data that we collect is encrypted using an AES-256 encryption algorithm.">
                  <SecurityIcon color="primary" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select
          name="driverLicenseState"
          label="Driver License State"
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          disabled={readonlyAccess}
          errors={errors}
          control={control}
        >
          <MenuItem key={'passport'} value={'Passport'}>
            Passport
          </MenuItem>
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="driverLicenseNumber"
          label={`Driver License Number ${
            watchPersonaId?.isVerified && watchPersonaId?.driverLicenseNumber === watchDlNumber
              ? ' (Verified by Persona Id)'
              : ''
          }`}
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          disabled={readonlyAccess}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="cellPhone"
          label="Cell Phone"
          required
          disabled={readonlyAccess}
          errors={errors}
          control={control}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <SectionTitle text="Home Address" />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleHomeAddressAutoFill}
          name="homeAddress1"
          label="Address Line 1"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="homeAddress2"
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="homeCity"
          label="City"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          name="homeState"
          label="State"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}2`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="homeZip"
          label="Zip Code"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Business Ownership" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="percentOwnership"
          label="What percentage of the business does the signer own?"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="title"
          label="Title"
          required
          defaultValue={OwnerTitles.Owner}
          errors={errors}
          disabled={readonlyAccess}
          control={control}
          options={Object.values(OwnerTitles)}
        />
      </Grid>
      {watchPercentOwnership !== undefined && watchPercentOwnership < 75 && (
        <Grid item xs={12}>
          <BooleanRadioGroup
            name="otherOwners"
            label="Are there any other individuals with ownership above 25%?"
            defaultValue={false}
            required
            errors={errors}
            disabled={readonlyAccess}
            control={control}
          >
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
          </BooleanRadioGroup>
        </Grid>
      )}
      {watchPercentOwnership !== undefined && watchPercentOwnership < 75 && watchOtherOwners && (
        <Grid item xs={12}>
          <OtherOwners control={control} errors={errors} isAgent={true} />
        </Grid>
      )}
    </Grid>
  );
};

export default SignerPageForAgents;
