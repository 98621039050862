import {Application} from '@ozark/common';
import {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {generatePath, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {
  FIRST_AGENT_APPLICATION_STEP,
  FIRST_APPLICATION_STEP,
  getSteps,
  IStep,
} from '../../../constants/steps';
import {useStore} from '../../../store';
import {useApplicationQuery} from './useApplicationQuery';
import {useApplicationRedirectToSummary} from './useApplicationRedirectToSummary';

export const useApplicationSteps = (
  applicationId: string,
  isApplicationForAgent: boolean | undefined
) => {
  const {application, group} = useStore();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const steps = useMemo(() => getSteps(isApplicationForAgent), [isApplicationForAgent]);
  const [activeStep, setActiveStep] = useState(steps[match.path]);
  const {isSummary} = useApplicationQuery();
  const {redirectToSummary} = useApplicationRedirectToSummary();
  const hash = useLocation().hash;

  // set active step when the path changes
  useLayoutEffect(() => {
    setActiveStep(steps[match.path]);
    if (hash === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [match.path, steps, hash]);

  useEffect(() => {
    if (application.promised) {
      return;
    }

    if (!application.data) {
      return;
    }

    if (activeStep) {
      return;
    }

    const getRouteForStep = (step?: string): [string | undefined, IStep | undefined] => {
      if (!step) return [undefined, undefined];

      const routeKey = Object.keys(steps).find(stepRoute => steps[stepRoute].key === step);
      if (routeKey) {
        return [routeKey, steps[routeKey]];
      }
      return [undefined, undefined];
    };

    if (
      group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired &&
      !isApplicationForAgent &&
      application.data.nextPath
    ) {
      const resolvedNextPath = resolveNextPathWhenInfoIsNotRequired(application.data);
      const [validationRoute, validationStep] = getRouteForStep(resolvedNextPath);
      const [, applicationNextStep] = getRouteForStep(application.data.nextPath);

      if (
        validationRoute &&
        validationStep &&
        applicationNextStep &&
        validationStep.index < applicationNextStep.index
      ) {
        history.replace({
          pathname: `${generatePath(validationRoute, {applicationId})}`,
          hash: 'showValidation',
        });
        return;
      }
    }

    if (application.data.nextPath) {
      // redirecting user to nextPath or to the first step of application
      const route = Object.keys(steps).find(
        stepRoute => steps[stepRoute].key === application.data.nextPath
      );

      if (route) {
        history.replace({
          pathname: `${generatePath(route, {applicationId})}`,
        });
        return;
      }
    }

    history.replace(
      generatePath(isApplicationForAgent ? FIRST_AGENT_APPLICATION_STEP : FIRST_APPLICATION_STEP, {
        applicationId,
      })
    );
  }, [
    history,
    application.data,
    application.promised,
    group.data,
    activeStep,
    isApplicationForAgent,
    applicationId,
    steps,
  ]);

  const goToPreviousStep = () => {
    if (activeStep.back) {
      history.push(generatePath(activeStep.back, {applicationId}));
    }
  };

  const goToNextStep = () => {
    if (isSummary) {
      redirectToSummary(applicationId);
      return;
    }
    if (activeStep.next) {
      history.push({
        pathname: generatePath(activeStep.next, {applicationId}),
        ...(location.hash && {hash: location.hash}),
      });
    }
  };

  return {
    activeStep,
    steps,
    goToPreviousStep,
    goToNextStep,
    isLastStep: activeStep?.index === 6,
    isVerifyStep: activeStep?.index === 7,
  };
};

const resolveNextPathWhenInfoIsNotRequired = (application: Application) => {
  const {
    dateOfBirth,
    driverLicenseNumber,
    driverLicenseState,
    encryptedSocialSecurityNumber,
    federalTaxId,
    bankAccountNumber,
    routingNumber,
    otherOwners,
    otherOwnersData,
  } = application;

  if (!federalTaxId) {
    return 'business';
  }

  if (
    !dateOfBirth ||
    !encryptedSocialSecurityNumber ||
    !driverLicenseNumber ||
    !driverLicenseState
  ) {
    return 'signer';
  }

  if (
    otherOwners &&
    otherOwnersData?.some(
      owner =>
        !owner.encryptedSocialSecurityNumber ||
        !owner.dateOfBirth ||
        !owner.driverLicenseNumber ||
        !owner.driverLicenseState
    )
  ) {
    return 'signer';
  }

  if (!bankAccountNumber || !routingNumber) {
    return 'deposits';
  }

  return undefined;
};
