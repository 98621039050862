import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import WebIcon from '@mui/icons-material/Web';
import {Button, Divider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {Fragment} from 'react';
import {Link as RouterLink, useRouteMatch} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5, '10%'),
    marginBottom: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0, 2),
    },
  },
}));

const icons: {[index: string]: React.ReactElement} = {
  [ROUTES.PORTAL_BASICS]: <WebIcon />,
  [ROUTES.PORTAL_BUSINESS]: <BusinessIcon />,
  [ROUTES.PORTAL_SIGNER]: <AccountCircleIcon />,
  [ROUTES.PORTAL_DEPOSITS]: <AccountBalanceIcon />,
};

export const Navigator = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  return (
    <Fragment>
      <div className={classes.root}>
        <Button
          color={match.path === ROUTES.PORTAL_BASICS ? 'primary' : undefined}
          component={RouterLink}
          to={ROUTES.PORTAL_BASICS}
          startIcon={icons[ROUTES.PORTAL_BASICS]}
        >
          Basics
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          color={match.path === ROUTES.PORTAL_BUSINESS ? 'primary' : undefined}
          component={RouterLink}
          to={ROUTES.PORTAL_BUSINESS}
          startIcon={icons[ROUTES.PORTAL_BUSINESS]}
        >
          Business
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          color={match.path === ROUTES.PORTAL_SIGNER ? 'primary' : undefined}
          component={RouterLink}
          to={ROUTES.PORTAL_SIGNER}
          startIcon={icons[ROUTES.PORTAL_SIGNER]}
        >
          Signer
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          color={match.path === ROUTES.PORTAL_DEPOSITS ? 'primary' : undefined}
          component={RouterLink}
          to={ROUTES.PORTAL_DEPOSITS}
          startIcon={icons[ROUTES.PORTAL_DEPOSITS]}
        >
          Deposits
        </Button>
      </div>
      <Divider />
    </Fragment>
  );
};
