import {useUrlQuery} from '@ozark/common/hooks/useUrlQuery';

export const useApplicationQuery = () => {
  const query = useUrlQuery();
  const isSummaryUrlParamName = 'isSummary';
  const anchorUrlParamName = 'anchor';
  const isSummary = (query.get(isSummaryUrlParamName) ?? 'false') === 'true';
  const summaryAnchor = query.get(anchorUrlParamName) ?? '';
  const agentUid = query.get('a') ?? '';
  return {
    isSummaryUrlParamName,
    anchorUrlParamName,
    isSummary,
    summaryAnchor,
    agentUid,
  };
};
