import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useStore} from '../../../store';

export const useApplicationFromRouteId = () => {
  const {setApplicationId, application} = useStore();
  const {applicationId} = useParams<{applicationId: string}>();

  useEffect(() => {
    setApplicationId(applicationId);
  }, [applicationId, setApplicationId]);

  return {application, applicationId};
};
