import {useEffect} from 'react';
import {generatePath, useHistory} from 'react-router-dom';
import {DEPOSITS} from '../../constants/routes';
import Loading from '../Loading';

export const PLAID_STORED_APPLICATION_ID_STORAGE_KEY = 'plaid-application-id';

export const PlaidCallback = () => {
  const history = useHistory();

  useEffect(() => {
    const storedApplicationId = sessionStorage.getItem(PLAID_STORED_APPLICATION_ID_STORAGE_KEY);
    if (storedApplicationId) {
      history.push({
        pathname: generatePath(DEPOSITS, {applicationId: storedApplicationId}),
        search: history.location.search,
      });
    } else {
      history.push('/');
    }
  }, [history]);

  return <Loading />;
};
