import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {getSetPasswordShape} from '@ozark/common';
import {InputPasswordAdornment, PasswordCheckList, TextField} from '@ozark/common/components';
import {useSetPassword} from '@ozark/common/hooks';
import PhoneNumber from 'awesome-phonenumber';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import Title from '../Title';

interface Props {
  setValidationHandler(handleSubmit: any): any;
}

type FormInput = {
  firstName: string;
  lastName: string;
  email: string;
  businessPhone: string;
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  businessPhone: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Business Phone is required'),
  ...getSetPasswordShape<FormInput>('password', 'confirmPassword'),
});

const StartPage = ({setValidationHandler}: Props) => {
  const {group} = useStore();

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const {
    showPassword,
    showConfirmation,
    displayIsValidPassword,
    displayPasswordCheckList,
    handleClickShowPassword,
    handleClickShowConfirmation,
    passwordValue,
    passwordConfirmationValue,
  } = useSetPassword<FormInput>({
    watch,
    passwordFieldName: 'password',
    passwordConfirmationFieldName: 'confirmPassword',
    dirtyFields,
  });

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(() => (onSuccess: any, onError: any) => _handleSubmit(onSuccess, onError));
  }, [setValidationHandler, handleSubmit]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Title h1="Let's Get Started" h2="Start by creating an account." />
      </Grid>
      <Grid container item sm={6} spacing={1}>
        <Grid item xs={6} sm={6}>
          <TextField
            name="firstName"
            label="First Name"
            required
            errors={errors}
            control={control}
            autoFocus
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField name="lastName" label="Last Name" required errors={errors} control={control} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="businessPhone"
            label="Phone Number"
            required
            errors={errors}
            control={control}
            transform={{
              pattern: '(999) 999-9999',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="email"
            label="Email Address"
            required
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="password"
            label="Password"
            required
            errors={errors}
            control={control}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputPasswordAdornment
                  showPassword={showPassword}
                  displayIsValidPassword={displayIsValidPassword}
                  handleClickShowPassword={handleClickShowPassword}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            required
            errors={errors}
            control={control}
            type={showConfirmation ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputPasswordAdornment
                  showPassword={showConfirmation}
                  displayIsValidPassword={displayIsValidPassword}
                  handleClickShowPassword={handleClickShowConfirmation}
                />
              ),
            }}
          />
          {displayPasswordCheckList && (
            <PasswordCheckList password={passwordValue} confirmation={passwordConfirmationValue} />
          )}
        </Grid>
      </Grid>
      <Grid container item sm={6}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              pt: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Already have an account?
            </Typography>
            <Button
              variant="outlined"
              sx={{mt: 2}}
              target="_self"
              href={`http://${group.data?.portalDomain}`}
            >
              Sign in
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StartPage;
