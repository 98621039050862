import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@mui/material';
import {TextField} from '@ozark/common/components';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import Title from '../Title';

interface Props {
  setValidationHandler(handleSubmit: any): any;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const StartPage = ({setValidationHandler}: Props) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(() => (onSuccess: any, onError: any) => _handleSubmit(onSuccess, onError));
  }, [setValidationHandler, handleSubmit]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Let's Get Started" h2="Start by giving us your email address." />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="email"
          label="Email Address"
          required
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
    </Grid>
  );
};

export default StartPage;
