import {generatePath, useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {useApplicationQuery} from './useApplicationQuery';

export const useApplicationRedirectToSummary = () => {
  const history = useHistory();
  const {summaryAnchor} = useApplicationQuery();
  const redirectToSummary = (applicationId: string) => {
    const route = `${generatePath(ROUTES.REVIEW, {applicationId})}#${summaryAnchor}`;
    history.push(route);
  };
  return {
    redirectToSummary,
  };
};
