import {Alert} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {createAuditNonce, Dispositions, Firebase, useSimpleBusinessTypes} from '@ozark/common';
import {Loading} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {usePortal} from '../../firebase/hooks/usePortal';
import {useStore} from '../../store';
import BasicsPage from '../BasicsPageForAgents';
import BusinessPage from '../BusinessPageForAgents';
import DepositsPage from '../DepositsPageForAgents';
import ProgramPage from '../ProgramPageForAgents';
import SignerPage from '../SignerPageForAgents';
import {Actions} from './Actions';
import {GeneratePdfDialog} from './GeneratePdfDialog';
import {Navigator} from './Navigator';

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  root: {
    margin: spacing(0, 4, 4),
    [breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(1),
      marginBottom: 72,
      marginLeft: 0,
      marginRight: 0,
    },
    // eslint-disable-next-line no-useless-computed-key
    ['& .Mui-disabled']: {
      color: 'unset',
      '-webkit-text-fill-color': 'unset',
    },
  },
  page: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    [breakpoints.down('xs')]: {
      marginLeft: spacing(2),
      marginTop: spacing(4),
      marginRight: spacing(2),
      marginBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(2),
    marginTop: spacing(2),
  },
  alert: {
    marginBottom: spacing(4),
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(0),
    },
  },
}));

export const Portal = () => {
  const classes = useStyles();
  const {portalState} = usePortal();
  const {setApplicationId, setDisableLogRocket} = useStore();
  const history = useHistory();
  const match = useRouteMatch();
  const {authUser, application, set} = useStore();
  const disposition = application.data?.disposition ?? Dispositions.incomplete;

  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generatePdfDialog, setGeneratePdfDialog] = useState(false);
  const {getMccByBusinessType} = useSimpleBusinessTypes();

  const [validationHandler, setValidationHandler] =
    useState<(onSuccess: any, onError?: any) => () => Promise<any>>();

  useEffect(() => {
    if (portalState.promised || !portalState.data?.applicationId) return;
    setApplicationId(portalState.data.applicationId);
    setDisableLogRocket(portalState.data.disableLogRocket);
    history.push(ROUTES.PORTAL_BASICS);
  }, [
    portalState.promised,
    portalState.data?.applicationId,
    portalState.data?.disableLogRocket,
    setApplicationId,
    setDisableLogRocket,
    history,
  ]);

  useEffect(() => {
    setIsDirty(false);
  }, [match.path]);

  const isReadonly = (): boolean => {
    return portalState.data?.readonly === true;
  };

  const handleDirty = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const handleSubmit = async () => {
    if (!validationHandler || typeof validationHandler !== 'function') {
      return;
    }

    setLoading(true);

    await validationHandler(
      disposition !== Dispositions.incomplete
        ? () => setGeneratePdfDialog(true)
        : handleSuccess(false),
      handleError
    )();
  };

  const handleSubmitWithGeneration = async (generatePdf: boolean) => {
    if (!validationHandler || typeof validationHandler !== 'function') {
      return;
    }
    await validationHandler(handleSuccess(generatePdf), handleError)();
  };

  const handleSuccess = (generatePdf: boolean) => async (data: any) => {
    // Omit unnecessary field
    delete data.confirmBankAccountNumber;

    if (generatePdf) {
      data.pdfGenerationTimestamp = Firebase.FieldValue.now();
    }

    try {
      const uid = authUser.data?.uid;
      if (!uid) {
        throw Error('uid cannot be null');
      }

      const auditNonce = createAuditNonce(uid);
      const mcc = getMccByBusinessType(data.businessType);
      await set({...data, auditNonce, mcc});

      if (window.parent) {
        window.parent.postMessage(
          {action: 'alert', severity: 'success', message: 'Application was successfully updated.'},
          '*'
        );
      }
    } catch (err) {
      window.parent.postMessage(
        {action: 'alert', severity: 'error', message: 'There was an error saving the application.'},
        '*'
      );
      console.error('failed to save application', err);
    }

    setIsDirty(false);
    setLoading(false);
    setGeneratePdfDialog(false);
  };

  const handleError = (data: any) => {
    console.error(data);
    setLoading(false);
  };

  if (
    authUser.promised ||
    application.promised ||
    portalState.promised ||
    (!application.data && portalState.data)
  )
    return <Loading />;

  return (
    <div className={classes.root}>
      <Navigator />
      <div className={classes.page}>
        {portalState.data?.readonly && (
          <Alert severity="info" className={classes.alert}>
            You currently have <b>read only</b> access to this application.
          </Alert>
        )}
        <Switch>
          <Route
            path={ROUTES.PORTAL_BASICS}
            render={() => (
              <BasicsPage
                setValidationHandler={setValidationHandler}
                onDirty={handleDirty}
                readonlyAccess={isReadonly()}
              />
            )}
          />
          <Route
            path={ROUTES.PORTAL_BUSINESS}
            render={() => (
              <BusinessPage
                setValidationHandler={setValidationHandler}
                onDirty={handleDirty}
                readonlyAccess={isReadonly()}
              />
            )}
          />
          <Route
            path={ROUTES.PORTAL_SIGNER}
            render={() => (
              <SignerPage
                setValidationHandler={setValidationHandler}
                onDirty={handleDirty}
                readonlyAccess={isReadonly()}
              />
            )}
          />
          <Route
            path={ROUTES.PORTAL_DEPOSITS}
            render={() => (
              <DepositsPage
                setValidationHandler={setValidationHandler}
                onDirty={handleDirty}
                readonlyAccess={isReadonly()}
              />
            )}
          />
          <Route
            path={ROUTES.PORTAL_PROGRAM}
            render={() => <ProgramPage setValidationHandler={setValidationHandler} />}
          />
        </Switch>
      </div>
      {generatePdfDialog && (
        <GeneratePdfDialog
          onClose={() => {
            setLoading(false);
            setGeneratePdfDialog(false);
          }}
          onNo={() => handleSubmitWithGeneration(false)}
          onYes={() => handleSubmitWithGeneration(true)}
        />
      )}
      {!portalState.data?.readonly && isDirty && (
        <Actions onSubmit={handleSubmit} loading={loading} />
      )}
    </div>
  );
};
