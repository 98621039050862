import {yupResolver} from '@hookform/resolvers/yup';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ApplicationSubCollection,
  Collections,
  FinicityAccount,
  FinicityAccountModel,
  FinicityAchDetailResponse,
  FinicityData,
  FinicityDocumentId,
  FinicityInstitutionResponse,
  Firebase,
  formatterCurrency,
  getDepositsPageForAgentsObjectCommon,
  ScorecardServiceName,
  useNotification,
} from '@ozark/common';
import {ScorecardsLogoMap, TextField} from '@ozark/common/components';
import AutoCompleteBankInput from '@ozark/common/components/AutoCompleteBankInput';
import {useAutoComplete} from '@ozark/common/context/AutoComplete';
import {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  setEndSessionHandler?(handleEndSession: any): any;
  onDirty?: (isDirty: boolean) => void;
  readonlyAccess?: boolean;
  readAccessToAccountNumber?: boolean;
  setLoading?: (loading: boolean) => void;
};

type FormInput = {
  routingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  bankName: string;
  finicityAccount: FinicityAccount;
};

const schema = yup.object().shape(getDepositsPageForAgentsObjectCommon(true));

const transform = (onSuccess: any) => (data: any) => {
  onSuccess(data);
};

const DepositsPageForAgents = ({
  setValidationHandler,
  setEndSessionHandler,
  onDirty,
  readonlyAccess,
  readAccessToAccountNumber,
}: Props) => {
  const showNotification = useNotification();
  const {applicationId, application, apiClient, group} = useStore();
  const autoComplete = useAutoComplete(apiClient.externalServices, group.data);

  const [finicityData, setFinicityData] = useState<FinicityData>();

  const {bankAccountNumber, bankName, routingNumber, plaidAccount} = application.data ?? {};

  const {formState, reset, control, setValue, watch, getValues, handleSubmit} = useForm<FormInput>({
    defaultValues: {
      bankAccountNumber: application.data?.bankAccountNumber,
      bankName: application.data?.bankName,
      routingNumber: application.data?.routingNumber,
      finicityAccount: application.data?.finicityAccount,
    },
    context: {
      dobSsnTaxIdAndBankInfoNotRequired:
        group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired,
    },
    resolver: yupResolver(schema),
  });

  const {errors} = formState;

  const {isDirty} = formState;

  const [blurAccountNumber, setBlurAccountNumber] = useState(true);
  const [blurConfirmAccountNumber, setBlurConfirmAccountNumber] = useState(true);

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          transform(onSuccess)(data);
          reset(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  useEffect(() => {
    if (!setEndSessionHandler) return;
    setEndSessionHandler(() => () => {
      const form = getValues();
      return form;
    });

    return () => {
      setEndSessionHandler(undefined);
    };
    // eslint-disable-next-line
  }, [setEndSessionHandler]);

  useEffect(() => {
    // subscribes to finicity subcollection to show list of accounts when available
    if (!application.data?.finicityAccount) {
      return;
    }
    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(ApplicationSubCollection.finicity)
      .doc(FinicityDocumentId.connect)
      .onSnapshot(async snapshot => {
        if (!snapshot.exists) {
          return;
        }
        const finicity = snapshot.data() as FinicityData;
        setFinicityData(finicity);
      });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [applicationId]);

  const handleAccountChange = (account: any) => async (_event: any) => {
    if (readonlyAccess) {
      return;
    }
    // prevent setting value if account is already selected
    if (account.id === finicityAccount?.accountId) {
      return;
    }
    // make sure the account id is only a checking or savings account
    if (!['checking', 'savings'].includes(account.type)) {
      showNotification('error', 'Only checking and savings accounts are allowed for deposits.');
      return;
    }
    // async call to get institution and ach detail so checkbox can be checked without waiting for response
    Promise.all([
      apiClient.finicity.getInstitution(account.institutionId),
      apiClient.finicity.getAchDetail({
        applicationId: applicationId as string,
        customerId: account.customerId,
        accountId: account.id,
      }),
    ])
      .then((results: [FinicityInstitutionResponse | null, FinicityAchDetailResponse | null]) => {
        const [institution, achDetail] = results;
        if (!(achDetail && institution)) {
          return;
        }
        setValue(
          'finicityAccount',
          {
            customerId: account.customerId,
            accountId: account.id,
            bankName: institution.institution?.name || '',
          },
          {shouldDirty: true}
        );
        setValue('bankAccountNumber', achDetail.realAccountNumber, {shouldDirty: true});
        setValue('confirmBankAccountNumber', achDetail.realAccountNumber, {shouldDirty: true});
        setValue('bankName', institution.institution?.name || '', {shouldDirty: true});
        setValue('routingNumber', achDetail.routingNumber, {shouldDirty: true});
      })
      .catch(_err => {
        showNotification(
          'error',
          'Please select another account or enter your account details manually.'
        );
      });
  };

  const handleFetchAchDetail = async (account: FinicityAccountModel) => {
    try {
      const achDetail = await apiClient.finicity.getAchDetail({
        applicationId: applicationId as string,
        customerId: account.customerId,
        accountId: account.id,
      });

      setValue('bankAccountNumber', achDetail.realAccountNumber, {shouldDirty: true});
      setValue('confirmBankAccountNumber', achDetail.realAccountNumber, {shouldDirty: true});

      showNotification('success', 'ACH detail is fetched');
    } catch (error) {
      showNotification('error', 'Failed to get ACH detail');
    }
  };

  const watchFields = watch(['bankAccountNumber', 'confirmBankAccountNumber', 'routingNumber']);

  const mask = !(watchFields[0]?.length > 1 && watchFields[0] === watchFields[1]);

  const finicityAccount = watch('finicityAccount');

  const linkedWithPlaid: boolean =
    plaidAccount &&
    plaidAccount.bankName === bankName &&
    plaidAccount.routingNumber === routingNumber &&
    plaidAccount.accountNumber === bankAccountNumber;

  const linkedWithFinicity: boolean = !!finicityAccount;

  const linked = linkedWithPlaid || linkedWithFinicity;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          h1="Bank Deposit Info"
          h2={
            linked ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 6,
                }}
              >
                <Typography variant="h5" sx={{color: '#3CB043', mr: 1}}>
                  Linked with
                </Typography>
                <img
                  src={
                    ScorecardsLogoMap[
                      linkedWithPlaid ? ScorecardServiceName.plaid : ScorecardServiceName.finicity
                    ]
                  }
                  alt={linkedWithPlaid ? 'Plaid' : 'Finicity'}
                />
              </Box>
            ) : (
              <div>
                <span>Tell us where to deposit the merchant's funds</span>
              </div>
            )
          }
        />
      </Grid>
      {linkedWithFinicity && (
        <>
          <Grid item xs={12}>
            <List
              sx={{
                margin: 'auto',
                px: 2,
                width: 400,
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 2,
                mb: 2,
              }}
            >
              {finicityData?.accounts?.map((account: any, index: number) => {
                return (
                  <Fragment key={`account-${index}`}>
                    {index > 0 && <Divider />}
                    <ListItem
                      secondaryAction={
                        <Typography variant="body2">
                          {formatterCurrency.format(Number(account.balance))}
                        </Typography>
                      }
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={account.id === finicityAccount?.accountId}
                          onChange={handleAccountChange(account)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{'aria-labelledby': `labelId-${index}`}}
                          disabled={!['checking', 'savings'].includes(account.type)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`labelId-${index}`}
                        primary={
                          <>
                            {account.accountNickname}{' '}
                            {!readonlyAccess && account.id === finicityAccount?.accountId && (
                              <IconButton
                                title="Reload ACH retail"
                                onClick={() => handleFetchAchDetail(account)}
                                size="small"
                              >
                                <CachedIcon />
                              </IconButton>
                            )}
                          </>
                        }
                        secondary={
                          !['checking', 'savings'].includes(account.type) ? (
                            <Typography variant={'caption'} color={'red'}>
                              Invalid Deposit Account
                            </Typography>
                          ) : (
                            `Ending in ${account.accountNumberDisplay}`
                          )
                        }
                      />
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <AutoCompleteBankInput
          name="bankName"
          label="Bank Name"
          required
          freeSolo
          errors={errors}
          control={control}
          onInputChange={(_event: any, newInputValue: string) => {
            setValue('bankName', newInputValue as any, {shouldDirty: true});
            autoComplete.handleInstitutionQuery(newInputValue);
          }}
          onChange={(_event: any, inputValue: any) => {
            if (inputValue) autoComplete.handleInstitutionSelect(inputValue);
          }}
          options={autoComplete.institutionOptions}
          loading={autoComplete.loading}
          disabled={readonlyAccess || linkedWithFinicity}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteBankInput
          name="routingNumber"
          label="Routing Number"
          onInputChange={(_event: any, newInputValue: string) => {
            setValue('routingNumber', newInputValue as any, {shouldDirty: true});
          }}
          options={autoComplete.routingNumberOptions.filter(e =>
            watchFields && watchFields[2] && watchFields[2].length > 0
              ? e.value.indexOf(watchFields[2]) > -1
              : true
          )}
          required={!group.data?.applicationSettings?.dobSsnTaxIdAndBankInfoNotRequired}
          helperText="Suggested routing numbers may not match your records."
          errors={errors}
          control={control}
          autoFocus
          inputProps={{maxLength: 9}}
          transform={{
            pattern: '999999999',
          }}
          loading={autoComplete.loading}
          disabled={readonlyAccess || linkedWithFinicity}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="bankAccountNumber"
          label="Account Number"
          onFocus={() => setBlurAccountNumber(false)}
          onBlur={() => setBlurAccountNumber(true)}
          type={mask && blurAccountNumber ? 'password' : 'default'}
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          transform={{
            pattern: '999999999999999999999999999',
          }}
          {...((readAccessToAccountNumber || (readAccessToAccountNumber && linkedWithFinicity)) && {
            disabled: false,
            onChange: () => {}, // disable editing but field should be enabled
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="confirmBankAccountNumber"
          label="Confirm Account Number"
          onFocus={() => setBlurConfirmAccountNumber(false)}
          onBlur={() => setBlurConfirmAccountNumber(true)}
          type={mask && blurConfirmAccountNumber ? 'password' : 'default'}
          errors={errors}
          control={control}
          disabled={readonlyAccess || linkedWithFinicity}
          transform={{
            pattern: '999999999999999999999999999',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DepositsPageForAgents;
