import {EbtAcceptanceStatus, ProcessingTypePayments, RefundPolicy} from '@ozark/common';
import {
  avgSaleOptions,
  estimatedMonthlyCreditCardSalesOptions,
  highestSaleOptions,
  ProcessingTypes,
  ShippingService,
} from '@ozark/functions/src/constants';
import {PageAnswers, QuestionAnswer, SectionAnswers} from '../types';
import {useSummaryCommon} from './useSummaryCommon';

export const useBasicStepAnswers = () => {
  const {
    emptyAnswers,
    getAvailableQuestionAnswer,
    getQuestionAnswer,
    isCardPresent,
    getYesNo,
    app,
  } = useSummaryCommon();

  const getBasicsAnswers = (businessTypeQuestion: string): SectionAnswers => {
    return {
      isAvailable: true,
      header: 'Basics',
      questionAnswers: [getAvailableQuestionAnswer(businessTypeQuestion, app.businessType)],
    };
  };

  const getProcessingTypeAnswer = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(
      question,
      app.processingType === undefined
        ? undefined
        : ProcessingTypePayments[app.processingType].title
    );
  };

  const getCustomerReceivesProductService = (question: string): QuestionAnswer => {
    return getQuestionAnswer(question, app.customerReceivesProductService, !isCardPresent());
  };

  const getRefundPolicy = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(question, app.refundPolicy);
  };

  const getRefundPolicyExplained = (): QuestionAnswer => {
    return getQuestionAnswer(
      'Refund Policy Explanation',
      app.refundPolicyExplained,
      app.refundPolicy === RefundPolicy.other
    );
  };

  const getEcommerceQuestions = (): QuestionAnswer[] => {
    if (app.processingType !== ProcessingTypes.eCommerce) {
      return [];
    }
    return [
      getAvailableQuestionAnswer('Who owns the product', app.productOwner),
      getAvailableQuestionAnswer(
        'Are customers required to provide a deposit',
        getYesNo(app.isCustomerDepositRequired)
      ),
      getQuestionAnswer(
        'Deposit Percentage',
        app.customerDepositPercentage,
        app.isCustomerDepositRequired ?? false
      ),
      getAvailableQuestionAnswer(
        'List the name(s)/addresses(es) where the product is purchased',
        app.productPurchasedNames
      ),
      getAvailableQuestionAnswer('Product purchased by', app.productPurchasedBy),
      getAvailableQuestionAnswer(
        'List the name(s) of Fulfillment Center, Contact Name, Address, and Phone # or Email Address, if any',
        app.isFulfillmentCenterNamesDefined ? app.fulfillmentCenterNames : 'N/A'
      ),
      getAvailableQuestionAnswer(
        'List the name(s) of call center(s) providers, if any',
        app.isCallCenterNamesDefined ? app.callCenterNames : 'N/A'
      ),
      getAvailableQuestionAnswer(
        'List the name(s) of chargeback management systems, if any',
        app.isCbManagementSystemsNamesDefined ? app.cbManagementSystemsNames : 'N/A'
      ),
      getAvailableQuestionAnswer(
        'What shipping service do you use to deliver products to customers',
        (app.shippingServices as string[]).join(', ')
      ),
      getQuestionAnswer(
        'Other Shipping Method',
        app.otherShippingServices,
        app.shippingServices?.includes(ShippingService.other) ?? false
      ),
    ];
  };

  const getPreviouslyProcessed = (question: string): QuestionAnswer => {
    return getAvailableQuestionAnswer(question, getYesNo(app.previouslyProcessed));
  };

  const getOptionText = (options: any, value: number | undefined) => {
    return Object.keys(options)[Object.values(options).findIndex(x => x === value)];
  };

  const getEstimatedMonthlyCreditCardSales = (question: string): QuestionAnswer => {
    const value = getOptionText(
      estimatedMonthlyCreditCardSalesOptions,
      app.estimatedMonthlyCreditCardSales
    );
    return getAvailableQuestionAnswer(question, value);
  };

  const getEstimatedAverageSale = (): QuestionAnswer => {
    const value = getOptionText(avgSaleOptions, app.estimatedAverageSale);
    return getAvailableQuestionAnswer('Average Amount Per Sale', value);
  };

  const getEstimatedHighestSale = (): QuestionAnswer => {
    const value = getOptionText(highestSaleOptions, app.estimatedHighestSale);
    return getAvailableQuestionAnswer('Estimated highest sale amount', value);
  };

  const getNumberOrZero = (numberOrUndefined: number | undefined): number => {
    return numberOrUndefined ?? 0;
  };

  const getBasicStepAnswersForAgentOrErpUser = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    const isPercentSwipedGtEq70 = getNumberOrZero(app.percentSwiped) >= 70;
    return {
      sectionAnswers: [
        getBasicsAnswers('What is the specific business type?'),
        {
          isAvailable: true,
          header: 'How will the merchant sell to their customers?',
          questionAnswers: [
            getProcessingTypeAnswer('How will the merchant sell to their customers?'),
            getAvailableQuestionAnswer('Percent Swiped', app.percentSwiped),
            getAvailableQuestionAnswer('Percent Keyed', app.percentKeyed),
            getAvailableQuestionAnswer('Percent Internet', app.percentInternet),
            getAvailableQuestionAnswer('Percent International', app.percentInternational),
            getAvailableQuestionAnswer('Percent B2B', app.percentB2b),
            getQuestionAnswer(
              'Will the merchant accept Pin based debit cards?',
              getYesNo(app.acceptPIN),
              isPercentSwipedGtEq70
            ),
            getQuestionAnswer(
              'Does the merchant accept EBT cards?',
              app.ebtAcceptance,
              isPercentSwipedGtEq70
            ),
            getQuestionAnswer(
              'EBT FNS #',
              app.ebtFNS,
              isPercentSwipedGtEq70 && app.ebtAcceptance === EbtAcceptanceStatus.Yes
            ),
            getPreviouslyProcessed('Has the merchant accepted credit cards before?'),
            getCustomerReceivesProductService(
              'When do customers receive their products or services?'
            ),
            getRefundPolicy("What is the business' refund policy?"),
            getRefundPolicyExplained(),
            ...getEcommerceQuestions(),
          ],
        },
        {
          isAvailable: true,
          header: 'Sales Estimates',
          questionAnswers: [
            getAvailableQuestionAnswer(
              'VISA/MC/DS Monthly Credit Card Sales',
              app.estimatedMonthlyCreditCardSales
            ),
            getAvailableQuestionAnswer(
              'AMEX Monthly Credit Card Sales',
              app.estimatedMonthlyCreditCardSalesAmex
            ),
            getAvailableQuestionAnswer('Average Amount Per Sale', app.estimatedAverageSale),
            getAvailableQuestionAnswer('Estimated highest sale amount', app.estimatedHighestSale),
          ],
        },
      ],
    };
  };

  const getBasicStepAnswers = (): PageAnswers => {
    if (!app) {
      return emptyAnswers;
    }
    return {
      sectionAnswers: [
        getBasicsAnswers('What is your specific business type?'),
        {
          isAvailable: true,
          header: 'How do you sell to your customers?',
          questionAnswers: [
            getProcessingTypeAnswer('How will you be processing payments?'),
            getQuestionAnswer(
              'Do you want to accept Pin based debit cards?',
              getYesNo(app.acceptPIN),
              isCardPresent()
            ),
            getQuestionAnswer('Do you accept EBT cards?', app.ebtAcceptance, isCardPresent()),
            getQuestionAnswer(
              'EBT FNS #',
              app.ebtFNS,
              isCardPresent() && app.ebtAcceptance === EbtAcceptanceStatus.Yes
            ),
            getPreviouslyProcessed('Have you accepted credit cards before?'),
            getCustomerReceivesProductService(
              'When do your customers receive your products or services?'
            ),
            getRefundPolicy('What is your business refund policy?'),
            getRefundPolicyExplained(),
            ...getEcommerceQuestions(),
          ],
        },
        {
          isAvailable: true,
          header: 'Sales Estimates',
          questionAnswers: [
            getEstimatedMonthlyCreditCardSales('Monthly credit card sales'),
            getEstimatedAverageSale(),
            getEstimatedHighestSale(),
          ],
        },
      ],
    };
  };

  return {
    getBasicStepAnswers,
    getBasicStepAnswersForAgentOrErpUser,
  };
};
