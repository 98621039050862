import {Grid, InputAdornment, TextField} from '@mui/material';
import {UniversalTimestamp} from '@ozark/common';
import {format} from 'date-fns';
import {QuestionAnswer} from '../types';

interface Props {
  questionAnswer: QuestionAnswer;
}

export const QuestionSummary = ({questionAnswer}: Props) => {
  const getAnswerString = () => {
    const type = typeof questionAnswer.answer;
    if (type === 'string' || type === 'number' || type === 'undefined') {
      return `${questionAnswer.answer ?? ''}`;
    }
    if (questionAnswer.answer === null || questionAnswer.answer === '') {
      return '';
    }
    const date = (questionAnswer.answer as UniversalTimestamp)?.toDate();
    return format(date, 'MM/dd/yy');
  };

  const answerString = getAnswerString();
  return (
    <Grid item sm={6}>
      <TextField
        variant="outlined"
        margin="normal"
        label={questionAnswer.question}
        value={answerString}
        helperText={questionAnswer.helperText}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start" />,
          endAdornment: <InputAdornment position="end" />,
        }}
        disabled={true}
      />
    </Grid>
  );
};
