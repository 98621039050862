import {CircularProgress, Container, CssBaseline, Grid, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Application, formatPhone} from '@ozark/common';
import {Fragment, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useStore} from '../../store';
import Title from '../Title';

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: theme.spacing(8),
  },
  logo: {
    margin: theme.spacing(2, 2, 8, 2),
    height: 48,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
  },
}));

const ConfirmationForAgents = () => {
  const classes = useStyles();
  const {group, signOut} = useStore();
  const history = useHistory();
  const location = useLocation<{applicationData: Pick<Application, 'distinguishableId'>}>();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (history.action !== 'PUSH') {
      setLoading(false);
      return;
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [history.action]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Grid container className={classes.grid} direction="column" alignItems="center">
        <Grid item>
          <img className={classes.logo} src={group.data?.logoUrl} alt={group.data?.name} />
        </Grid>
        <Grid item>
          {loading ? (
            <Title h2="Processing the application..." />
          ) : (
            <Fragment>
              <Title
                h1="Submitted!"
                h2="You have successfully submitted your application. Please expect a completed review and response within 24 hours."
              />
              <Typography variant="h6" align="center">
                Application ID: <b>{location.state?.applicationData?.distinguishableId ?? ''}</b>
              </Typography>
              <div className={classes.footer}>
                <Typography variant="body2" align="center">
                  {group.data?.name}
                </Typography>
                <Typography variant="body2" align="center">
                  {`${group.data?.address1}${
                    group.data?.address2 ? `, ${group.data?.address2}` : ''
                  }`}
                </Typography>
                <Typography variant="body2" align="center">
                  {`${group.data?.city}, ${group.data?.state} ${group.data?.zipCode}`}
                </Typography>
                <Typography variant="body2" align="center">
                  <a href={`tel:${formatPhone(group.data?.phone || '', {format: 'e164'})}`}>
                    {group.data?.phone}
                  </a>
                </Typography>
              </div>
            </Fragment>
          )}
        </Grid>
        {loading && (
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ConfirmationForAgents;
