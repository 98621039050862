import {useStore} from '../../store';
import DepositsPageBasic from './Basic';
import DepositsPageWithFinicity from './Finicity';
import DepositsPageWithPlaid from './Plaid';

export type Props = {
  setValidationHandler(handleSubmit: any): any;
  onDirty?: (isDirty: boolean) => void;
  setLoading?: (loading: boolean) => void;
};

const DepositsPage = (props: Props) => {
  const {group} = useStore();
  return group.data?.institutionServiceProvider === 'Plaid' ? (
    <DepositsPageWithPlaid {...props} />
  ) : group.data?.institutionServiceProvider === 'Finicity' ? (
    <DepositsPageWithFinicity {...props} />
  ) : (
    <DepositsPageBasic {...props} />
  );
};

export default DepositsPage;
