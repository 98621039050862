import {Alert} from '@mui/material';
import {Fragment} from 'react';

type Props = {
  className?: string;
  userEmail?: string | null;
};

export const PortalLoginAlert = ({className, userEmail}: Props): JSX.Element | null => {
  if (!userEmail) {
    return null;
  }

  return (
    <Alert severity="info" className={className}>
      <Fragment>
        You are logged in through the <b>Portal</b> as <b>{userEmail}</b>.
      </Fragment>
    </Alert>
  );
};
